import React from 'react'
import { Link } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import { getApproval } from '../shared/utils'

const SKIP_TAGS = true;

export default ({
	title,
	seriesSeasonApprovalFilter,
	handleFilter,
	goBackInsteadOfLink,
	hideFilter = true,
	catalogue,
	location,
}) => {

	// We need to go back instead of doing a normal navigation to allow for
	// react-router-scroll to restore the scroll position of the library list.
	function handleClick(e) {
		if (goBackInsteadOfLink || location && location.state && location.state.returnTo) {
			e.preventDefault();
			browserHistory.goBack();
		}
	}

	return (
		<Filter>
			<Left>
				<h1>
					{getLink(location, catalogue, handleClick)} {title}
				</h1>
			</Left>
			<Right>
				<Switch
					disabled={hideFilter}
					name="seriesSeasonApprovalFilter"
					title="Episode approval"
					onChange={handleFilter.bind(this, "filter")}
					states={getApproval(SKIP_TAGS)}
					currentState={seriesSeasonApprovalFilter}
				/>
			</Right>
		</Filter>
	);
};

// HELPERS
function getLink(location, catalogue, handleClick) {
	const returnTo = location?.state?.returnTo;
	const returnToTitle = location?.state?.returnToTitle;
	const cataloguePath = catalogue ? `/${catalogue}` : "";

	const url = returnTo ? returnTo : `/metadata/programs${cataloguePath}`;
	const parentTitle = returnToTitle || "Program library";

	return <Link to={url} onClick={handleClick}>{parentTitle}</Link>
}