import React, { Component, useState } from 'react'
import { Link } from 'react-router'
import moment from 'moment-timezone'
import debounce from 'lodash/debounce'

import LazyLoad from '../../../components/lazyload'

import { getItemHref } from '../shared/utils'
import Widget from './widget'

import appConfig from 'config'

import './horizontalList.css'

const SCROLL_INCREMENT = 300;
const SCROLL_INCREMENT_LANDSCAPE = 600;

class HorizontalList extends Component {
	slideLeft = React.createRef()
	slideRight = React.createRef()
	wrapper = React.createRef()

	componentDidMount() {
		showOrHideSlideButtons(this.wrapper.current, this.slideLeft.current, this.slideRight.current, 0);
	}

	componentDidUpdate() {
		showOrHideSlideButtons(this.wrapper.current, this.slideLeft.current, this.slideRight.current, 0);
	}

	scrollWrapper = (increment, e) => {
		scroll(this.wrapper.current, this.slideLeft.current, this.slideRight.current, increment);
	}

	onWrapperScroll = e => {
		e.persist();
		this.showOrHideSlideButtonsDebounced(this.wrapper.current, this.slideLeft.current, this.slideRight.current, e.target.scrollLeft);
	}

	scrollWrapperDebounced = debounce(this.scrollWrapper, 200, { leading: true, trailing: false });
	showOrHideSlideButtonsDebounced = debounce(showOrHideSlideButtons, 100, { leading: false, trailing: true });

	render() {
		const { widgetData = {}, presentation = "" } = this.props;
		const { items: programs, body } = widgetData;

		const scrollBy = presentation === "landscape" ? SCROLL_INCREMENT_LANDSCAPE : SCROLL_INCREMENT;

		return (
			<div className={`c6-press-horizontal-list ${presentation} ${body ? "has-body" : ""}`}>
				{body && <p className="list-body">{body}</p>}
				<div className="slide-left" onClick={this.scrollWrapperDebounced.bind(this, -scrollBy)} ref={this.slideLeft}><div className="icon-chevron_left"></div></div>
				<div className="wrapper" ref={this.wrapper} onScroll={this.onWrapperScroll}>
					{programs && programs.map(p => <Item key={p.id} {...p} presentation={presentation} />)}
				</div>
				<div className="slide-right" onClick={this.scrollWrapperDebounced.bind(this, scrollBy)} ref={this.slideRight}><div className="icon-chevron_right"></div></div>
			</div>
		);
	}
}

export default Widget(HorizontalList);

const Item = (props) => {
	const [expandInfo, setExpandInfo] = useState(false);
	const { heading, description, start, positionDate, positionText, assets: itemAssets = [], program = {}, presentation = "cover" } = props;
	const { title, synopsis = {}, assets: programAssets = [], seriesTitle } = program;
	const { short } = synopsis;

	// const date = (start || positionDate) && moment(start || positionDate).tz(appConfig.features.pressTimezone);
	const href = getItemHref(props);
	// const logo = getLogo(positionText);

	const mainImage = itemAssets.find(a => a.type === "image") || programAssets.find(a => a.type === "image");
	const { src, srcSet } = getSources(mainImage, presentation);
	return (
		<Link className={`c6-press-horizontal-item ${expandInfo ? "expand-info" : ""}`} to={href}>
			<div className="image">
				{mainImage && (
					// Decided not to use ResponsiveImage here because the sizes there don't work with the layout used here.
					// Maybe we should add a new "format" to ResponsiveImage that does the same as below?
					<LazyLoad offsetVertical={600}>
						<img
							alt={heading}
							src={src}
							srcSet={srcSet}
							sizes={`(max-width: 500px) 100px, ${false ? "100px" : "200px"}`}
							decoding="async"
						/>
					</LazyLoad>
				)}
			</div>
			<div className="info" onClick={e => { e.preventDefault(); e.stopPropagation(); setExpandInfo(!expandInfo) }}>
				<h2 className={expandInfo ? "icon-expand_less" : "icon-expand_more"}>{heading || title || positionText}</h2>
				<p>{seriesTitle || description || short}</p>
			</div>
		</Link>
	);
}


function getSources(image, presentation) {
	if (image && image.id) {
		const srcFormat = presentation === "landscape" ? "Comet_16x9_200x112" : "Star_7x10_200x"; //,false ? "Comet_16x9_200x112" : "Comet_16x9_400x224";
		const srcFormat2x = presentation === "landscape" ? "Comet_16x9_400x224" : "Star_7x10_400x"; //,false ? "Comet_16x9_200x112" : "Comet_16x9_400x224";
		const imgUrlStart = appConfig.media.star;

		/* TODO: Use these URLS when the images in Azure blob storage works (but also add the new srcFormat2x above) */
		// return {
		// 	src: `${imgUrlStart}${srcFormat}/${image.id}.jpg`,
		// 	srcSet: `${imgUrlStart}Comet_16x9_200x112/${image.id}.jpg 200w, ${imgUrlStart}Comet_16x9_400x224/${image.id}.jpg 400w`,
		// }

		return {
			src: `${imgUrlStart}${image.id}/${srcFormat}.jpg`,
			srcSet: `${imgUrlStart}${image.id}/${srcFormat}.jpg 200w, ${imgUrlStart}${image.id}/${srcFormat2x}.jpg 400w`,
		}
	}

	return {};
}

function getLogo(positionText) {
	if (positionText && positionText.toLowerCase().includes("cmore")) {
		return "cmore";
	}
	if (positionText && positionText.toLowerCase() === "mtvfi") {
		return "mmtv-katsomossa";
	}

	return positionText;
}

function scroll(wrapper, slideLeft, slideRight, increment) {
	const newScrollPosition = wrapper.scrollLeft + increment;
	// showOrHideSlideButtons(wrapper, slideLeft, slideRight, newScrollPosition);
	wrapper.scrollTo({
		left: newScrollPosition,
		behavior: "smooth",
	});
}

function showOrHideSlideButtons(wrapper, slideLeft, slideRight, newScrollPosition) {
	if (newScrollPosition >= wrapper.scrollWidth - wrapper.clientWidth) {
		slideRight.style.opacity = 0;
		slideRight.style.pointerEvents = "none";
	} else {
		slideRight.style.opacity = 1;
		slideRight.style.pointerEvents = "all";
	}

	if (newScrollPosition <= 0) {
		slideLeft.style.opacity = 0;
		slideLeft.style.pointerEvents = "none";
	} else {
		slideLeft.style.opacity = 1;
		slideLeft.style.pointerEvents = "all";
	}
}