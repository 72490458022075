import React from 'react'

import Button from '../../../components/ui/controls/button'

import StarConstants from '../../../core/constants/star'
import * as StarAPI from '../../../apis/star'

import Actions from './actions'

import appConfig from 'config'

const AssetActions = ({
	asset,
	assetCrops,
    isCropping,
    activeCrop,
	cropHasChanged = false,
    // onSaveClick,
    // onCancelClick,
	disabled,
	transformations,

    children = null,
}) => {

	const transformationsChanged = asset.rotation !== transformations.rotation
		|| asset.brightness !== transformations.brightness
		|| asset.contrast !== transformations.contrast;

    // Save and Cancel actions
	const leftActions = (
		<React.Fragment>
			{isCropping && !disabled && (
				<Button
					key="save"
					title="Save crop"
					type="submit"
					onClick={() => saveCrops(asset, assetCrops)}
					noBackground
					disabled={!cropHasChanged}
				/>
			)}
			{isCropping && (
				<Button key="cancel" title="Cancel" type="cancel" onClick={() => Actions.cancelCrop()} noBackground />
			)}
			{!isCropping && transformationsChanged && (
				<Button key="save" title="Save" type="submit" onClick={() => Actions.transformAsset(asset, transformations)} noBackground />
			)}
			{!isCropping && transformationsChanged && (
				<Button key="cancel" title="Cancel" type="cancel" onClick={() => Actions.cancelTransformations()} noBackground />
			)}
		</React.Fragment>
	);

    // Download actions
	const showBrandedDownloads = (asset.branding?.name.length > 0)
		&& asset.branding.name.toLowerCase() !== "prebranded" && asset.branding.name.toLowerCase() !== "undefined"
		&& (appConfig.media.brandedImageProxy?.length > 0);
	const buttonStyle = { gridRow: "1 / 2" };
	const brandingButtonStyle = {
		...buttonStyle,
		gridRow: "2 / 3",
	};

	// Special 1920x1080 download crop button
	const download1920x1080Format = getDownload1920x1080Format(activeCrop);

	let crop1920x1080Download = null,
		brandingCrop1920x1080Download = null;
	if (download1920x1080Format && isCropping) {
		const { files = [] } = asset;
		const width = activeCrop?.cropData?.width ?? files[0]?.width;
		const height = activeCrop?.cropData?.height ?? files[0]?.height;
		const isSmallerThan1080p = width < 1920 || height < 1080;

		const buttonStyle1920x1080 = {
			...buttonStyle,
			backgroundColor: isSmallerThan1080p ? "var(--pending-color)" : undefined,
		};

		crop1920x1080Download = (
			<Button
				title="1920x1080"
				shortTitle="1080p"
				onClick={() => download(asset, activeCrop.id, download1920x1080Format)}
				type="download"
				style={buttonStyle1920x1080}
			/>
		);

		if (showBrandedDownloads) {
			brandingCrop1920x1080Download = (
				<Button
					title="1920x1080"
					shortTitle="1080p"
					onClick={() => download(asset, activeCrop.id, download1920x1080Format, true)}
					type="download"
					style={{
						...buttonStyle1920x1080,
						...brandingButtonStyle
					}}
				/>
			);
		}
	}

	const rightActions = (
		<React.Fragment>
			{showBrandedDownloads && <p style={{ gridRow: "1 / 2" }}>Unbranded:</p>}
			{crop1920x1080Download}
			{isCropping && (
				<Button
					title="Crop"
					shortTitle="Crop"
					onClick={() => download(asset, activeCrop.id, null)}
					type="download"
					style={buttonStyle}
				/>
			)}
			<Button
				title="Original"
				shortTitle="Orig"
				onClick={() => download(asset, false, null)}
				type="download"
				style={buttonStyle}
			/>
			{showBrandedDownloads && <p style={{ gridRow: "2 / 3" }}>Branded:</p>}
			{brandingCrop1920x1080Download}
			{showBrandedDownloads && isCropping && (
				<Button
					title="Crop"
					shortTitle="Crop"
					onClick={() => download(asset, activeCrop.id, null, true)}
					type="download"
					style={brandingButtonStyle}
				/>
			)}
			{showBrandedDownloads && (
				<Button
					title="Original"
					shortTitle="Orig"
					onClick={() => download(asset, false, null, true)}
					type="download"
					style={brandingButtonStyle}
				/>
			)}
		</React.Fragment>
	);

    return (
        <div className="asset-actions flex flex-1-1-1">
            <div className="left-actions">{leftActions}</div>
            <h1>{children}</h1>
            <div className="right-actions">{rightActions}</div>
        </div>
    );
}

export default AssetActions;


export function download(asset, cropFormatId, downloadFormat, branded) {
	const { id: assetId, assetGuid, files, displayName } = asset;
	let fileName = displayName;
	const orgFile = files && files.find(f => f.fileFormatId === 0 && (f.status === "Ok" || f.status === "Undefined"));
	const extension = orgFile.fileTypeExtension || ".jpg";

	if(!downloadFormat) {
		downloadFormat = cropFormatId ? StarConstants.CROPS.find(item => item.id == cropFormatId) : null;
	}

	saveAssetToDisc(assetId, assetGuid, fileName, extension, downloadFormat, branded);
}

function saveCrops(asset, assetCrops) {
	const cropDatas = assetCrops.map(assetCrop => assetCrop.cropData);
	Actions.updateAssetCrop(cropDatas, asset.id);
	Actions.cancelCrop();
}

function getDownload1920x1080Format(crop) {
	const downloadFormatName = crop.id ? StarConstants.CROPS.find(c => c.id === crop.id)?.download1920x1080FormatName : null;
	return downloadFormatName
		? { downloadFormatName, name: "1920x1080" }
		: null;
}

async function saveAssetToDisc(assetId, assetGuid, fileName = "comet_download", extension, downloadFormat, branded) {
	let name = `${fileName.replace(extension, "")}_${assetId}`;
	name = encodeURIComponent(name); // URL Encode fileName

	if (downloadFormat) name += downloadFormat.name;
	if (extension) name += extension;
	let href;
	if (branded && appConfig.media.brandedImageProxy) {
		const format = downloadFormat ? downloadFormat.downloadFormatName : "original";
		href = `${appConfig.media.brandedImageProxy}${assetGuid}/${format}.jpg?download=true`;
	} else if (downloadFormat) {
		// Crop formats => download from image endpoint using "dl" query parameter
		href = `${appConfig.media.star}${assetGuid}/${downloadFormat.downloadFormatName}.jpg?download=true&fileName=${name}`;
	} else {
		// Other assets => get a download link from Star first
		href = await StarAPI.fetchAssetDownloadLink(assetGuid ?? assetId);
	}

	const a = document.createElement("a");
	a.target = "_blank";
	a.href = href;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
}