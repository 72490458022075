import React from 'react'
import PropTypes from 'prop-types'

import withDragDropContext from '../../../../core/services/withDnDContext'
import Suggestions from '../../suggestions'

import Tag from './tag'
import { generateKey } from '../index'
import './tags.css'

const KEYS = {
	ENTER: 13,
	TAB: 9,
	BACKSPACE: 8,
	UP_ARROW: 38,
	DOWN_ARROW: 40,
	ESCAPE: 27
};

const DEFAULT_DELIMITERS = [KEYS.ENTER, KEYS.TAB];

class Tags extends React.Component {
	static propTypes = {
		formData: PropTypes.arrayOf(
			PropTypes.shape({
				id: PropTypes.oneOfType([
					PropTypes.number,
					PropTypes.string
				]),
				name: PropTypes.string
			})
		),
		uiSchema: PropTypes.shape({
			suggestions: PropTypes.arrayOf(
				PropTypes.shape({
					id: PropTypes.oneOfType([
						PropTypes.number,
						PropTypes.string
					]),
					name: PropTypes.string
				})
			),
			dataSource: PropTypes.func,
			placeholder: PropTypes.string,
			autoFocus: PropTypes.bool,
			delimiters: PropTypes.array,
			allowDeleteFromEmptyInput: PropTypes.bool,
			minQueryLength: PropTypes.number,
			autoComplete: PropTypes.bool,
			maxTags: PropTypes.number,
		})
	};

	static defaultProps = {
		uiSchema: {},
		schema: {},
		idSchema: {},
	};

	constructor(props) {
		super(props);

		const { formData = [], uiSchema } = props;

		this.ignoreDataSourceResults = false;
		this.prioritizedSuggestions = null;
		this.dataSourceConfig = uiSchema?.["ui:options"]?.dataSourceConfig
			?? props.dataSourceConfig
			?? { value: "id", text: "name" };

		const suggestions = this.getSuggestionsFromProps(props);
		this.state = {
			suggestions: this.filteredSuggestions("", suggestions, formData),
			query: "",
			selectedIndex: -1,
			selectionMode: false,
			tags: formData,
			isFocused: false,
		};
	}

	componentDidMount = () => {
		const { uiSchema, readonly, autofocus, formData } = this.props;

		this.ignoreDataSourceResults = false;

		const dataSource = uiSchema?.["ui:options"]?.dataSource;
		if (typeof(dataSource) === "function") {
			dataSource().then(response => {
				if (!this.ignoreDataSourceResults) {
					const items = Array.isArray(response) ? response : response.items;
					this.prioritizedSuggestions = items;
					this.setState({
						suggestions: this.filteredSuggestions("", items, formData)
					});
				}
			});
		}

		// Give input focus on mount
		if (autofocus && !readonly) {
			this.resetAndFocusInput();
		}
	}

	UNSAFE_componentWillReceiveProps = (nextProps) => {
		const { formData = [] } = nextProps;
		const suggestions = this.getSuggestionsFromProps(nextProps);
		this.setState({
			suggestions: this.filteredSuggestions(this.state.query, suggestions, formData),
			tags: formData
		});
	}

	componentWillUnmount = () => {
		this.ignoreDataSourceResults = true;
	}

	getSuggestionsFromProps = (props) => {
		const { uiSchema, schema } = props;
		const dataSource = uiSchema?.["ui:options"]?.dataSource;
		if (typeof dataSource === "function" && this.prioritizedSuggestions) {
			return this.prioritizedSuggestions;
		}
		const suggestions = Array.isArray(dataSource) ? dataSource : schema.suggestions ?? uiSchema.suggestions ?? props.suggestions ?? [];
		return suggestions;
	}

	resetAndFocusInput = () => {
		if (this.textInput) {
			this.textInput.value = "";
			this.textInput.focus();
		}
	}

	filteredSuggestions = (query = "", suggestions = [], tags = [], autoComplete = true) => {
		const tagKeys = tags.map(item => item[this.dataSourceConfig.value]);
		if (query === "")
			return [];

		// Return suggestions that match the query and are not already added
		return suggestions.filter(item => {
			const tagIsAlreadyUsed = tagKeys.some(tagKey => tagKey == item[this.dataSourceConfig.value]);
			const name = item[this.dataSourceConfig.text] || item.name || `(Missing ${this.dataSourceConfig.text})`;
			const nameMatch = match(name.trim().toLowerCase(), query.trim().toLowerCase(), autoComplete);
			const versionMatch = item.versions?.some(version => match((version?.displayName ?? version?.title)?.toLowerCase(), query.trim().toLowerCase(), autoComplete));
			return !tagIsAlreadyUsed && (nameMatch || versionMatch);
		});
	}

	handleDelete = (index) => {
		const { onChange, onDelete } = this.props;
		const { tags } = this.state;

		const newList = [...tags];
		const [deleted] = newList.splice(index, 1);
		onChange(newList);
		this.setState({ query: "" });
		this.resetAndFocusInput();

		if (typeof(onDelete) === "function") {
			onDelete(deleted);
		}
	}

	handleChange = (e) => {
		let query = e.target.value;
		// query = query.trim(); // Skip trim() to open suggestions when pressing spacebar
		if (this.props.uiSchema.onInputChange) {
			this.props.uiSchema.onInputChange(query);
		}

		const suggestions = this.getSuggestionsFromProps(this.props);
		const filteredSuggestions = this.filteredSuggestions(query, suggestions, this.state.tags);

		let selectedIndex = this.state.selectedIndex;
		if (selectedIndex >= filteredSuggestions.length) {
			selectedIndex = filteredSuggestions.length - 1;
		}

		this.setState({
			query,
			suggestions: filteredSuggestions,
			selectedIndex
		});
	}

	handleFocus = (e) => {
		this.setState({ isFocused: true });
	}

	handleBlur = (e) => {
		const value = e.target.value.trim();
		if (this.props.uiSchema.onInputBlur && this.textInput) {
			this.props.uiSchema.onInputBlur(value);
			this.textInput.value = "";
		}
		this.setState({ isFocused: false });
	}

	handleKeyDown = (e) => {
		const { query, suggestions, selectionMode, tags } = this.state;
		const { uiSchema } = this.props;
		let selectedIndex = this.state.selectedIndex;

		// Delimiter pressed without shift key (default delimiters are TAB and ENTER)
		const delimiters = uiSchema.delimiters || DEFAULT_DELIMITERS;
		if (delimiters.includes(e.keyCode) && !e.shiftKey) {
			if (e.keyCode !== KEYS.TAB || query !== "") {
				e.preventDefault();
			}

			// Call addTag() with selected suggestion or query
			if (query !== "" || selectedIndex != -1) {
				let selected = query;
				if (selectionMode && selectedIndex != -1) {
					selected = suggestions[selectedIndex];
				}
				this.addTag(selected);
			}
		}

		switch (e.keyCode) {
			// ESCAPE: clear suggestions
			case KEYS.ESCAPE:
				e.preventDefault();
				e.stopPropagation();
				this.setState({
					selectedIndex: -1,
					selectionMode: false,
					suggestions: []
				});
				break;

			// BACKSPACE: delete last tag if allowDeleteFromEmptyInput
			case KEYS.BACKSPACE:
				if (query.length == 0 && uiSchema.allowDeleteFromEmptyInput) {
					this.handleDelete(tags.length - 1);
				}
				break;

			// UP: Select suggestion above current selection
			case KEYS.UP_ARROW:
				e.preventDefault();

				selectedIndex = selectedIndex <= 0 ? suggestions.length - 1 : selectedIndex - 1;
				this.setState({
					selectedIndex,
					selectionMode: true
				});
				break;

			// DOWN: Select suggestion below current selection
			case KEYS.DOWN_ARROW:
				e.preventDefault();

				selectedIndex = (selectedIndex + 1) % suggestions.length;
				this.setState({
					selectedIndex,
					selectionMode: true
				});
				break;
		}
	}

	addTag = (tag) => {
		const { uiSchema, schema, onChange, onAdd } = this.props;
		const { tags = [] } = this.state;

		// Find possible matches
		if (typeof tag === "string") {
			const suggestions = this.getSuggestionsFromProps(this.props);
			const possibleMatches = this.filteredSuggestions(tag, suggestions, tags, uiSchema.autoComplete);

			if (possibleMatches.length === 0 && !uiSchema.createNewTags) {
				return;
			} else if (possibleMatches.length === 0 && uiSchema.createNewTags) {
				const text = tag;
				tag = { reactKey: generateKey() };
				tag[this.dataSourceConfig.text] = text;
			} else {
				tag = possibleMatches[0];
			}
		}

		// Don't add tag that is already added
		const tagKeys = tags.map(tag => tag[this.dataSourceConfig.value]);
		if (tag[this.dataSourceConfig.value] && tagKeys.includes(tag[this.dataSourceConfig.value]))
			return;

		// Add tag
		const newList = [...tags];
		const newTag = {
			reactKey: tag.reactKey,
			id: tag[this.dataSourceConfig.value],
			name: tag[this.dataSourceConfig.text] || tag.name,
			tagType: tag.tagType || { id: schema.newTagTypeId },
			[this.dataSourceConfig.value]: tag[this.dataSourceConfig.value],
			[this.dataSourceConfig.text]: tag[this.dataSourceConfig.text] || tag.name,
		};
		newList.push(newTag);
		onChange(newList);

		// Reset state
		this.setState({
			query: "",
			selectionMode: false,
			selectedIndex: -1,
			suggestions: [],
			isFocused: false,
		});
		this.resetAndFocusInput();

		if (typeof(onAdd) === "function") {
			onAdd(newTag);
		}
	}

	handleSuggestionClick = (index, disabled) => {
		if (!disabled) {
			this.addTag(this.state.suggestions[index]);
		}
	}

	handleSuggestionHover = (index, disabled) => {
		if (!disabled) {
			this.setState({
				selectedIndex: index,
				selectionMode: true
			});
		}
	}

	moveTag = (dragIndex, hoverIndex) => {
		const { onChange, onMoveTag } = this.props;
		const { tags } = this.state;

		const dragTag = tags[dragIndex];
		const newList = [...tags];
		newList.splice(dragIndex, 1);
		newList.splice(hoverIndex, 0, dragTag);
		if (typeof(onMoveTag) === "function") {
			onMoveTag(dragTag, hoverIndex);
		}
		this.setState({ tags: newList });
	}

	dropTag = () => {
		const { tags } = this.state;
		const { onChange } = this.props;
		onChange(tags);
	}

	renderTags = () => {
		const { readonly } = this.props;
		const { tags = [] } = this.state;
		const allowReorder = this.props.allowReorder !== undefined ? this.props.allowReorder : !readonly;

		return tags.map((tag, i) => {
			return (
				<Tag
					key={tag.reactKey ? tag.reactKey : tag[this.dataSourceConfig.value]}
					id={tag[this.dataSourceConfig.value]}
					name={tag[this.dataSourceConfig.text] || tag.name || `(Missing ${this.dataSourceConfig.text})`}
					index={i}
					moveTag={this.moveTag}
					dropTag={this.dropTag}
					readOnly={readonly}
					allowReorder={allowReorder}
					onClick={() => this.handleDelete(i)}
				/>
			);
		});
	}

	// Clicks on the component container should give focus to text-input
	handleContainerClick = (e) => {
		if (this.container && this.textInput && e.target == this.container) {
			this.textInput.focus();
		}
	}

	render = () => {
		const {
			schema,
			uiSchema,
			idSchema,
			readonly
		} = this.props;

		const {
			tags = [],
			query,
			selectedIndex,
			suggestions = [],
			isFocused,
		} = this.state;

		const {
			placeholder = "",
			minQueryLength = 1,
			maxTags,
		} = uiSchema;

		const { title, description } = schema;
		const className = `c6-tags${readonly ? " read-only" : ""}`;
		const usedKeys = tags.map(tag => tag[this.dataSourceConfig.value]);

		return (
			<div className={`c6-tagsfield${isFocused ? " is-focused" : ""}`}>
				{title && (<label htmlFor={idSchema.$id}>{title}</label>)}
				{description && (<p className="field-description">{description}</p>)}
				<div
					ref={el => { this.container = el }}
					className={className}
					onClick={this.handleContainerClick}>

					{this.renderTags()}
					{!(readonly || maxTags && tags.length >= maxTags) && (
						<input
							id={idSchema.$id}
							ref={input => { if (input) this.textInput = input }}
							type="text"
							placeholder={placeholder}
							onBlur={this.handleBlur}
							onFocus={this.handleFocus}
							onChange={this.handleChange}
							onKeyDown={this.handleKeyDown}
							data-1p-ignore={true} // tell 1Password it should ignore the field
							data-lpignore={true} // tell LastPass it should ignore the field
						/>
					)}
					{!readonly && (
						<Suggestions
							query={query}
							suggestions={suggestions}
							usedKeys={usedKeys}
							selectedIndex={selectedIndex}
							onSuggestionClick={this.handleSuggestionClick}
							onSuggestionHover={this.handleSuggestionHover}
							minQueryLength={minQueryLength}
							dataSourceConfig={this.dataSourceConfig}
						/>
					)}

				</div>
			</div>
		);
	}
}

export default withDragDropContext(Tags);

function match(item, query, autoComplete) {
	// When using autoComplete, allow partial match
	if (autoComplete) {
		return item.includes(query);
	}

	// Else, only allow exact match
	return item === query;
};