import * as PressAPI from '../../../apis/press'
import { useQuery } from '@tanstack/react-query'

const usePressProgramSchedules = (programId: string | number) => {
    return useQuery(
        ["press", "programschedules", { id: programId }],
        () => PressAPI.fetchProgramSchedules({ id: programId }),
        {
            enabled: programId !== null && programId !== undefined,
            onError: () => {}, // Do nothing on error
        }
    );
};

export default usePressProgramSchedules;