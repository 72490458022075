import React, { useEffect, useMemo, useRef } from 'react'

import { displayAlert } from '../../../core/services/alert'

import { getServiceToken } from '../../../core/services/auth'
import IFrameDialog from '../../../dialogs/iFrameDialog'
import './subtitleHorse.css'

import { fetchContainerByGuid, fetchAsset, convertSubtitle } from '../../../apis/star'

import appConfig from 'config'
import DropDown from '../../../components/ui/controls/dropdown'
import Button from '../../../components/ui/controls/button'

const VERSION_SRCLANG = {
	1: "sv",
    "sv": 1,
	2: "no",
    "no": 2,
	3: "da",
    "da": 3,
	4: "fi",
    "fi": 4,
	5: "en",
    "en": 5,
};

const LANGUAGE_MAP = {
    "sv": "Swedish",
    "no": "Norwegian",
    "da": "Danish",
    "fi": "Finnish",
    "en": "English"
};

export default function SubtitleHorse(props) {

    const [dialogOpen, setDialogOpen] = React.useState(props.open ?? false);
    const [iFrameSrc, setIFrameSrc] = React.useState(null);
    const [status, setStatus] = React.useState({});

    const shireFiles = useRef({
        sv: null,
        no: null,
        da: null,
        fi: null,
        en: null
    });

    const [language, setLanguage] = React.useState("sv");
    const [editSource, setEditSource] = React.useState(null)

    const [languageDropdownStates, setLanguageDropdownStates] = React.useState([]);
    // const [allSources, setAllSources] = React.useState([]);
    const allSources = React.useRef([]);
    const [allSourceDropdownStates, setAllSourceDropdownStates] = React.useState([])
    const [fileId, setFileId] = React.useState(null)

    const exportFormats = [
        // { key: "subrip", value: "subrip", text: "SRT", mimeType: "text/plain" },
        // { key: "webvtt", value: "webvtt", text: "VTT", mimeType: "text/vtt"},
        { key: "timedtext", value: "timedtext", text: "TTML", mimeType: "text/ttml" }
    ];

    const [programId, setProgramId] = React.useState(null)
    const [exportFormat, setExportFormat] = React.useState(exportFormats[0].key);
    const [assetId, setAssetId] = React.useState(null);

    const token = useStarToken();

    const getExportFormat = (key) => exportFormats.find(format => format.key === key);

    useEffect(() => {
        (async () => {
            if (!props.program?.guid) {
                return;
            }

            setProgramId(props.program?.id);

            let sources = [];

            let dropdownStates = {
                sv: { title: "Swedish", value: "sv", key: "sv", subtitles: false, shire: false, transcript: false, programId: props.program?.guid },
                no: { title: "Norwegian", value: "no", key: "no", subtitles: false, shire: false, transcript: false, programId: props.program?.guid },
                da: { title: "Danish", value: "da", key: "da", subtitles: false, shire: false, transcript: false, programId: props.program?.guid  },
                fi: { title: "Finnish", value: "fi", key: "fi", subtitles: false, shire: false, transcript: false, programId: props.program?.guid  },
                en: { title: "English", value: "en", key: "en", subtitles: false, shire: false, transcript: false, programId: props.program?.guid }
            };
            
            setStatus({ loading: true, message: "Fetching container..." });
            let container = await fetchContainerByGuid(props.program?.guid);
            if (!container) {
                setStatus({ loading: false, error: true, message: "No container found" });
                return;
            }
            
            setStatus({ loading: true, message: "Looking for video in container..." });
            let videoAssetInContainer = findVideoAssetInContainer(container, "mezz");
            if (!videoAssetInContainer) {
                setStatus({ loading: false, error: true, message: "No video found in container" });
                return;
            }

            setAssetId(videoAssetInContainer.assetGuid);
            setStatus({ loading: true, message: "Fetching video asset files..." });
            let videoAsset = await fetchAsset(videoAssetInContainer.id);

            setStatus({ loading: false, message: null });

            // Find all subtitle files and group them by language
            videoAsset.files?.filter(file => 
                file.assetType === "Subtitle"
                && !["deleted", "trashed", "replaced"].includes(file.status?.toLowerCase())
            ).forEach(file => {
                dropdownStates[VERSION_SRCLANG[file.versionId]].subtitles = true;
                sources.push({ lang: VERSION_SRCLANG[file.versionId], type: "Subtitle", title: dropdownStates[VERSION_SRCLANG[file.versionId]].title, fileId: file.id });
            });

            //Find all shire files and group them by language
            videoAsset.files?.filter(file => 
                file.assetType === "Document"
                && !["deleted", "trashed", "replaced"].includes(file.status?.toLowerCase()
                && file.fileTypeName === "Shire2")
            ).forEach(file => {
                shireFiles.current[VERSION_SRCLANG[file.versionId]] = file;
                dropdownStates[VERSION_SRCLANG[file.versionId]].shire = true;
        
                sources.push({ lang: VERSION_SRCLANG[file.versionId], type: "Workfile", title: dropdownStates[VERSION_SRCLANG[file.versionId]].title, fileId: file.id });
            });

            let f = videoAsset.files.find(file =>
                file.assetType === "Sidecar"
                && !["deleted", "trashed", "replaced"].includes(file.status?.toLowerCase())
                && file.fileTypeName === "transcript");
            if (f) {
                let versionId = f.versionId || 5;
                dropdownStates[VERSION_SRCLANG[versionId]].transcript = true;
                sources.push({ lang: VERSION_SRCLANG[versionId], type: "AI Transcript", title: dropdownStates[VERSION_SRCLANG[versionId]].title, fileId: f.id });
            }

            // updateDropdownStates(dropdownStates);
            setLanguageDropdownStates(
                Object.values(dropdownStates).map(state => {
                    state.text = state.title + (state.subtitles ? " (S)" : "") + (state.shire ? " (W)" : "") + (state.transcript ? " (T)" : "");
                    return state;
                })
            );

            allSources.current = sources.map((source, index) => {
                    return {
                        value: {
                            lang: source.lang,
                            type: source.type,
                            file: source.fileId
                        },
                        programId: programId,
                        key: `${source.lang}-${source.type}-${index}`,
                        text: `${source.title} (${source.type})`
                    }
                })

            updateLanguage("sv");
        })();
    }, [props.program]);

	const openDialog = () => {
        let mode = "new";
        if (fileId) {
            mode = "translate";
        } else if (shireFiles.current[language]) {
            mode = "edit";
        }

        setIFrameSrc(getSHUrl(props.program, language, token, mode, fileId));
        setDialogOpen(true);
	};

    const closeDialog = () => {
        setDialogOpen(false);
        if (props.onClose) {
            props.onClose();
        }
    };

    const updateLanguage = React.useCallback((lang) => { 
        const additionalSources = [];
        if (shireFiles.current[lang]) {
            additionalSources.push({
                value: {
                    lang: lang,
                    type: "Workfile",
                    title: `Edit ${LANGUAGE_MAP[lang]} draft`,
                },
                key: `${lang}-Workfile`,
                text: `Edit ${LANGUAGE_MAP[lang]} draft`
            });
        }

        const dropdownSources = [
            ...additionalSources,
            ...allSources.current.filter(f => !shireFiles.current[lang] || f.value.file !== shireFiles.current[lang].id) // remove workfile from list if it's already there
        ];

        if (!dropdownSources.length) {
            dropdownSources.push({
                value: {
                    lang: lang,
                    type: "Subtitle",
                    title: `New ${LANGUAGE_MAP[lang]} subtitle`,
                },
                key: `${lang}-Subtitle`,
                text: `New ${LANGUAGE_MAP[lang]} subtitle`
            });
        }
        
        setAllSourceDropdownStates(dropdownSources);

        setEditSource(dropdownSources[0].key);
        setFileId(dropdownSources[0].value.file);

        setLanguage(lang);
    }, [allSources.current, shireFiles, programId]);

    const updateEditSource = React.useCallback((e) => {
        let key = e.target.value;
        let source = allSources.current.find(source => source.key === key);
        setEditSource(e.target.value);
        setFileId(source.value.file);
    }, [allSources.current]);

    
    const updateLanguageFromDropdown = React.useCallback((e) => {
        updateLanguage(e.target.value);
    }, [updateLanguage]);

    const updateExportFormat = React.useCallback((e) => {
        setExportFormat(e.target.value);
    }, []);

    useEffect(() => {
        if (!props.open) {
            setAllSourceDropdownStates([]);
            allSources.current = [];
            setLanguageDropdownStates([]);
            shireFiles.current = {
                sv: null,
                no: null,
                da: null,
                fi: null,
                en: null
            };
        }
    }, [props.open]);

    const dialog = (
        <IFrameDialog
            src={iFrameSrc}
            open={true}
            onClose={closeDialog}
            data={
                {
                    token: token
                }
            }
        />
    );

    if (props.renderOnlyDialog) {
        return dialog;
    }

    if (!props.open) {
        return null;
    }

    if (dialogOpen) {
        return dialog;
    }

    const convertSubtitleRequest = async (file, format, save) => {
        let exportFormat = getExportFormat(format);

        const request = {
            input: {
                assetGuid: assetId,
                fileId: file.id,
                format: "shire2"
            },
            output: {
                format
            }
        }

        if (format === "timedtext") {
            request.output.extension = ".ttml";
        }

        if (save) {
            request.output.saveToAsset = {
                assetGuid: assetId,
                mimeType: exportFormat.mimeType,
            }
        }

        let res = await convertSubtitle(request);

        if (res.url) {
            if (save) {
                displayAlert("success", "Subtitle saved successfully");
                return;
            }
            let fileUrl = res.url;
            const anchor = document.createElement('a');
            anchor.href = fileUrl;
        
            // Append the anchor to the body (necessary for Firefox)
            document.body.appendChild(anchor);
        
            // Trigger a click event on the anchor
            anchor.click();
        
            // Remove the anchor from the document
            document.body.removeChild(anchor);
        } else {
            displayAlert("error", "Failed to convert subtitle");
        }
    };

    return (
        <div className="sh-dialog-wrapper" onClick={props.onClose}>
            <div className='sh-dialog' onClick={e => e.stopPropagation()}>
                <div className="sh-container-new-from-existing">
                    <div className='sh-form-group'>
                        <label>Language:</label>
                        <DropDown
                            name="languageDropdown"
                            states={languageDropdownStates}
                            currentState={language}
                            onChange={updateLanguageFromDropdown}
                        />
                    </div>
                    <div className='sh-form-group'>
                        <label>Source:</label>
                        <DropDown
                            name="sourceDropdown"
                            states={allSourceDropdownStates}
                            currentState={editSource}
                            onChange={updateEditSource}
                        />
                    </div>
                    <Button
                        onClick={() => openDialog()}
                        title="Open"
                    />
                </div>

                {
                    shireFiles.current[language] 
                    && (
                        <div className='sh-dialog-section'>
                            <label>Manage {LANGUAGE_MAP[language]} draft</label>
                            <div className="sh-container-new-from-existing">
                                <div className='sh-form-group'>
                                    <label>Format:</label>
                                    <DropDown
                                        name="exportFormatDropdown"
                                        states={exportFormats}
                                        currentState={exportFormat}
                                        onChange={updateExportFormat}
                                    />
                                </div>
                                <Button
                                    onClick={() => convertSubtitleRequest(shireFiles.current[language], exportFormat)}
                                    title="Download"
                                    disabled={!shireFiles.current[language]}
                                />
                                <Button
                                    onClick={() => convertSubtitleRequest(shireFiles.current[language], exportFormat, true)}
                                    title="Save to Comet"
                                    disabled={!shireFiles.current[language] || !appConfig.features.metadataSubtitleEditorAllowSaving}
                                />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
}

function getSHUrl(program, language, token, mode, fileId) {
    const params = new URLSearchParams();
    if (token.length < 1800) {
        params.set("tkn", token);
    }
    params.set("programGuid", program?.guid);
    params.set("lang", language);
    params.set("mode", mode);
    if (fileId) {
        params.set("fileId", fileId);
    }

    const testPlayerBaseUrl = appConfig.features.metadataSHBaseUrl;
    return `${testPlayerBaseUrl}?${params.toString()}`;
}

function useStarToken(disabled = false) {
    const [token, setToken] = React.useState(null);

    React.useEffect(
        () => {
            if (!disabled) {
                const fetchToken = async () => {
                    // Get Star token since we need to pass it to the iframe to make STAR-requests from there
                    const starToken = await getServiceToken("star");
                    setToken(starToken);
                }
                fetchToken();
            }
        },
        [disabled]
    );

    return token;
}

function findVideoAssetInContainer(container, videoFormat) {
    if (!container.assets) {
        return null;
    }
    return container.assets
        .sort((a, b) => b.created.localeCompare(a.created))
        .find((asset) => 
            asset.type?.toLowerCase() === "video" 
            && asset.format?.toLowerCase() === videoFormat.toLowerCase() 
            && !["deleted", "trashed", "replaced"].includes(asset.status?.toLowerCase())
        );
}