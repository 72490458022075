import React, { Component } from 'react'
import PropTypes from 'prop-types'

const Tab = ({ onClick, isActive, label }) => {
	return (
		<div className={`c6-tab ${isActive ? "active" : ""}`} onClick={onClick}>
			{label}
		</div>
	);
};

Tab.propTypes = {
	isActive: PropTypes.bool,
	label: PropTypes.oneOfType([ PropTypes.string, PropTypes.element ]).isRequired,
	id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
	onClick: PropTypes.func,
};

export default Tab;