import React from 'react'

import Link from '../shared/link'
import Widget from './widget'
import LazyLoad from '../../../components/lazyload'
import { getItemHref } from '../shared/utils'

import appConfig from 'config'

import './feature.css'

const Feature = (props) => {
	const { widgetData = {}, widgetLoaded, widgetLoadFailed, contentIfWidgetLoadFailed } = props;
	const { items = [], heading, subHeading } = widgetData; 
	const item = items[0] ?? {};
	const { assets: itemAssets = [], program = {} } = item;

	const image = itemAssets.find(a => a.type === "image");
	const { src, srcSet } = getSources(image);
	const href = getItemHref(item);

	const title = heading ?? program.title;
	const description = subHeading ?? program.synopsis?.long;
	return (
		<div className="c6-press-feature">
			{widgetLoadFailed && <div>{contentIfWidgetLoadFailed}</div>}
			{widgetLoaded && (
				<div>
					<LazyLoad offsetVertical={600}>
						<img
							alt={heading}
							src={src}
							srcSet={srcSet}
							sizes={`(max-width: 450px) 300px, 600px`}
						/>
					</LazyLoad>
					<h2>{title}</h2>
					<p>
						{description}
						<br/>
						<Link to={href} className="c6-press-button">Se programmet</Link>
					</p>
				</div>
			)}
		</div>
	);
}

export default Widget(Feature);

function getSources(image) {
	if (image && image.id) {
		const srcFormat = "Star_None_300x";
		const srcFormat2x = "Star_None_600x";
		const imgUrlStart = appConfig.media.star;

		return {
			src: `${imgUrlStart}${image.id}/${srcFormat}.jpg`,
			srcSet: `${imgUrlStart}${image.id}/${srcFormat}.jpg 600w, ${imgUrlStart}${image.id}/${srcFormat2x}.jpg 1200w`,
		}
	}

	return {};
}