import React from 'react'
import moment from 'moment'
import xor from 'lodash/xor'
import Flatpickr from 'react-flatpickr'
import { Swedish } from "flatpickr/dist/l10n/sv.js"
import 'flatpickr/dist/flatpickr.min.css'

import Search from '../ui/controls/search'
import Switch from '../ui/controls/switch'
import Pick from '../ui/controls/pick'
import Dropdown from '../ui/controls/dropdown'
import Button from '../ui/controls/button'

import { useLocalStorage } from '../../core/hooks/useStorage'

export const Filters = React.memo(({ filterConfig, filters, collapseFiltersDefault, appKey }) => {
	const { values, updateMethods } = filters;

	if (!Object.values(filterConfig).length) {
		return null;
	}

	const collapseDefault = collapseFiltersDefault ?? true;
	const [collapseFilters, setCollapseFilters] = useLocalStorage(`filters-collapse-${appKey}`, collapseDefault);
	const hasHideableFilters = Object.values(filterConfig).some(f => !f.alwaysVisible);
	
	return (
		<React.Fragment>
			{Object.entries(filterConfig)
				.filter(([name, config]) => {
					let customHidden = false;
					if (typeof config.hidden === "function") {
						customHidden = config.hidden(values);
					}

					const hideThisFilter = customHidden || (collapseFilters && !config.alwaysVisible);
					return !hideThisFilter;
				})
				.map(([name, config]) => {
					const { type, title, options, hidden, disabled = false, alwaysVisible, ...otherProps } = config;
					const value = values[name];
					const updateMethod = updateMethods[name];
					
					return (
						<Filter
							key={name}
							name={name}
							type={type}
							value={value}
							updateMethod={updateMethod}
							title={title}
							options={options}
							disabled={typeof disabled === "function" ? disabled(values) : disabled}
							otherProps={otherProps}
						/>
					);
			})}
			{hasHideableFilters && collapseFilters && <a className="c6-link icon-chevron_right hide-filters" onClick={() => setCollapseFilters(false)}>More</a>}
			{hasHideableFilters && !collapseFilters && <a className="c6-link icon-chevron_left hide-filters" onClick={() => setCollapseFilters(true)}>Less</a>}
		</React.Fragment>
	);
});

export const Filter = React.memo(({ name, type, value, updateMethod, title, options, disabled, otherProps }) => {
	switch (type) {
		case "search":
			return (
				<Search
					key={name}
					onChange={e => updateMethod(e.target.value)}
					placeholder="Search"
					searchText={value}
					disabled={disabled}
					{...otherProps}
				/>
			);
		case "switch":
			return (
				<Switch
					key={name}
					name={name}
					title={title}
					onChange={e => updateMethod(e.target.value)}
					states={options}
					currentState={value}
					disabled={disabled}
					{...otherProps}
				/>
			);
		case "pick":
			const currentState = Array.isArray(value) ? value : (value?.split(",") ?? []);
			return (
				<Pick
					key={name}
					name={name}
					title={title}
					onChange={e => {
						// lodash.xor will toggle value
						const newValue = xor(currentState, [e.target.value]);
						const newValueStr = newValue.filter(v => v?.length).join(",");
						updateMethod(newValueStr);
					}}
					states={options}
					currentState={currentState}
					disabled={disabled}
					{...otherProps}
				/>
			);
		case "dropdown":
			return (
				<Dropdown
					key={name}
					name={name}
					title={title}
					onChange={e => updateMethod(e.target.value)}
					states={options}
					currentState={value}
					disabled={disabled}
					{...otherProps}
				/>
			);
		case "date":
			return (
				<Flatpickr
					key={name}
					options={{
						altInput: true,
						altFormat: otherProps.altFormat ?? "D j M",
						locale: Swedish,
					}}
					value={value}
					onChange={(_, val) => updateMethod(moment(val).format())}
					onReady={(_, __, fp) => fp.calendarContainer?.classList.add("c6-generic-app-flatpickr")}
					disabled={disabled}
				/>
			);
		case "button":
			return (
				<Button
					key={name}
					label={title}
					type={otherProps.buttonType}
					onClick={otherProps.onClick}
					disabled={disabled}
				/>
			);
		case "text":
			return (
				<span className="c6-generic-app-text">
					<span style={{ marginRight: "5px" }}>{title}</span>
					<input
						type="text"
						defaultValue={value}
						onChange={e => updateMethod(e.target.value)}
						{...otherProps}
					/>
				</span>
			);
		default:
			console.warn(`No filter match for type: ${type}`);
			return null;
	}
});