import React from 'react'
import TextField from '@mui/material/TextField'
import getSlug from 'speakingurl'

import { Regions, AllRegions } from '../../../core/constants'

import './muiSlug.css'

export default class MuiSlug extends React.Component {
	constructor(props) {
		super(props);

		this.handleTextChange = this.handleTextChange.bind(this);
		this.setSlug = this.setSlug.bind(this);

		this.inputRef = null;

		this.state = {
			value: props.value || "",
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value && this.inputRef) {
			this.setState({ value: this.props.value || "" });
		}
	}

	handleTextChange = e => {
		const { value } = e.target;
		this.setState({ value });

		const { formContext = {} } = this.props;
		if(formContext.editorActions && formContext.editorActions.setDirty) {
			formContext.editorActions.setDirty();
		}
	}

	handleBlur = e => {
		this.commitChange(e);
	}

	// Update the editor model on enter key or blur event
	commitChange = e => {
		if (
			//e.type === "change" ||
			e.type === "blur" ||
			e.which == 13 ||
			e.keyCode == 13
		) {
			let { value } = e.target;
			if (!value && this.props.options && this.props.options.sendInt32MinWhenEmpty) {
				value = -2147483648;
			}

			e.preventDefault();
			e.target.blur();

			// Only update model if value actually has changed
			const type = this.props.schema?.type;
			value = type === "number" ? parseInt(value ?? 0) : String(value ?? "");
			const oldValue = type === "number" ? parseInt(this.props.value ?? 0) : String(this.props.value ?? "");
			if (value !== oldValue) {
				this.props.onChange(value, e);
			}
		}
	};

	setSlug(value, e) {
		this.setState({ value });
		this.props.onChange(value, e);
	}

	render() {
		const {
			formContext = {},
			required,
			label,
			readonly = false,
			schema,
		} = this.props;

		const { value } = this.state;

		const labelText = `${label}${required ? " *" : ""}`;

		// When an empty Slug is in an unapproved editor we want to warn the user but still allow saving with an empty Slug
		if(!formContext.model) {
			console.warn("No model provided through formContext so Slug suggestions won't be rendered,");
		}
		const { model = {} } = formContext;
		const { approval = {} } = model;
		const missingSlug = approval.status && approval.status.toLowerCase() === "approved" && (!value || value.trim() === "");

		let description = null, descriptionStyle = {};
		if(missingSlug) {
			descriptionStyle = {
				color: "var(--pending-color)",
			};
			description = <span className="description" style={descriptionStyle}>Hey, are you <strong>really sure</strong> you don't want to add a NID?</span>;
		}
		else if(!this.props.value) {
			descriptionStyle = {
				color: "var(--text-lighter-color)",
			};

			const slug = getSlugSuggestion(model);
			description = slug ? <span className="description" style={descriptionStyle}>Suggestion: <span className="c6-link" onClick={this.setSlug.bind(this, slug)}>{slug}</span></span> : null;
		}

		return (
			<div className="c6-mui-slug">
				<TextField
					inputRef={ref => this.inputRef = ref}
					variant="standard"
					className="c6-mui"
					name="muiSlug"
					color="primary"
					InputProps={{ readOnly: readonly }}
					label={labelText}
					value={value}
					onBlur={this.handleBlur}
					onKeyDown={this.commitChange}
					onChange={this.handleTextChange}
					style={{ width: '100%' }}
					type={schema && schema.type || "text"}
					inputProps={{
						"data-1p-ignore": true, // tell 1Password it should ignore the field
						"data-lpignore": true, // tell LastPass it should ignore the field
					}}
				/>
				{description}
			</div>
		);
	}
}

// HELPERS
const getSlugSuggestion = model => {
	const localLan = AllRegions.find(r => r.language === "sv");
	const swedishLocale = (model.versions || []).find(v => v.versionId === localLan.id) || {};

	return getSlug(swedishLocale.title || model.displayName || model.originalTitle || "", {
		lang: 'sv',
		symbols: false,
		custom: { // Also allow these characters without transformation
			"Ĉ": "c",
			"ċ": "c",
			"ė": "e",
			"Ĕ": "e",
			"ĝ": "g",
			"Ġ": "g",
			"Ģ": "g",
			"Ĥ": "h",
			"ĥ": "h",
			"Ħ": "h",
			"ħ": "h",
			"į": "i",
			"ĭ": "i",
			"ĵ": "j",
			"ĳ": "ij",
			"ĸ": "k",
			"ŀ": "l",
			"Ŋ": "ng",
			"ŋ": "ng",
			"ŉ": "n",
			"Ō": "o",
			"ō": "o",
			"Ŏ": "o",
			"ŏ": "o",
			"Ŗ": "r",
			"ŗ": "r",
			"Ŝ": "s",
			"ŝ": "s",
			"Ŧ": "t",
			"ŧ": "t",
			"Ü": "u",
			"Ų": "u",
			"ų": "u",
			"Ů": "u",
			"Ŭ": "u",
			"ŭ": "u",
			"Ŵ": "w",
			"ŵ": "w",
			"Ŷ": "y",
			"ŷ": "y",
			"Ÿ": "y",
			"å": "å",
			"ä": "ä",
			"Æ": "ä",
			"ö": "ö",
			"Ø": "ö",
			"×": "x",
			"_": "-",
			"'": "",
			"^": "",
		}
	});
}