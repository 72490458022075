import React from 'react'
import PropTypes from 'prop-types'
import { browserHistory } from 'browserHistory'
// import moment from 'moment'

import ResponsiveImage from '../assets/responsiveImage'

import {
	getProgramLink,
	getSynopsis,
	getSwedishVersion,
	getUpcomingBroadcasts,
	renderBroadcasts,
	renderNextUpcomingBroadcast,
	renderLocalRating,
	renderExposures,
	getUpcomingEpisode,
	renderEmptySeries,
} from './utils'
import {
	getPathWithNamesInsteadOfIDs,
	renderProgramReviews,
} from './pressUtils'
import { trackAdobeAnalyticsPageView } from '../../core/services/tracking'
import { copyHtmlToClipboard } from '../../utils/misc'

import Metadata from './metadata'
import PressImages from './pressImages'
import SportSeason from './sportSeason'

import appConfig from 'config'

import './program.css'
import './pressProgram.css'

export default class Program extends React.Component {

	static propTypes = {
		program: PropTypes.object,
		season: PropTypes.object,
		episodes: PropTypes.array,
		id: PropTypes.string,
		seasonId: PropTypes.string,
		seriesId: PropTypes.string,
		isLoading: PropTypes.bool,
		onPressImageClick: PropTypes.func,
		seasons: PropTypes.array,
		getSeasonText: PropTypes.func,
		programSchedules: PropTypes.object,
		programReviews: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
		allowPressImageDownload: PropTypes.bool,
		allowPressImagePreview: PropTypes.bool,
		texts: PropTypes.object,
		getProgramLink: PropTypes.func,
		timezone: PropTypes.string,
	}

	state = {
		pageName: null
	};

	componentDidMount() {
		const { program = {}, season = {} } = this.props;
		if (program?.id || season?.id) {
			const pageName = getPathWithNamesInsteadOfIDs(this.props);
			this.setState({ pageName });
			trackAdobeAnalyticsPageView(pageName);
		}
	}

	componentDidUpdate(prevProps) {
		const { id, season = {}, program = {} } = this.props;
		const { id: prevId, season: prevSeason = {}, program: prevProgram = {} } = prevProps;
		const episode = season.episodes?.find(e => e.id === id) || {};
		const prevEpisode = prevSeason.episodes?.find(e => e.id === prevId) || {};

		if (
			season.id !== undefined && season.id !== prevSeason.id
			|| program.id !== prevProgram.id
			|| episode.id !== prevEpisode.id
		) {
			const pageName = getPathWithNamesInsteadOfIDs(this.props);
			this.setState({ pageName });
			trackAdobeAnalyticsPageView(pageName);
		}
	}

	render() {
		const {
			id,
			seasonId,
			seriesId,
			program = {},
			season,
			episodes,
			isLoading,
			onPressImageClick,
			seasons = [],
			getSeasonText,
			programSchedules,
			programReviews = {},
			allowPressImageDownload,
			allowPressImagePreview,
			texts = {},
			getProgramLink: getProgramLinkOverride,
			timezone,
			series,
		} = this.props;
		
		if (!season?.id && series?.id)
			return renderEmptySeries(series);
		if (isLoading || !(program.id || season?.id))
			return null;
		if (season?.classification === "sport")
			return <SportSeason {...this.props} pageName={this.state.pageName} />;

		let currentEpisode = id ? (episodes || []).find(e => e.id === id) : getUpcomingEpisode(episodes);
		const provider = program?.provider || season?.provider;

		const firstArea = season
			? <SeasonPart
				program={season}
				episodes={episodes}
				seriesId={seriesId}
				seasonId={seasonId}
				id={id || currentEpisode?.id}
				seasons={seasons}
				programSchedules={programSchedules}
				getSeasonText={getSeasonText}
				texts={texts}
				provider={provider}
				getProgramLinkOverride={getProgramLinkOverride}
				timezone={timezone}
				allowPreview={allowPressImagePreview}
				onVideoClick={onPressImageClick}
				pageName={this.state.pageName}
			/>
			: <ProgramPart
				program={program}
				programSchedules={programSchedules}
				texts={texts}
				provider={provider}
				timezone={timezone}
				season={season}
				allowPreview={allowPressImagePreview}
				onVideoClick={onPressImageClick}
			/>;

		return (
			<div className={`c6-program ${season?.id ? "program-season" : ""} press-program`}>
				<div className="section">{firstArea}</div>
				<div className="section">
					{renderProgramReviews({ seasonNumber: season?.seasonNumber, data: programReviews })}
					{currentEpisode && (
						<ProgramPart
							program={currentEpisode}
							programSchedules={programSchedules}
							texts={texts}
							provider={provider}
							timezone={timezone}
							season={season}
							allowPreview={allowPressImagePreview}
							onVideoClick={onPressImageClick}
							pageName={this.state.pageName}
						/>
					)}
					{currentEpisode && <hr />}
					<PressImages
						main={season || program}
						episode={currentEpisode}
						series={season && season.series}
						onClick={onPressImageClick}
						allowDownload={allowPressImageDownload}
						allowPreview={allowPressImagePreview}
						texts={texts}
						provider={provider}
					/>
				</div>
			</div>	
		);
	}
}

// When rendering single programs or episodes
const ProgramPart = ({ program, programSchedules, texts, provider, timezone, season, allowPreview, onVideoClick, pageName }) => {
	const { episodeNumber } = program?.episodeInfo || program;
	const title = program?.title || program?.originalTitle;
	const upcomingBroadcasts = getUpcomingBroadcasts(programSchedules, season?.type === "season" ? "season" : "single")

	const enableCopy = appConfig.features.pressCopyProgramInfoToClipboard;
	return (
		<div className="program-part">
			{getMainResponsiveImage(program, provider, allowPreview, onVideoClick, texts, pageName)}
			{(episodeNumber || title || enableCopy) && (
				<h1 className="program-title">
					{episodeNumber ? `${texts["episode"] || "Episod"} ${episodeNumber}` : null}
					{episodeNumber && title ? " - " : null}
					{title}
					{!episodeNumber && enableCopy && (
						<span className="icon-content_copy c6-link" onClick={copyProgramInfo.bind(this, texts, null)}>{texts["copyProgramInfo"]}</span>
					)}
				</h1>
			)}
			{getSynopsis(program, false, undefined, texts)}
			{renderLocalRating(program)}
			<Metadata program={program} texts={texts} />
			{renderExposures(program, texts)}
			{renderBroadcasts(program, upcomingBroadcasts, texts, timezone)}
		</div>
	);
};

// When rendering seasons
const SeasonPart = ({
	program,
	episodes,
	seriesId,
	seasonId,
	id,
	seasons: seriesSeasons,
	getSeasonText,
	programSchedules,
	texts,
	provider,
	getProgramLinkOverride,
	timezone,
	allowPreview,
	onVideoClick,
	pageName,
}) => {
	const seriesSeasonsOrFallback = seriesSeasons?.length > 0 ? seriesSeasons : [program]; // If there is no series (or series.seasons), use a list of just the current season
	const seasons = getSeasons(seriesSeasonsOrFallback, getSeasonText);
	// const { seasonNumber, numberOfEpisodes } = program.seasonInfo || program;
	const hideExtendedForSeason = false;
	const title = program?.title || program?.series?.originalTitle;
	const upcomingBroadcasts = getUpcomingBroadcasts(programSchedules, "season");
	const enableCopy = appConfig.features.pressCopyProgramInfoToClipboard;
	return (
		<div className="season-part">
			{getMainResponsiveImage(program, provider, allowPreview, onVideoClick, texts, pageName)}
			<div className="program-title-wrapper">
				<h1 className="program-title">
					{title && <span>{title}</span>}
				</h1>
				{enableCopy && (
					<span className="icon-content_copy c6-link copy-program-info" onClick={copyProgramInfo.bind(this, texts, seasonId)}>{texts["copyProgramInfo"]}</span>
				)}
				<select value={seasonId} onChange={e => onSelectSeason(e, seriesId, getProgramLinkOverride)} disabled={seasons.length <= 1}>
					{seasons.map(season => (
						<option key={season.key} value={season.key}>{season.text}</option>
					))}
				</select>
			</div>
			{getSynopsis(program, false, undefined, texts, hideExtendedForSeason)}
			<Metadata program={program} texts={texts} showNumberOfEpisodes={true} />
			{renderNextUpcomingBroadcast(program, upcomingBroadcasts, texts, timezone)}
			{getEpisodes(episodes, id, seasonId, seriesId, texts, getProgramLinkOverride)}
		</div>
	);
};

// HELPERS
function getMainResponsiveImage(program, provider, allowPreview, onVideoClick, texts, pageName) {
	const image = program?.assets?.find(a => a.category === "main" && a.type === "image");
	let video = program?.assets?.find(a => a.category === "press" && a.type === "video")
		?? program?.assets?.find(a => a.category === "press" && a.type === "clip");
	
	if (appConfig.features.pressDisableVideoPlayerOnProgramPage) {
		video = null;
	}

	return (image || video) && (
		<div className="main-image">
			{provider === "Hayu" && <span className="channel-logo channel-hayu" />}	
			{image && (
				<ResponsiveImage
					id={image.id}
					sizes="(min-width: 1500px) 33vw, (min-width: 985px) 40vw, (min-width: 665px) 50vw, 100vw"
					extension={["png", "tif"].includes(image.ext?.toLowerCase()) ? "png" : "jpg"}
				/>
			)}
			{!image && <div className="dummy-image"></div>}
			{video && (
				<React.Fragment>
					{!allowPreview && texts?.["pressPreviewsNotAllowed"] && <div className="download-not-allowed">{texts["pressPreviewsNotAllowed"]}</div>}
					<div
						className={`video-indicator icon-play_circle_outline ${allowPreview ? "playable" : ""}`}
						onClick={allowPreview ? e => onVideoClick(e, video, pageName) : null}
					/>
				</React.Fragment>
			)}
		</div>
	);
}

function getEpisodes(episodes = [], episodeId, seasonId, seriesId, texts, getProgramLinkOverride) {
	function handleSelectEpisode(episodeId) {
		browserHistory.push((getProgramLinkOverride || getProgramLink)(seriesId, seasonId, episodeId));
	}

	if (!episodes.length) {
		return (
			<article className="no-episodes disabled"><p><em>{texts["noEpisodesAvailable"] || "Inga planerade avsnitt."}</em></p></article>
		);
	}

	return episodes.map(episode => {
		const { id, versions, episodeInfo = {} } = episode;

		// let longSynopsis = "";
		let shortSynopsis = "";
		const synopsis = versions && versions[0] && versions[0].synopsis || episode.synopsis;
		if (synopsis) {
			const { extended, long, medium, brief, short } = synopsis;
			// longSynopsis = extended || long || medium || brief || short || <em>{texts["noEpisodeSynopsis"] || "Ingen episodsynopsis."}</em>;
			shortSynopsis = short || brief || medium || long || extended;
		}

		const className = id === episodeId ? "sel" : null

		return (
			<article key={id} className={className} onClick={handleSelectEpisode.bind(this, id)}>
				<div>{episodeInfo.episodeNumber || episode.episodeNumber}</div>
				<p>{shortSynopsis || <em>{texts["noEpisodeSynopsis"] || "Ingen episodsynopsis."}</em>}</p>
			</article>
		);
	});
}

function getSeasons(seriesSeasons, getSeasonText) {
	return seriesSeasons
		// .filter(season => isActiveSeason(season))
		.map(season => {
			const  { id, versions = [], seasonInfo, label } = season;
			const seasonTitle = getSwedishVersion(versions).title || season.title;
			const  { productionYear, seasonNumber } = seasonInfo || season;

			return {
				key: id,
				text: getSeasonText
					? getSeasonText(seasonTitle, label, productionYear, seasonNumber)
					: seasonTitle || label || (productionYear || seasonNumber ? `SÄSONG ${productionYear || seasonNumber} SAKNAR Season Label` : "?"),
			};
		});
}

// function isActiveSeason(season) {
// 	const now = moment();
// 	return (season.exposures || []).some(e => moment(e.from).isBefore(now) && moment(e.until).isAfter(now));
// }

function onSelectSeason(e, seriesId, getProgramLinkOverride) {
	const seasonId = e.target.value;
	browserHistory.replace((getProgramLinkOverride || getProgramLink)(seriesId, seasonId));
}

function copyProgramInfo(texts, seasonId, e) {
	// TODO: Find a way to replace channel logos with their names
	const content = document.createElement("div");
	if (seasonId) {
		const seasonContent = document.querySelector(".season-part");
		const episodeContent = document.querySelector(".program-part");
		content.innerHTML += seasonContent.outerHTML;
		content.innerHTML += episodeContent.outerHTML;
	} else {
		const programContent = document.querySelector(".program-part");
		content.innerHTML += programContent.outerHTML;
	}
	
	// add ratings as text instead of images
	content.querySelectorAll(".local-rating img").forEach(img => {
		const rating = img.alt;
		const div = document.createElement("div");
		div.textContent = rating;
		img.replaceWith(div);
	});
	// remove copy button
	content.querySelector(".icon-content_copy")?.remove();
	// remove hr
	content.querySelectorAll("hr").forEach(hr => hr.remove());
	// remove images
	content.querySelectorAll("img").forEach(img => img.remove());
	// replace non-breaking spaces
	content.innerHTML = content.innerHTML.replace(/&nbsp;/g, " ");
	// remove episodes
	content.querySelectorAll("article").forEach(article => article.remove());
	// remove this season's next broadcast
	content.querySelector(".season-part .program-broadcasts")?.remove();
	// replace tables with their text content
	content.querySelectorAll("table").forEach(table => {
		const rows = table.querySelectorAll("tr");
		const newNode = document.createElement("div");
		rows.forEach(row => {
			const newRow = document.createElement("div");
			newRow.innerHTML = row.textContent;
			newNode.appendChild(newRow);
		});
		table.replaceWith(newNode);
	});
	// add separator between actor and role
	content.querySelectorAll(".program-credits > div > span:nth-child(2)").forEach(span => span.textContent = ": " + span.textContent);
	// for seasons: remove season <option> tags except for the selected one
	content.querySelectorAll(".season-part select option").forEach(option => {
		if (option.value !== seasonId) {
			option.remove();
		}
	});

	copyHtmlToClipboard(content.innerHTML, texts["copyProgramInfoDone"], e);
}
