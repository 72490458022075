import React from 'react'
import { withRouter, Link } from 'react-router'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'

import AuthActions from './actions'
import UserStore from './store'

import './login.css'

import appConfig from 'config'

@withRouter
export default class Login extends React.Component {

	state = {
		username: "",
		password: "",
		extraLoginCheck: false,
		loginErrorMessage: "",
		passwordErrorMessage: "",
		...UserStore.getState(),
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
	}

	componentWillUnmount() {
		// AuthActions.unmount();
		UserStore.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { router, location, modal = false } = this.props;
		const { username, password, extraLoginCheck, isLoading } = this.state;

		if (isLoading) {
			return false;
		}

		const validExtraLoginCheck = !appConfig.features.extraLoginPassword || extraLoginCheck;
		if (username && password && validExtraLoginCheck) {
			AuthActions.loginWithUserPass({ username, password, location, router, modal });
		} else if (!validExtraLoginCheck) {
			AuthActions.loginFailed({ exceptionMessage: "Check that checkbox, please!" });
		} else {
			AuthActions.loginFailed({ exceptionMessage: "Fill both fields, please!" });

			if(!username) {
				const loginErrorMessage = "Please type your login/e-mail!";
				this.setState({ loginErrorMessage });
			}
			if(!password) {
				const passwordErrorMessage = "Please type your password!";
				this.setState({ passwordErrorMessage });
			}
		}
	}

	handleUserChange = (e) => {
		this.setState({
			username: e.target.value,
			loginErrorMessage: "",
		});
	}

	handlePassChange = (e) => {
		this.setState({
			password: e.target.value,
			passwordErrorMessage: "",
		});
	}

	handleExtraLoginCheckChange = (e) => {
		this.setState({
			extraLoginCheck: e.target.value,
		});
	}

	handleLoginNav = (e) => {
		e.preventDefault();
		const { modal = false } = this.props;
		AuthActions.displayForgotPassword(modal);
	}

	handleInputKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	}

	handleSSOLogin = (e) => {
		e.preventDefault();
		const { router, location, modal = false } = this.props;
		const { isLoading } = this.state;

		if (isLoading) {
			return false;
		}

		AuthActions.loginWithSSO({ location, router, modal });
	}

	render() {
		const { router, location } = this.props;
		const { username, password, extraLoginCheck, loginErrorMessage, passwordErrorMessage, error, errorMessage, loggedIn, isLoading } = this.state;
		const classNames = error
			? "c6-authentication-form c6-authentication-error"
			: "c6-authentication-form" ;

		const progress = isLoading ? <CircularProgress className="loading-icon" size={16} thickness={3.6} /> : null;

		const nextHelpText = "You are trying to log in to next-comet. Check this box so that you are aware of this!";
		
		return (
			<div className={classNames}>
				<header>
					<h1>
						{ error ? errorMessage : "Log in " }
						{ appConfig.env === "Acceptance" && (<span title={nextHelpText}>{"(Comet Next)"}</span>)}
					</h1>
					{appConfig.env === "Acceptance" && <a className="c6-link" href="https://comet.b17g.net">Take me to Comet!</a>}
				</header>
				<form onSubmit={this.handleSubmit}>
					<div>
						<div>
							<TextField
								helperText={loginErrorMessage}
								variant="standard"
								color={loginErrorMessage ? "error" : "primary"}
								label="Login/E-mail"
								placeholder="user@something.com"
								value={username}
								onKeyDown={this.handleInputKeyPress}
								onChange={this.handleUserChange}
								focused={loginErrorMessage ? true : undefined}
								fullWidth
								className="c6-authentication-input"
							/>
						</div>
						<div>
							<TextField
								helperText={passwordErrorMessage}
								variant="standard"
								color={passwordErrorMessage ? "error" : "primary"}
								label="Password"
								type="password"
								value={password}
								onKeyDown={this.handleInputKeyPress}
								onChange={this.handlePassChange}
								focused={passwordErrorMessage ? true : undefined}
								fullWidth
								className="c6-authentication-input"
							/>
						</div>
					</div>
					<div className="form-bottom">
						<div>
							<Link
								to="/forgotpassword"
								className="c6-link"
								onClick={this.handleLoginNav}>
								Forgot password
							</Link>
						</div>
						<div className="right">
							<Button
								type="submit"
								variant="contained"
								onClick={this.handleSubmit}
								startIcon={progress}
								color="primary"
							>
								Log in
							</Button>
						</div>
					</div>
				</form>
				{appConfig.features.ssoEnabled && (
					<div className="sso">
						<Button
							type="button"
							variant="outlined"
							onClick={this.handleSSOLogin}
							color="primary"
						>
							JuneFirst SSO
						</Button>
					</div>
				)}
			</div>
		);
	}
}
