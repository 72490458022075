import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import { DragSource, DropTarget } from 'react-dnd'

import Button from '../../controls/button'
import Suggestions from '../../suggestions'

import { getPrimary } from '../../../../utils/assets' //TODO: remove when we have real images
import LazyLoad from '../../../lazyload' //TODO: remove when we have real images
import Spinner from '../../../spinner'

const ItemTypes = { PERSON: "person" };

const personSource = {
	beginDrag(props) {
		return {
			id: props.person.id,
			index: props.index,
			categoryKey: props.categoryKey
		};
	},
	canDrag(props) {
		// Allow dragging when there is a movePerson() function provided and readOnly is false
		return props.movePerson && !props.readOnly;
	}
};

const personTarget = {
	hover(props, monitor, component) {
		if (monitor.getItem().categoryKey != props.categoryKey) {
			return
		}

		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Do nothing if hovered person is same as dragging person
		if (dragIndex === hoverIndex)
			return;

		const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
		const clientOffset = monitor.getClientOffset();
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only move persons when the mouse has crossed half the width (+/- 10px, feels better?)
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY - 10)
			return;
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY + 10)
			return;

		props.movePerson(dragIndex, hoverIndex);
		monitor.getItem().index = hoverIndex;
	},
	drop(props) {
		props.dropPerson();
	},
	canDrop(props, monitor) {
		return !props.readOnly && monitor.getItem().categoryKey === props.categoryKey;
	}
};

const dragSource = (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	connectDragPreview: connect.dragPreview(),
	isDragging: monitor.isDragging()
});
const dropCollect = (connect, mintor) => ({
	connectDropTarget: connect.dropTarget()
});

@DragSource(ItemTypes.PERSON, personSource, dragSource)
@DropTarget(ItemTypes.PERSON, personTarget, dropCollect)
export default class Person extends Component {

	constructor(props) {
		super(props);

		this.handleInputFocus = this.handleInputFocus.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleRoleInputFocus = this.handleRoleInputFocus.bind(this);
		this.handleRoleInputBlur = this.handleRoleInputBlur.bind(this);

		this.state = {
			focusedElement: null
		};

		this.mainInputRef = null;
		this.roleInputRef = null;
	}

	handleInputFocus(e) {
		this.setState(({
			focusedElement: e.target
		}));

		this.props.onInputFocus();
	}

	handleInputBlur(e) {
		const { person } = this.props;
		if (e.target.value !== person.name) {
			this.props.onActorChange(e);
		}
		this.props.onInputBlur(e);
	}

	handleRoleInputFocus(e) {
		this.setState({
			focusedElement: e.target
		});

		this.props.onInputFocus();
	}

	handleRoleInputBlur(e) {
		const { person } = this.props;
		if (e.target.value !== person.role) {
			this.props.onRoleChange(e);
		}
		this.props.onInputBlur(e);
	}

	render() {
		const {
			person,
			index,
			onDelete,
			query = "",
			suggestions = [],
			usedIds = [],
			selectedIndex = -1,
			withRole,
			onSuggestionClick,
			onSuggestionHover,
			minQueryLength = 1,
			isActive,
			onInputChange,
			onInputFocus,
			onInputKeyDown,
			inputRef,
			readOnly,
			isLoading,

			connectDragSource,
			connectDragPreview,
			isDragging,
			connectDropTarget
		} = this.props;

		let image;
		if (person.imageGuid) {
			image = getPrimary(person.imageGuid, "smallCover");
		} else if (person.imageUrl) { //TODO: remove when we have real images
			image = (
				<LazyLoad className="c6-preview cover" height={44}>
					<img src={person.imageUrl} height={44} />
				</LazyLoad>
			);
		}

		let actorInput = !readOnly
			? (
				<input
					ref={(ref) => {
						this.mainInputRef = ref;
						inputRef(ref, "main");
					}}
					type="text"
					defaultValue={person.name}
					placeholder="Name"
					onChange={onInputChange}
					onFocus={this.handleInputFocus}
					onBlur={this.handleInputBlur}
					onKeyDown={onInputKeyDown}
					readOnly={readOnly}
					data-1p-ignore={true} // tell 1Password it should ignore the field
					data-lpignore={true} // tell LastPass it should ignore the field
				/>
			)
			: <span className="actor-name">{person.name}</span>;


		let separator, roleInput;
		if (withRole) {
			separator = <div className="person-role-separator">as</div>;

			roleInput = (
				<div className="role">
					{!readOnly && (
						<input
							ref={(ref) => {
								this.roleInputRef = ref;
								inputRef(ref, "role");
							}}
							type="text"
							defaultValue={person.role}
							placeholder="Name of character"
							onFocus={this.handleInputFocus}
							onBlur={this.handleRoleInputBlur}
							onKeyDown={onInputKeyDown}
							readOnly={readOnly}
							data-1p-ignore={true} // tell 1Password it should ignore the field
							data-lpignore={true} // tell LastPass it should ignore the field
						/>
					)}
					{!readOnly && <span></span>}
					{readOnly && <span className="role-name">{person.role ||  "Name of character"}</span>}
				</div>
			);
		}

		const className = `c6-person${readOnly ? " read-only" : ""}`;
		const handleClassName = `handle${image ? " has-image" : ""}`;

		return connectDragPreview(connectDropTarget(
			<div className={className} style={{ opacity: isDragging ? 0 : 1 }}>
				{connectDragSource(
					<div className={handleClassName}>
						{!readOnly && <div className="c6-handle"></div>}
						{image}
						{!image && (<div className="icon-person"></div>)}
					</div>
				)}
				<div className="data">
					<div className="person">
						{actorInput}
						<Spinner loading={isLoading} />
						<span className="material-underline"></span>
						{isActive && (
							<Suggestions
								query={query}
								suggestions={suggestions}
								usedIds={usedIds}
								selectedIndex={selectedIndex}
								onSuggestionClick={onSuggestionClick}
								onSuggestionHover={onSuggestionHover}
								minQueryLength={minQueryLength}
								dataSourceConfig={{
									"value": "id",
									"text": "name"
								}}
							/>
						)}
					</div>
					{separator}
					{roleInput}
				</div>
				<div className="remove">
					{!readOnly && (
						<Button
							type="close"
							noBackground
							onClick={onDelete}
						/>
					)}
				</div>
			</div>
		));
	}
}