import { get, put, post, patch, del } from '../core/c6'

import appConfig from 'config'

const API = appConfig.features.plannerC80 ? "plannerc8" : "planner";

// PUBLISHWINDOWS
export const fetchCalendarPublishWindows 	        = filters 			=> 	get(API, "calendar/exposures", filters);
export const fetchCalendarPublishWindowsCount	    = filters 			=> 	get(API, "calendar/publishwindows/count", filters); // Fetches assets count
export const fetchPublishWindows 			        = filters			=>	get(API, "exposures", filters);
export const createPublishWindow 			        = payload 			=>  post(API, "exposures", payload);
export const updatePublishWindow 			        = ({ id }, payload) => 	put(API, `exposures/${id}`, payload);
export const approvePublishWindows			        = payload			=>	put(API, "exposures/approvechanges", payload);
export const undoPublishWindowsChanges		        = payload			=> 	put(API, "exposures/undochanges", payload);
export const deletePublishWindow                    = ({ id }, payload) =>  del(API, `exposures/${id}`, payload);

// EXCEL EXPORT
export const excelExport = (payload) => get(API, "excel", payload);

// LICENSES
export const fetchLicenses 	    = filters 			=> get(API, "licenses", filters);
export const fetchLicense	    = ({ id }) 			=> get(API, `licenses/${id}`);
export const updateLicense	    = ({ id }, payload) => put(API, `licenses/${id}`, payload);
export const fetchLicenseTypes  = filters           => get(API, "licenses/types", filters);

// PROGRAMS
export const fetchPrograms  = filters               => get(API, "programs/fromLicenses", filters);
export const fetchProgram   = ({ id, reference })   => {
    if (reference && !id) {
        return get(API, `programs/fromLicenses/reference/${reference}`);
    }
    return get(API, `programs/fromLicenses/${id}`);
}
export const updateProgram  = ({ id }, payload)     => put(API, `programs/${id}`, payload);

// PLATFORMS
export const fetchPlatforms = filters => get(API, "platforms", filters);



export const fetchUrl = url => get(API, url);