import { createTheme } from '@mui/material/styles'

// For now, we can't use our CSS variables (like --text-color) in the theme object due to limitations in mui
// UNLESS we choose to use the experimental API:
// https://mui.com/material-ui/experimental-api/css-theme-variables/migration/

/**
 * @type {import('@mui/material/styles').Theme}
 */
const baseTheme = {
	palette: {
		secondary: {
			main: "#fff",
			contrastText: "#fff",
		},
		error: {
			main: "#f44336",
			contrastText: "#fff",
		},
		success: {
			main: "#66bb6a",
			contrastText: "#fff",
		},
	},
	components: {
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				shrink: {
					transform: "translate(0, -1.5px) scale(0.9)", // Default is 0.75 but that's too small
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontSize: "1.1rem",
				},
			}
		},
	},
};

export const lightTheme = createTheme({
	palette: {
		...baseTheme.palette,
		mode: "light",
		text: {
			primary: "#212121"	
		},
		primary: {
			main: "#06a7b1",
			contrastText: "#fff",
		},
	},
	components: {
		...baseTheme.components,
		MuiDialog: {
			styleOverrides: {
				paper: {
					color: "#212121",
				},
			},
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					marginTop: "20px",
				}
			}
		},
	}
}, baseTheme);

export const darkTheme = createTheme({
	palette: {
		...baseTheme.palette,
		mode: "dark",
		text: {
			primary: "#a9d8dc",
		},
		primary: {
			main: "#61bbc2",
			contrastText: "#fff",
		},
	},
	components: {
		...baseTheme.components,
		MuiDialog: {
			styleOverrides: {
				paper: {
					color: "#a9d8dc",
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#90A4AE",
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				underline: {
					"&:before": {
						borderBottomColor: "#364c50",
					},
				},
			}
		},
		MuiListSubheader: {
			styleOverrides: {
				root: {
					backgroundColor: "#252525",
					marginTop: "20px",
				}
			}
		},
	}
}, baseTheme);
