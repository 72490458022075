import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'

import { getProgramLink } from '../../../components/program/utils'
import { getBroadcastSeasonEpisodeInfo } from '../shared/utils'

import './broadcast.css'

export default ({
	broadcast,
	showSeasonEpisodeNumbersForSports = true,
	programTitleMissingText = "Programtitel inte inmatad!",
	getProgramLink: getProgramLinkOverride,
	fixedUtcOffset,
	disableTooltip = false,
}) => {
	const { public: publicBroadcast, type = "", status = "", rights = [], title: broadcastTitle } = broadcast;
	const broadcastEnd = moment(publicBroadcast.end);
	const past = broadcastEnd.isBefore(moment()) ? "dim" : "";
	const time = fixedUtcOffset
		? moment(publicBroadcast.start).utcOffset(fixedUtcOffset).format("HH:mm")
		: moment(publicBroadcast.start).format("HH:mm");

	const { id, seriesId, seasonId, title, seasonNumber, episodeNumber, classification } = getBroadcastMetadata(broadcast);
	const seasonEpisode = getBroadcastSeasonEpisodeInfo(seasonNumber, episodeNumber, classification, showSeasonEpisodeNumbersForSports);

	const programTitle = broadcastTitle || title || <span className="attention">{programTitleMissingText}</span>;

	// Rights tooltip
	const day = publicBroadcast.start.substring(0, 10);
	const toolTip = rights.map(({ type = "UNKNOWN", from = "", until = "", fastForward }) => {
		const rightFrom = day === from.substring(0, 10) ? from.substr(11, 5) : from.substring(0, 16).replace("T", " ");
		const rightUntil = day === until.substring(0, 10) ? until.substr(11, 5) : until.substring(0, 16).replace("T", " ");
		return `${type.toUpperCase()} ${rightFrom} to ${rightUntil}${!fastForward ? " (No fast forward)" : ""}`;
	}).join("\n");

	return (
		<Link
			to={(getProgramLinkOverride || getProgramLink)(seriesId, seasonId, id)}
			className={`c6-schedule-broadcast ${past} type-${type} status-${status}`}
			title={disableTooltip ? null : toolTip}
		>
			<time dateTime={publicBroadcast.start}>{time}</time>
			<h1>{programTitle}{seasonEpisode && <span>{seasonEpisode}</span>}</h1>
		</Link>
	);
}

function getBroadcastMetadata(broadcast) {
	return {
		...broadcast.metadata,
		seasonNumber: broadcast.metadata && broadcast.metadata.seasonNumber || broadcast.seasonNumber,
		episodeNumber: broadcast.metadata && broadcast.metadata.episodeNumber || broadcast.episodeNumber,
		seasonId: broadcast.metadata && broadcast.metadata.seasonId || broadcast.seasonId,
		seriesId: broadcast.metadata && broadcast.metadata.seriesId || broadcast.seriesId,
		id: broadcast.metadata && (broadcast.metadata.id || broadcast.metadata.programId || broadcast.metadata.episodeId) || broadcast.programId,
		title: broadcast.metadata && broadcast.metadata.title || broadcast.title,
		classification: broadcast.metadata && broadcast.metadata.classification,
	}
}