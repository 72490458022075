import React, { Component } from 'react'
import debounce from 'lodash/debounce'

import ContainerNavigation from '../containereditor/containerNavigation'
import Items from '../shared/items'
import { decoratorUIActions } from '../../../core/decorators/uiActions'
import { CONTENT } from '../../../components/actions'
import { Filter, Left } from '../../../components/filter'
import Button from '../../../components/ui/controls/button'
import Search from '../../../components/ui/controls/search'
import Pick from '../../../components/ui/controls/pick'

import Actions from './actions'
import Store from './store'

import appConfig from 'config'

import './library.css'

@decoratorUIActions({
	targetComponent: "Item",
	actions: [{
		type: CONTENT,
		title: "Display item",
		method: (item, props) => {
			if (item.type === "Container") {
				Actions.selectContainer(item);
				Actions.fetchChildContainers(item.id);
				Actions.setSearching(false);
			} else if (props.openAssetDialog) {
				let id = item.id;
				if (item.type === "Asset") {
					id = parseInt(item.reference);
				}
				props.openAssetDialog(id, item.categoryId);
			}
		},
	}]
})
class Library extends Component {

	constructor(props) {
		super(props);

		this.state = {
			...Store.getState(),
			itemTypes: [],
			latestSearchPayload: {},
		};

		this.containerNavigationWrapper = React.createRef();
		this.debouncedFilter = debounce(this.handleFilter, 500);
	}

	componentDidMount() {
		Store.listen(this.onChange);
		if (this.props.defaultIsSearching && !this.state.isSearching) {
			Actions.setSearching(true);
		}
	}

	componentWillUnmount() {
		Actions.setSearching(false);
		Store.unlisten(this.onChange);
	}

	componentDidUpdate(prevProps, prevState) {
		const container = this.state.selectedContainer.id ? this.state.selectedContainer : this.state.container;
		const prevContainer = prevState.selectedContainer.id ? prevState.selectedContainer : prevState.container;
		if (container.id !== prevContainer.id) {
			Actions.fetchChildContainers.defer(container.id);
		}
	}

	onChange = state => this.setState(state);

	onFilter = (type, e, searchText) => {
		if (type === "search") {
			e.persist();
			this.debouncedFilter(type, e, searchText);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter = (type, event) => {
		const { name, value } = event.target;
		Actions[type](value, name);
		
		this.search();

		this.setState({ searchText: type !== "search" ? "" : value });
	}

	search = () => {
		const { searchText, itemTypes, latestSearchPayload } = this.state;
		const payload = {
			type: itemTypes.join(","),
			searchText,
		};
		if (searchText.length >= 2 && Object.entries(payload).some(([key, value]) => value !== latestSearchPayload[key])) {
			this.setState({ latestSearchPayload: payload });
			Actions.fetchSearchItems(payload);
		}
	}

	toggleSearch = () => {
		if (!this.state.isSearching) {
			this.search();
		}
		Actions.setSearching(!this.state.isSearching);
	}

	onItemTypesFilter = (e, value) => {
		const index = this.state.itemTypes.findIndex(t => t === value);
		const newList = [...this.state.itemTypes];
		if (index >= 0) {
			newList.splice(index, 1);
		} else {
			newList.push(value);
		}
		this.setState({ itemTypes: newList }, () => {
			if (this.state.isSearching) {
				this.search();
			}
		});
	}

	// Find the button that expands the container navigation and click it
	onContainerNavigationWrapperClick = () => {
		const wrapper = this.containerNavigationWrapper.current;
		const btn = wrapper.querySelector("button");
		if (btn) {
			btn.click();
		}
	}

	mergeContainer = (container) => {
		const callback = () => {
			Actions.fetchSearchItems(this.state.latestSearchPayload);
		};
		Actions.mergeContainer(container.id, this.props.container.id, callback);
	}

	render() {
		const container = this.state.selectedContainer.id ? this.state.selectedContainer : this.props.container;
		const contentSource = container.contentSource?.length && container.contentSource !== "Undefined" ? container.contentSource : null;
		const contentSourceInfo = container?.id && !container.programGuid?.length
			? (
				<div className="content-source-info">
					{contentSource && (
						<p>
							This folder was created manually in <strong>One Stop Drop</strong>.&nbsp;
							The files in this folder can not be used until you&nbsp;
							<a className="c6-link" onClick={() => this.mergeContainer(container)}>merge this folder</a>&nbsp;
							with the folder that you are currently editing ({this.props.container.displayName}).
						</p>
					)}
					{!contentSource && (
						<p>
							This folder was created manually in <strong>STAR</strong>.&nbsp;
							Please consider&nbsp;
							<a className="c6-link" onClick={() => this.mergeContainer(container)}>merging this folder</a>&nbsp;
							with the folder that you are currently editing ({this.props.container.displayName}).
						</p>
					)}
				</div>
			)
			: null;
		
		return (
			<div className="c6-star-container-metadata-library">
				<Filter>
					{!this.state.isSearching && (
						<React.Fragment>
							{this.props.container?.id && (
								<Button
									type="arrow_back"
									hoverTitle={`Bring back the original folder (${this.state.container.id ? this.state.container.displayName : ""})`}
									onClick={() => Actions.clearSelectedContainer()}
									disabled={!this.state.selectedContainer.id || this.state.selectedContainer.id === this.state.container.id}
								/>
							)}
							<Button
								type="drive_folder_upload"
								hoverTitle="Go to parent folder"
								onClick={() => {
									Actions.selectContainer({ id: container.parentId });
									Actions.setSearching(false);
								}}
								disabled={!container.parentId}
							/>
							<div
								className="container-nav-wrapper"
								ref={this.containerNavigationWrapper}
								onClick={this.onContainerNavigationWrapperClick}
							>
								<ContainerNavigation
									// loadingContainerId={containerIsLoading || childContainersIsLoading}
									container={container}
									childContainers={container.id ? [container, ...this.state.childContainers] : []}
									hasEditAccess={false}
									selected={container}
									onContainerClick={c => {
										if (c.id !== container.id) {
											Actions.selectContainer(c);
											Actions.setSearching(false);
										}
									}}
									initiallyOpen={false}
									alwaysShowEpisodesAndSeasons={false}
									hideAssetCountOnSelectedContainer={true}
								/>
							</div>
							
						</React.Fragment>
					)}
					{this.state.isSearching && (
						<Search
							onChange={this.onFilter.bind(this, "search")}
							placeholder="Search STAR"
							searchText={this.state.searchText}
						/>
					)}
					<Button
						type={this.state.isSearching ? "list" : "search"}
						hoverTitle={this.state.isSearching ? "Switch to folder navigation" : "Search STAR"}
						onClick={this.toggleSearch}
					/>
				</Filter>
				<Filter>
					<Left>
						<Pick
							states={itemTypes}
							currentState={this.state.itemTypes}
							onChange={this.onItemTypesFilter}
							name="library-search-filter"
						/>
					</Left>
				</Filter>

				{!this.state.isSearching && container.id && (
					<React.Fragment>
						{contentSourceInfo}
						<div className="assets">
							{renderAssets({
								container,
								isLoading: this.state.selectedContainerIsLoading || this.state.containerIsLoading,
								assets: container.assets,
								types: this.state.itemTypes,
								disableDrag: !container.programGuid?.length && contentSource?.length, // Disable DnD for OSD folders
							})}
						</div>
					</React.Fragment>
				)}

				{this.state.isSearching && (
					<Items
						searchText={this.state.searchText}
						list={{
							items: this.state.searchItems,
							isLoading: this.state.searchItemsIsLoading,
						}}
						// draggableItems={true}
						groupItems={true}
						hideRecentSearches={true}
						modal={true}
						uploaderInformationText={`Folder: "${container.displayName ?? container.name}"`}
						uploaderIdPrefix={`container-${container.id}`}
					/>
				)}
			</div>
		);
	}
}

export default Library;

function renderAssets({ container, isLoading, assets, types, disableDrag = false }) {
	if (isLoading) {
		return <h3>Loading folder...</h3>;
	}

	const filteredAssets = filterAssets(assets, types);

	if (!filteredAssets || !filteredAssets.length) {
		return <span>This folder has no assets that match your filter. You can try searching above for other folders to pick images from.</span>;
	}

	return (
		<Items
			list={{
				items: filteredAssets,
				isLoading: false,
			}}
			groupItems={true}
			hideRecentSearches={true}
			modal={true}
			disableDrag={disableDrag}
			uploaderInformationText={`Folder: "${container.displayName ?? container.name}"`}
			uploaderIdPrefix={`container-${container.id}`}
		/>
	);
}


function filterAssets(assets = [], types) {
	return assets.filter(a =>
		a.category === "Undefined"
		&& (
			a.type !== "Video"
			|| appConfig.features.metadataStarContainerDisplayVideoFormats?.includes(a.format?.toLowerCase())
			|| appConfig.features.starDAMFeatures
		)
		&& (
			!types.length // No type selected => show all
			|| types.some(t => t.includes(a.type.toLowerCase())) && a.assetCategory !== "ScreenGrab" // Don't include ScreenGrabs just because "Images" is selected
			|| types.includes("screengrab") && a.assetCategory === "ScreenGrab" // Only include ScreenGrabs if "ScreenGrabs" is selected
		)
	);
}

const itemTypes = [
	{ key: "image", text: "Images", description: "" },
	{ key: "screengrab", text: "Screen grabs", description: "" },
	{ key: "document", text: "Documents", description: "" },
	{ key: "trailer,clip", text: "Trailers & Clips", description: "" },
	{ key: "video", text: "Videos & Subs", description: "" },
];