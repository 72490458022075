import React, { useState } from 'react'
import { Link } from 'react-router'
import moment from 'moment-timezone'

import Widget from './widget'
import PressLink from '../shared/link'
import Translations from '../shared/translations'
import { MTV_DATE_FORMAT, getStringWithoutHTML } from '../shared/utils'

import appConfig from 'config'

import './pressReleases.css'

const itemsPerBatch = 20;

const PressReleases = ({ name, compact, widgetData = {}, heading }) => {
	const [maxVisible, setMaxVisible] = useState(itemsPerBatch);
	const { items: pressReleases = [] } = widgetData;

	const isAllPressReleases = name === "all-press-releases";
	const visibleItems = isAllPressReleases ? pressReleases.slice(0, maxVisible) : pressReleases;

	const morePressReleasesLinkProps = {};
	if (appConfig.features.pressMorePressReleasesLink) {
		morePressReleasesLinkProps.to = appConfig.features.pressMorePressReleasesLink;
		morePressReleasesLinkProps.target = "_blank";
	} else {
		morePressReleasesLinkProps.to = `/${Translations.getTranslation("url_pressreleases")}`;
	}

	return (
		<div className={`c6-press-pressreleases ${compact ? "compact" : ""} ${!heading || !heading.length ? "no-heading" : ""}`}>
			{visibleItems.map(pr => <PressReleaseItem key={pr.id} compact={compact} {...pr} />)}
			{!isAllPressReleases && (
				<PressLink {...morePressReleasesLinkProps}>{Translations.getTranslation("morePressReleases")} &#8594;</PressLink>
			)}
			{isAllPressReleases && maxVisible < pressReleases.length && (
				<PressLink onClick={() => setMaxVisible(maxVisible + itemsPerBatch)}>{Translations.getTranslation("loadMore")}...</PressLink>
			)}
		</div>
	);
}

export default Widget(PressReleases);

const PressReleaseItem = ({ heading, description, start: date, reference, compact, pressRelease, link }) => {
	const brand = pressRelease && pressRelease.brand;
	const dateFormat = appConfig.features.pressDateFormatPressReleases ?? MTV_DATE_FORMAT;
	const content = (
		<>
			<h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
			<div className="info">
				<p className="metadata">
					{!compact && brand && <span className={`channel-logo channel-${brand.toLowerCase().replace(/\s/g, "")}`}></span>}
					<span className="icon-insert_invitation">{moment(date).tz(appConfig.features.pressTimezone).format(dateFormat)}</span>
				</p>
				{!compact && description && <p>{getStringWithoutHTML(description)}</p>}
			</div>
		</>
	);

	const pressreleaseUrl = link?.url ?? pressRelease?.url;
	if (pressreleaseUrl) {
		return (
			<a
				className={`c6-press-pressrelease-item ${compact ? "compact" : ""}`}
				href={pressreleaseUrl}
				target="_blank"
			>
				{content}
			</a>
		);
	}

	return (
		<Link
			className={`c6-press-pressrelease-item ${compact ? "compact" : ""}`}
			to={`/${Translations.getTranslation("url_pressrelease")}/${reference}`}
		>
			{content}
		</Link>
	);
}
