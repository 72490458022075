import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ContentItem, { Content } from '../../../components/list/contentItem'
import DraggableContentItem from '../../../components/list/contentItemDraggable'
import Preview from '../../../components/assets/preview'
// import Label from '../../../components/ui/label'
import { getFileIcon } from '../../../components/assets/asset'

import { isThumbNailType, isAssetEditorType } from './utils'

export default class SearchContent extends PureComponent {

	static propTypes = {
		item: PropTypes.object,
		selected: PropTypes.bool,
		draggable: PropTypes.bool,
		selectedItems: PropTypes.array,
	}

	render() {
		const { item, selected, draggable = false, selectedItems = [], style = {} } = this.props;
		const { approved, asset = {}, type, subType, assetGuid } = item;

		swapDimensionsIfRotated(item.asset);
		swapDimensionsIfRotated(item.assetData);
		const size = getSize(item);
		const metadata = getMetadata(item);
		// const approvalLabel = getApprovalLabel(type, approved);

		const extension = getExtension(item);
		const fileIcon = ["Asset", "Document", "Clip", "Trailer", "Video"].includes(type) && subType !== "Image" ? getFileIcon(item) : "";
		const contentSource = getContentSource(item, asset);
		const previewClassName = `preview-icon ${fileIcon}`;

		const itemContent = (
			<React.Fragment>
				<div className={previewClassName} data-contentsource={contentSource} title={contentSource}>
					<Preview
						imageGuid={asset.guid || assetGuid}
						size={size}
						extension={extension}
					/>
				</div>
				<Content>{metadata}</Content>
				{/* {approvalLabel} */}
			</React.Fragment>
		);

		if (draggable) {
			return (
				<DraggableContentItem
					data={item}
					selected={selected}
					selectedItems={selectedItems}
					handleType="item"
					style={style}
				>
					{itemContent}
				</DraggableContentItem>
			);
		}
		
		return (
			<ContentItem
				data={item}
				selected={selected}
				style={style}
			>
				{itemContent}
			</ContentItem>
		);
  	}
}

// HELPERS
const PREVIEW_HEIGHT = 200;

function getSize({ type, subType, asset, assetData }) {
	let { width = "max-content", height } = asset || assetData || {}; // asset is from SearchItems, assetData from Containers and other collections of assets.

	// In case the asset was just uploaded we'll have to let the browser calculate the width dynamically
	// but the default is to calculate the width with data from the API for quicker rendering
	if(width !== "max-content") {
		width = Math.round(PREVIEW_HEIGHT*(width/height));
	}

	return isThumbNailType(type, subType)
		? {
			name: "starAsset",
			width,
			height: PREVIEW_HEIGHT,
		}
		: "small";
}

function getMetadata({
	type,
	subType,
	asset,
	assetData,
	displayName,
	description,
	assetCount = description ? Number(description.split(" ")[0]) : null,
	containerCount,
}) {
	const { width, height } = asset || assetData || {}; // asset is from SearchItems, assetData from Containers and other collections of assets.
	
	// Assets
	if (isAssetEditorType(type, subType)) {
		if (displayName.indexOf("FILE_") === 0) {
			const separator = dimensions && description?.length > 0 ? " - " : null;
			return dimensions || description?.length > 0 ? <h1>{dimensions}{separator}{description}</h1> : null;
		}

		const dimensions = (width || height) ? `${width}×${height}` : null;
		const separator = dimensions && displayName ? " - " : null;
		return (
			<>
				<h1>{dimensions}{separator}{displayName}</h1>
				<p>{description}</p>
			</>
		);
	}

	// Containers
	let assetsDescription = null;
	if (assetCount > 0) {
		assetsDescription = (
			<span title="Asset count does not include assets in subfolders">
				{assetCount} {assetCount > 1 ? "assets" : "asset"}
			</span>
		);
	}

	let subfoldersDescription = null;
	if (containerCount > 0) {
		subfoldersDescription = <span>{containerCount} {containerCount > 1 ? "subfolders" : "subfolder"}</span>
	}

	const containerDescription = <>{assetsDescription}{assetsDescription && subfoldersDescription ? ", " : ""}{subfoldersDescription}</>;

	return (
		<>
			<h1>{displayName}</h1>
			<p>{containerDescription}</p>
		</>
	);
}

function swapDimensionsIfRotated(data) {
	if (data && !data.dimensionsRotated && data.rotation && data.rotation % 180 !== 0) {
		const tmp = data.width;
		data.width = data.height;
		data.height = tmp;
		data.dimensionsRotated = true;
	}
}

function getExtension(item) {
	const assetData = item.asset || item.assetData;
	return item.type === "Document" || assetData && assetData.type.toLowerCase() === "png" ? "png" : "jpg";
}

function getContentSource(item, asset) {
	if (!!item.contentSource && item.contentSource !== "Undefined") return item.contentSource;
	if (!!asset.contentSource && asset.contentSource !== "Undefined") return asset.contentSource;
	return null;
}

// function getApprovalLabel(type, approved) {
// 	if(type !== "Container") {
// 		return null;
// 	}

// 	let status = null, text = "";
// 	switch(approved) {
// 		case true:
// 			status = "approved";
// 			text = "Approved";
// 			break;
// 		case false:
// 			status = "unapproved";
// 			text = "Not approved";
// 			break;
// 		default:
// 			return null;
// 	}

// 	return (
// 		<Content size="min">
// 			<Label status={status}>{text}</Label>
// 		</Content>
// 	);
// }