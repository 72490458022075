export default {
	// Header & search
	search: "Hae",
	searching: "Etsii",
	noItemsFound: "Kohteita ei löytynyt",
	
	// Footer
	faq: "UKK",
	channels: "Kanavat",
	graphicProfile: "Logot ja värit",
	about: "mtv-katsomossa",
	customerService: "mtv-katsomon asiakaspalvelu",
	news: "MTV Uutiset",
	arena: "SuomiAreena",
	tastes: "Makuja",
	feedback: "MTV palaute",
	advertisers: "MTV mainostajalle",
	company: "MTV yrityksenä",
	cmore: "C More",

	// Login/register/forgot password
	logIn: "Kirjaudu sisään",
	logOut: "Kirjaudu ulos",
	forgotPassword: "Unohtuiko salasanasi?",
	register: "Luo tunnus",
	email: "Sähköposti",
	password: "Salasana",
	incorrectCredentials: "Sähköposti tai salasana on virheellinen.",
	couldNotFindLogin: "Käyttäjää {email} ei löydy.",
	name: "Nimi",
	phoneNumber: "Puhelinnumero",
	media: "Media",
	purposeForRegistering: "Rekisteröitymisen tarkoitus",
	submit: "Lähetä",
	requestSubmitted: "Pyyntö lähetetty",
	requestSubmittedInfo: "Pyyntösi on otettu vastaan. Otamme sen käsittelyyn mahdollisimman pian. Saat vahvistuksen sähköpostiisi, kun pyyntösi on joko hyväksytty tai hylätty.",
	accountAlreadyExists: "Sähköpostiosoite ({email}) on jo rekisteröity. Voit kokeilla salasanasi vaihtamista tai mikäli ongelma ei ratkea, olla yhteydessä osoitteeseen mtvviestinta@mtv.fi.",
	requestNotHandled: "Olet pyytänyt pääsyä MTV Oy:n pressipalveluun sähköpostiosoitteelle {email}. Emme ole vielä käsitelleet pyyntöäsi. Tarvittaessa voit ottaa yhteyttä meihin: mtvviestinta@mtv.fi.",
	requiredFields: "pakolliset kentät",
	fillBothFields: "Täytäthän molemmat kentät.",
	typeEmail: "Syötä käyttäjätunnuksesi / sähköpostiosoitteesi.",
	typePassword: "Syötä salasanasi.",
	passwordsDontMatch: "Salasana ei täsmää.",
	typePasswordTwice: "Kirjoita salasana kahdesti.",
	typeNewPassword: "Anna uusi salasana.",
	retypePassword: "Kirjoita salasanasi uudestaan.",
	linkExpires: "Linkki vanhenee",
	linkExpired: "Linkki on vanhentunut!",
	newPassword: "Uusi salasana",
	confirmNewPassword: "Vahvista uusi salasana",
	save: "Tallenna",
	passwordResetLinkInfo: "Salasanan palauttamiseen tarkoitettu linkki on voimassa rajoitetun ajan. Tämä on jo vanhentunut. Pyydä uusi linkki painamalla alapuolella olevasta painikkeesta.",
	itWillBeSentTo: "Se lähetetään",
	requestNewLink: "Pyydä uusi linkki",
	passwordResetSuccess: "Salasanasi on päivitetty. Pääset kirjautumaan sisään alta.",
	forgotPasswordSuccess: "Sähköposti ja toimintaohjeet onlähetetty osoitteeseen {email}. Jos et saa sähköpostia 2 minuutin kuluessa, tarkistathan roskapostikansiosi.",
	registrationConditionsAgreement: "Sitoudun noudattamaan esikatseluiden ja kuvamateriaalin",
	registrationConditionsAgreementFAQLinkText: "käyttöehtoja",
	registrationPrivacyPolicyAgreement: "Olen tutustunut",
	registrationPrivacyPolicyAgreementLinkText: "tietosuojaselosteeseen",
	registrationCaptchaPolicyText: "Tämä sivusto on suojattu reCAPTCHA:lla ja siihen sovelletaan Googlen <a target=\"_blank\" class=\"c6-link\" href=\"https://policies.google.com/privacy?hl=fi\">Tietosuojakäytäntöä</a> ja <a target=\"_blank\" class=\"c6-link\" href=\"https://policies.google.com/terms?hl=fi\">Palveluehtoja</a>.",
	
	// Schedules
	today: "Tänään",
	all: "Kaikki",
	noBroadcasts: "Ei lähetystä",
	programTitleMissing: "Puuttuva otsikko",
	mtvDescription: "Näytä vain MTV:n kanavat",
	cmoreDescription: "Näytä vain C Moren kanavat",
	todayTitle: "Näytä tämä päivä [CTRL-T]",
	previousDayTitle: "Näytä edellinen päivä [LEFT ARROW KEY]",
	nextDayTitle: "Näytä seuraava päivä [RIGHT ARROW KEY]",
	subscribeSuccess: "Kiitos tilauksestasi. Sähköposti ja toimintaohjeetlähetetään osoitteeseen",
	subscribeError: "Tilausta ei lähetetty. Yritä uudelleen.",
	loadSubscriptionsError: "Tilauksia ei voitu ladata. Yritä uudelleen.",
	subscriptionsUpdated: "Tilauksesi päivitettiin onnistuneesti.",
	subscriptionsCancelled: "Kaikki tilaukset onnistuneesti peruttu.",
	subscriptionsUpdateError: "Tilausta ei voitu päivittää. Yritä uudelleen.",
	cancelSubscriptions: "Peruuta tilaukseni",
	subscribeWidgetMessage: "Lisää sähköpostiosoitteesi, jos haluat ilmoituksen kaikista ohjelmamuutoksistamme.",

	// Program
	season: "Kausi",
	episode: "Jakso",
	episodes: "jaksoa",
	availableOn: "Katsottavissa",
	// premieresOn: "Ensi-ilta",
	nextBroadcast: "Seuraava lähetys",
	upcomingBroadcasts: "Tulevat lähetykset",
	noPlannedBroadcasts: "Seuraava lähetyskerta ei vielä tiedossa.",
	noEpisodesAvailable: "Jaksoja ei ole saatavilla tällä hetkellä.",
	at: "klo",
	tomorrow: "Huomenna",
	noEpisodeSynopsis: "Ei jakson tiivistelmää",
	episodeImages: "Ladattavat materiaalit - Jaksokuvia",
	seasonImages: "Ladattavat materiaalit - Kausikuvia",
	seriesImages: "Ladattavat materiaalit - Ohjelmakuvia",
	downloadableMaterials: "Ladattavat materiaalit",
	downloadNotAllowed: "Kirjaudu ladataksesi hi-res-kuvan.",
	pressPreviewsNotAllowed: "Kirjaudu sivustolle lehdistön ennakkokatselua varten.",
	noPressImages: "Sivustolla ei ole lehdistökuvia saatavilla tällä hetkellä. Olethan meihin yhteydessä, mikäli kaipailet kuvia tästä ohjelmasta, niin voimme selvittää niiden saatavuutta.",
	// programDescriptionMissing: "Ohjelman kuvaus puuttuu.",
	programDescriptionMissing: " ",
	showMore: "Näytä lisää",
	showFewer: "Näytä vähemmän",
	minutes: "Minuutit",
	actor: "Pääosissa",
	director: "Ohjaus",
	production: "Tuotanto",
	script: "Käsikirjoitus",
	previewNotAllowed: "Jotta voit esikatsella ja ladata kuvia, sinun on kirjauduttava sisään.",
	programNotAvailable: "Ohjelma ei ole saatavilla.",
	availability: "Saatavuus",
	copyProgramInfo: "Kopioi",
	copyProgramInfoDone: "Ohjelman tiedot kopioitu leikepöydälle.",
	closePreview: "Sulje",
	loading: "Ladataan",

	// Press releases
	images: "Kuvia",
	morePressReleases: "Lisää tiedotteita",
	loadMore: "Lataa lisää",
	bulletinboardItemCategory_invitation: "Kutsu",
	bulletinboardItemCategory_programchange: "Muutos",
	bulletinboardItemCategory_othernews: "",

	// URLs
	url_pressreleases: "tiedotteet",
	url_pressrelease: "tiedoti",
	url_bulletin: "bulletin",
	url_program: "ohjelma",
	url_series: "sarja",
	url_season: "kausi",
	url_episode: "jakso",
	url_schedules: "ohjelmatiedot",
	url_updateschedulessubscription: "paivittaa-ohjelmatiedot-tilaus",
	url_login: "kirjaudu",
	url_register: "rekisteroidy",
	url_forgotpassword: "unohtuiko-salasana",
	url_resetpassword: "nollaa-salasana",
	url_faq: "ukk",
	url_search: "haku",
}