export default {
	"Afghanistan": { fi: "Afganistan" },
	"Albania": { fi: "Albania" },
	"Algeria": { fi: "Algeria" },
	"Andorra": { fi: "Andorra" },
	"Angola": { fi: "Angola" },
	"Antigua and Barbuda": { fi: "Antigua ja Barbuda" },
	"Argentina": { fi: "Argentiina" },
	"Armenia": { fi: "Armenia" },
	"Australia": { fi: "Australia" },
	"Austria": { fi: "Itävalta" },
	"Azerbaijan": { fi: "Azerbaidžan" },
	"Bahamas": { fi: "Bahama" },
	"Bahrain": { fi: "Bahrain" },
	"Bangladesh": { fi: "Bangladesh" },
	"Barbados": { fi: "Barbados" },
	"Belarus": { fi: "Valko-Venäjä" },
	"Belgium": { fi: "Belgia" },
	"Belize": { fi: "Belize" },
	"Benin": { fi: "Benin" },
	"Bhutan": { fi: "Bhutan" },
	"Bolivia": { fi: "Bolivia" },
	"Bosnia and Herzegovina": { fi: "Bosnia ja Hertsegovina" },
	"Botswana": { fi: "Botswana" },
	"Brazil": { fi: "Brasilia" },
	"Brunei": { fi: "Brunei" },
	"Bulgaria": { fi: "Bulgaria" },
	"Burkina Faso": { fi: "Burkina Faso" },
	"Burundi": { fi: "Burundi" },
	"Cambodia": { fi: "Kambodža" },
	"Cameroon": { fi: "Kamerun" },
	"Canada": { fi: "Kanada" },
	"Cape Verde": { fi: "Kap Verde" },
	"Central African Republic": { fi: "Keski-Afrikan tasavalta" },
	"Chad": { fi: "Tšad" },
	"Chile": { fi: "Chile" },
	"China": { fi: "Kiina" },
	"Colombia": { fi: "Kolumbia" },
	"Comoros": { fi: "Komorit" },
	"Costa Rica": { fi: "Costa Rica" },
	"Côte d’Ivoire": { fi: "Norsunluurannikko" },
	"Croatia": { fi: "Kroatia" },
	"Cuba": { fi: "Kuuba" },
	"Cyprus": { fi: "Kypros" },
	"Czech Republic": { fi: "Tšekki" },
	"Democratic Republic of the Congo": { fi: "Kongon demokraattinen tasavalta" },
	"Denmark": { fi: "Tanska" },
	"Djibouti": { fi: "Djibouti" },
	"Dominica": { fi: "Dominica" },
	"Dominican Republic": { fi: "Dominikaaninen tasavalta" },
	"East Timor": { fi: "Itä-Timor" },
	"Ecuador": { fi: "Ecuador" },
	"Egypt": { fi: "Egypti" },
	"El Salvador": { fi: "El Salvador" },
	"Equatorial Guinea": { fi: "Päiväntasaajan Guinea" },
	"Eritrea": { fi: "Eritrea" },
	"Estonia": { fi: "Viro" },
	"Ethiopia": { fi: "Etiopia" },
	"Fiji": { fi: "Fidži" },
	"Finland": { fi: "Suomi" },
	"France": { fi: "Ranska" },
	"Gabon": { fi: "Gabon" },
	"Gambia": { fi: "Gambia" },
	"Georgia": { fi: "Georgia" },
	"Germany": { fi: "Saksa" },
	"Ghana": { fi: "Ghana" },
	"Greece": { fi: "Kreikka" },
	"Grenada": { fi: "Grenada" },
	"Guatemala": { fi: "Guatemala" },
	"Guinea": { fi: "Guinea" },
	"Guinea-Bissau": { fi: "Guinea-Bissau" },
	"Guyana": { fi: "Guyana" },
	"Haiti": { fi: "Haiti" },
	"Honduras": { fi: "Honduras" },
	"Hungary": { fi: "Unkari" },
	"Iceland": { fi: "Islanti" },
	"India": { fi: "Intia" },
	"Indonesia": { fi: "Indonesia" },
	"Iran": { fi: "Iran" },
	"Iraq": { fi: "Irak" },
	"Ireland": { fi: "Irlanti" },
	"Israel": { fi: "Israel" },
	"Italy": { fi: "Italia" },
	"Jamaica": { fi: "Jamaika" },
	"Japan": { fi: "Japani" },
	"Jordan": { fi: "Jordan" },
	"Kazakhstan": { fi: "Kazakstan" },
	"Kenya": { fi: "Kenia" },
	"Kiribati": { fi: "Kiribati" },
	"Kuwait": { fi: "Kuwait" },
	"Kyrgyzstan": { fi: "Kirgisia" },
	"Laos": { fi: "Laos" },
	"Latvia": { fi: "Latvia" },
	"Lebanon": { fi: "Libanon" },
	"Lesotho": { fi: "Lesotho" },
	"Liberia": { fi: "Liberia" },
	"Libya": { fi: "Libya" },
	"Liechtenstein": { fi: "Liechtenstein" },
	"Lithuania": { fi: "Liettua" },
	"Luxembourg": { fi: "Luxemburg" },
	"Madagascar": { fi: "Madagaskar" },
	"Malawi": { fi: "Malawi" },
	"Malaysia": { fi: "Malesia" },
	"Maldives": { fi: "Malediivit" },
	"Mali": { fi: "Mali" },
	"Malta": { fi: "Malta" },
	"Marshall Islands": { fi: "Marshallinsaaret" },
	"Mauritania": { fi: "Mauritania" },
	"Mauritius": { fi: "Mauritius" },
	"Mexico": { fi: "Meksiko" },
	"Micronesia": { fi: "Mikronesia" },
	"Moldova": { fi: "Moldova" },
	"Monaco": { fi: "Monaco" },
	"Mongolia": { fi: "Mongolia" },
	"Montenegro": { fi: "Montenegro" },
	"Morocco": { fi: "Marokko" },
	"Mozambique": { fi: "Mosambik" },
	"Myanmar": { fi: "Myanmar" },
	"Namibia": { fi: "Namibia" },
	"Nauru": { fi: "Nauru" },
	"Nepal": { fi: "Nepal" },
	"Netherlands": { fi: "Alankomaat" },
	"New Zealand": { fi: "Uusi-Seelanti" },
	"Nicaragua": { fi: "Nicaragua" },
	"Niger": { fi: "Niger" },
	"Nigeria": { fi: "Nigeria" },
	"North Korea": { fi: "Pohjois-Korea" },
	"Norway": { fi: "Norja" },
	"Oman": { fi: "Oman" },
	"Pakistan": { fi: "Pakistan" },
	"Palau": { fi: "Palau" },
	"Panama": { fi: "Panama" },
	"Papua New Guinea": { fi: "Papua-Uusi-Guinea" },
	"Paraguay": { fi: "Paraguay" },
	"Peru": { fi: "Peru" },
	"Philippines": { fi: "Filippiinit" },
	"Poland": { fi: "Puola" },
	"Portugal": { fi: "Portugali" },
	"Qatar": { fi: "Qatar" },
	"Republic of the Congo": { fi: "Kongon tasavalta" },
	"Republic of Macedonia": { fi: "Makedonia" },
	"Romania": { fi: "Romania" },
	"Russia": { fi: "Venäjä" },
	"Rwanda": { fi: "Ruanda" },
	"Saint Kitts and Nevis": { fi: "Saint Kitts ja Nevis" },
	"Saint Lucia": { fi: "Saint Lucia" },
	"Saint Vincent and the Grenadines": { fi: "Saint Vincent ja Grenadiinit" },
	"Samoa": { fi: "Samoa" },
	"San Marino": { fi: "San Marino" },
	"Sao Tome and Principe": { fi: "São Tomé ja Príncipe" },
	"Saudi Arabia": { fi: "Saudi-Arabia" },
	"Senegal": { fi: "Senegal" },
	"Serbia": { fi: "Serbia" },
	"Seychelles": { fi: "Seychellit" },
	"Sierra Leone": { fi: "Sierra Leone" },
	"Singapore": { fi: "Singapore" },
	"Slovakia": { fi: "Slovakia" },
	"Slovenia": { fi: "Slovenia" },
	"Solomon Islands": { fi: "Salomonsaaret" },
	"Somalia": { fi: "Somalia" },
	"South Africa": { fi: "Etelä-Afrikka" },
	"South Korea": { fi: "Etelä-Korea" },
	"South Sudan": { fi: "Etelä-Sudan" },
	"Spain": { fi: "Espanja" },
	"Sri Lanka": { fi: "Sri Lanka" },
	"Sudan": { fi: "Sudan" },
	"Suriname": { fi: "Suriname" },
	"Swaziland": { fi: "Swazimaa" },
	"Sweden": { fi: "Ruotsi" },
	"Switzerland": { fi: "Sveitsi" },
	"Syria": { fi: "Syyria" },
	"Tajikistan": { fi: "Tadžikistan" },
	"Tanzania": { fi: "Tansania" },
	"Thailand": { fi: "Thaimaa" },
	"Togo": { fi: "Togo" },
	"Tonga": { fi: "Tonga" },
	"Trinidad and Tobago": { fi: "Trinidad ja Tobago" },
	"Tunisia": { fi: "Tunisia" },
	"Turkey": { fi: "Turkki" },
	"Turkmenistan": { fi: "Turkmenistan" },
	"Tuvalu": { fi: "Tuvalu" },
	"Uganda": { fi: "Uganda" },
	"Ukraine": { fi: "Ukraina" },
	"United Arab Emirates": { fi: "Yhdistyneet arabiemiirikunnat" },
	"United Kingdom": { fi: "Yhdistynyt kuningaskunta" },
	"United States": { fi: "Yhdysvallat" },
	"United States of America": { fi: "Yhdysvallat" },
	"Uruguay": { fi: "Uruguay" },
	"Uzbekistan": { fi: "Uzbekistan" },
	"Vanuatu": { fi: "Vanuatu" },
	"Venezuela": { fi: "Venezuela" },
	"Vietnam": { fi: "Vietnam" },
	"Yemen": { fi: "Jemen" },
	"Zambia": { fi: "Sambia" },
	"Zimbabwe": { fi: "Zimbabwe" },
}