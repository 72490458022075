import React, { useCallback, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import MuiButton from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import Button from '../ui/controls/button'

import { displayAlert } from '../../core/services/alert'
import { getUser } from '../../core/services/auth'
import { sendFeedback } from '../../apis/comet'

import appConfig from 'config'

function Feedback(props) {
    const {
        buttonText = "Leave feedback",
        buttonElement = null,
        description = "We would love to hear your feedback, please let us know what you think.",
        type = "General feedback",
        submittedMessage = "Feedback submitted, thank you!",
        data,
    } = props;

    const [dialogOpen, setDialogOpen] = useState(false);
    const [text, setText] = useState("");
    const [reaction, setReaction] = useState("neutral");
    const [isLoading, setIsLoading] = useState(false);

    const submit = useCallback(
        async (e) => {
            e.preventDefault();
            setIsLoading(true);

            try {
                await sendFeedback({
                    username: getUser().username,
                    url: window.location.href,
                    type,
                    reaction,
                    text: text?.length ? text : "",
                    data: JSON.stringify(data),
                });
    
                setText("");
                setReaction(null);
                
                setDialogOpen(false);
                displayAlert("success", submittedMessage);
            } catch (e) {
                console.error(e);
                displayAlert("error", "An error occurred while submitting feedback, please try again later.");
            } finally {
                setIsLoading(false);
            }
            
        },
        [data, text, reaction, type]
    );

    if (!appConfig.features.feedbackEnabled) {
        return null;
    }

    return (
        <React.Fragment>
            {buttonElement && React.cloneElement(buttonElement, { onClick: () => setDialogOpen(true) })}
            {!buttonElement && (
                <Button
                    title={buttonText}
                    type="chat"
                    onClick={() => setDialogOpen(true)}
                />
            )}

            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                className="c6-modal feedback-dialog"
                maxWidth={"sm"}
                fullWidth={true}
                onClick={(e) => e.stopPropagation()}
                classes={{
                    container: "c6-modal-content",
                    paper: "c6-modal-body",
                }}
            >
                <DialogContent>
                    <h2>Feedback</h2>
                    <p>{description}</p>
                    <div>
                        <MuiButton
                            variant={reaction === "positive" ? "contained" : "outlined"}
                            style={{ fontSize: "2em", height: "2em", margin: "0 5px 10px 0" }}
                            onClick={() => setReaction(oldReaction => oldReaction === "positive" ? "neutral" : "positive")}
                            color="success"
                            disabled={isLoading}
                        >
                            👍
                        </MuiButton>
                        <MuiButton
                            variant={reaction === "negative" ? "contained" : "outlined"}
                            style={{ fontSize: "2em", height: "2em", margin: "0 5px 10px 0" }}
                            onClick={() => setReaction(oldReaction => oldReaction === "negative" ? "neutral" : "negative")}
                            color="error"
                            disabled={isLoading}
                        >
                            👎
                        </MuiButton>
                    </div>
                    <TextField
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        placeholder="Your thoughts here... (optional)"
                        multiline={true}
                        style={{ width: "100%" }}
                        disabled={isLoading}
                        inputProps={{
                            maxLength: 5000,
                        }}
                        helperText="Max 5000 characters"
                    />
                </DialogContent>
                <DialogActions sx={{ padding: "0 24px 20px 24px"}}>
                    <MuiButton
                        onClick={() => setDialogOpen(false)}
                    >
                        Cancel
                    </MuiButton>
                    <MuiButton
                        variant="contained"
                        color="primary"
                        onClick={submit}
                        disabled={isLoading || (!type && !text?.length)}
                    >
                        Submit
                    </MuiButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default Feedback;
