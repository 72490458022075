import SSOInterface from './SSOInterface'
import SAML from './SAML'

// @ts-ignore
import appConfig from 'config'

let SSO: SSOInterface;

export async function initialize(): Promise<void> {
    if (!appConfig.features.ssoEnabled) {
        return;
    }

    if (appConfig.features.msalClientId) {
        SSO = new SAML();
    }

    // if (appConfig.features.googleClientIdOrWhatever) {
    //     SSO = new Google();
    // }

    await SSO.initialize();
}

export async function login(): Promise<any> {
    return await SSO.login();
}

export async function logout(): Promise<void> {
    await SSO.logout();
}