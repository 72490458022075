import React from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'
import 'moment/locale/fi'
import 'moment/locale/sv'

import AlertContainer from '../../components/ui/alertContainer'
// import InformationBox from './widgets/informationBox'

import UserStore from '../../core/authentication/store'

import * as Auth from '../../core/services/auth'
import { addAdobeAnalytics, addGoogleTagManager, addOneTrustCookieConsent, trackAdobeAnalyticsPageView } from '../../core/services/tracking'
import Translations from './shared/translations'
import { onBMPReady } from '../../components/bitmovin-player/utils'

import '../../components/comet/colors.css'
import '../../components/comet/comet.css'
import '../../components/comet/module.css'
import '../../client/icomoon.css'

import appConfig from 'config'

const cookieConsentKey = "cookieConsent";

@withRouter
export default class PressCore extends React.Component {
	pathname = "";
	userDataExpiryTimerID = null;

	constructor(props) {
		super(props);

		moment.locale(appConfig.features.pressLocale);

		this.state = {
			...UserStore.getState(),
			cookieConsent: localStorage.getItem(cookieConsentKey),
		};
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
		this.startIntervalCheck();

		addGoogleTagManager();
		addAdobeAnalytics();
		addOneTrustCookieConsent();

		// Set document language so that for example Google Translate can translate the page
		document.documentElement.lang = appConfig.features.pressLocale;
		// Set font
		if (appConfig.features.defaultFontFamily?.length) {
			document.documentElement.style.setProperty("--default-font", appConfig.features.defaultFontFamily);
		}
		// Set favicon
		if (appConfig.features.faviconPath?.length) {
			Array.from(document.querySelectorAll("link[rel*='icon']")).forEach(el => el.href = appConfig.features.faviconPath);
		}
		if (appConfig.features.pressClassName?.length) {
			document.documentElement.classList.add(appConfig.features.pressClassName);
		}

		this.pathname = this.props.location.pathname;
		if (!isPageViewTrackedSeparatelyForPage(this.pathname)) {
			trackAdobeAnalyticsPageView();
		}

		// Load Bitmovin player in advance
		if (appConfig.features.bitmovinPlayerKey) {
			onBMPReady(() => {});
		}
	}

	componentDidUpdate() {
		if (this.props.location.pathname !== this.pathname) {
			this.pathname = this.props.location.pathname;
			if (!isPageViewTrackedSeparatelyForPage(this.pathname)) {
				trackAdobeAnalyticsPageView();
			}
		}
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);
		clearInterval(this.userDataExpiryTimerID);
	}

	onChange = (state) => {
		this.setState(state);
	}

	startIntervalCheck = () => {
		const refreshIntervalSeconds = 60;
		this.userDataExpiryTimerID = setInterval(() => {
			if (this.state.loggedIn) {
				const prematureExpirySeconds = refreshIntervalSeconds; // Expire one refresh interval before the real expiry, so we don't risk overshooting the next check
				if (Auth.isExpired(this.state.user, prematureExpirySeconds)) {
					const stayOnPage = true;
					this.handleLogOut(stayOnPage);
				}
			}
		}, refreshIntervalSeconds * 1000);
	}

	handleLogOut = (stayOnPage) => {
		Auth.logoutUser(this.state.user, stayOnPage);
	}

	updateCookieConsent = () => {
		localStorage.setItem(cookieConsentKey, true);
		this.setState({ cookieConsent: true });
	}

	renderCookieConsent = () => {
		return null;

		// if (this.state.cookieConsent || appConfig.features.pressOneTrustCookiesConsentId) {
		// 	return null;
		// }

		// return (
		// 	<div className="c6-press-cookie-consent">
		// 		<InformationBox
		// 			name="cookie-consent"
		// 			contentIfWidgetLoadFailed={null}
		// 		/>
		// 		<button onClick={this.updateCookieConsent}>OK</button>
		// 	</div>
		// );
	}

	render() {
		return (
			<div className="c6-core">
				<AlertContainer />
				<div>
					<div className="c6-module">
						{this.props.children}
					</div>
				</div>
				{this.renderCookieConsent()}
			</div>
		);
	}
}

function isPageViewTrackedSeparatelyForPage(pathname) {
	return pathname.includes(`${Translations.getTranslation("url_series")}/`)
		|| pathname.includes(`${Translations.getTranslation("url_program")}/`);
}