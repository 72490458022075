export class SinguSubtitleOverlay {
    constructor(createdCallback) {
        // Create overlay element
        this.overlay = document.createElement("div")
        this.overlay.className = "singu-ui-subtitle-overlay"
        for (let i = 0; i < 10; i++) {
            const line = document.createElement("div")
            line.setAttribute("data-line", i + 1)
            this.overlay.appendChild(line)
        }

        // Update references required by bitmovin
        this.element = { document, elements: [this.overlay] }

        // Initialize locals
        this.subtitlesPresentByline = {}
        this.warnedAboutOverlappingLines = false
        this.fontSize = 30

        // Callback if present
        if (typeof createdCallback === "function") createdCallback(this)
    }

    /**
     * Hook this subtitle overlay to a Bitmovin player
     * TODO: The player property might exist somewhere in memory we can access, but we'll have to find out
     * @param {} player 
     */
    hook(player) {
        const playerContainer = player.getContainer()
        const eventsToHook = ["cueenter", "cueexit", "audiochanged", "subtitleenabled", "subtitledisabled", "seek", "timeshift", "playerresized"]
        for (const eventName of eventsToHook) player.on(eventName, (event) => this.handle(eventName, event))
        playerContainer.appendChild(this.overlay)
    }

    normalizeLine(line) {
        // Note: default to always using line 8
        return 8

        // Make sure line is a string
        /*line = line.toString()

        let normalizedLine = 0
        // Normalize percentages
        if (line.endsWith("%")) normalizedLine = line.length === 2 ? 0 : (line.length === 4 ? 9 : Number(line.split("")[0]) - 1)

        // Convert negative line numbers to positive line numbers
        else if (line.startsWith("-")) normalizedLine = 10 + Number(line)

        // Note: Do not use the first or last line
        normalizedLine = normalizedLine === 9 ? 8 : (normalizedLine === 0 ? 1 : normalizedLine)

        return normalizedLine*/
    }
    removeSubtitleFromLine(line) {
        this.overlay.children[this.normalizeLine(line)].innerHTML = ""
        delete this.subtitlesPresentByline[line]
    }
    addSubtitleLine(line, text, textAlign) {
        const renderToLine = this.normalizeLine(line)

        // Create text element
        const lineElement = document.createElement("p")
        lineElement.style.textAlign = "center" // textAlign.replace("line-", "") // Note: Always center all text
        lineElement.style.fontSize = `${this.fontSize}px`
        lineElement.innerText = text

        // Check if line already has some text in it
        if (this.subtitlesPresentByline[renderToLine]) {
            // Log a warning
            if (!this.warnedAboutOverlappingLines) {
                console.warn("SUBTITLE OVERLAY: Text is overlapping!", [this.subtitlesPresentByline[renderToLine].innerText], "->", [text])
                this.warnedAboutOverlappingLines = true
            }

            // Delete old line
            this.removeSubtitleFromLine(renderToLine)
        }

        // Add line to the overlay
        this.subtitlesPresentByline[renderToLine] = lineElement // Note: future-proofing
        this.overlay.children[renderToLine].appendChild(lineElement)
    }

    /**
     * Handle a subtitle event
     */
    handle(eventName, eventData) {
        switch(eventName) {
            case "cueenter": { // Handle the adding of a line of subtitles
                this.addSubtitleLine(eventData.vtt.line, eventData.text, eventData.vtt.positionAlign)
                break
            }
            case "cueexit": { // Handle the removal of a line of subtitles
                this.removeSubtitleFromLine(eventData.vtt.line)
                break
            }
            case "playerresized": { // Resize text based on player dimensions
                this.fontSize = Math.min(Math.max(30, Number(eventData.width.replace("px", "")) * 0.0382), 50)
                for (const lineNumber in this.subtitlesPresentByline) this.subtitlesPresentByline[lineNumber].style.fontSize = `${this.fontSize}px`
                break
            }
            case "seek":
            case "timeshift":
            case "audiochanged":
            case "subtitleenabled":
            case "subtitledisabled": { // Handle the clearing of all subtitles
                for (let i = 0; i < this.overlay.children.length; i++) this.removeSubtitleFromLine(i)
            }
        }
    }

    /* Make this a Bitmovin UI component */
    config = {
        tag: "div",
        id: `subtitle-overlay-${Math.floor(Math.random() * 100000)}`,
        cssPrefix: "",
        cssClasses: [],
        hidden: false,
        disabled: false
    }
    element = { document: null, elements: [] }
    hidden = false
    disabled = false
    hovered = false
    initialize() { }
    configure() { }
    toDomElement() { return this.element }
    getDomElement() { return this.element }
    hasDomElement() { return true }
}
