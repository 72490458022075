import React, { Component } from 'react'
import { Link } from 'react-router'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import { getItemHref } from '../shared/utils'
import Widget from './widget'

import appConfig from 'config'

import './carousel.css'

const Carousel = ({ widgetData = {}, extended }) => {
	const items = widgetData.items || [];

	return (
		<CarouselProvider
			naturalSlideWidth={16}
			naturalSlideHeight={9}
			totalSlides={items.length}
			isPlaying={true}
			>
			<Slider className="c6-press-carousel">
				{items.map((item, i) => <Slide key={item.id} index={i}><HighlightItem {...item} extended={extended} /></Slide>)}
			</Slider>
			{/* <DotGroup /> */}
			{/* <ButtonBack>Back</ButtonBack>
        	<ButtonNext>Next</ButtonNext> */}
		</CarouselProvider>
	);
}

export default Widget(Carousel);

// TODO: Re-use HighlightItem from Highlights.jsx instead, but maybe remember the className is different?
const HighlightItem = (props) => {
	const { id, reference, type, heading, description, assets: itemAssets = [], program = {}, extended } = props;
	const { assets: programAssets = [], tags = [] } = program;
	const mainImage = itemAssets.find(a => a.type === "image") || programAssets.find(a => a.type === "image");
	const brandTags = getBrandTags(tags);

	const href = getItemHref(props);
	const content = (
		<React.Fragment>
			{mainImage && (
				<img
					src={`${appConfig.media.star}${mainImage.id}/Comet_16x9_800x450.jpg`}
					alt={heading}
					decoding="async"
				/>
			)}
			{/* TODO: Use below URL when the images in Azure blob storage works */}
			{/*mainImage && <img src={`${appConfig.media.star}44/${mainImage.id}.jpg`} alt={heading} />*/}
			<div className="brand-tags">
				{brandTags.map(t => <span key={t} className={`brand-tag channel-logo channel-${t}`}></span>)}
			</div>
			{(heading || program.title || description || program && program.synopsis && program.synopsis.short) && (
				<div className="text">
					<h2>{heading || program.title}</h2>
					{extended && <p>{description || program && program.synopsis && program.synopsis.short || ""}</p>}
				</div>
			)}
		</React.Fragment>
	);

	const className = `c6-press-carousel-item ${type} ${extended ? "extended" : ""}`;

	if (!href || href === "") {
		return <div className={className}>{content}</div>;
	}

	// Use a regular anchor tag instead of react-router link if the link is not to this site
	if (href.startsWith("mailto") || href.startsWith("http://") || href.startsWith("https://") || href.startsWith("//")) {
		return <a href={href} className={className}>{content}</a>
	}

	return (
		<Link className={className} to={href}>{content}</Link>
	);
}

function getBrandTags(tags = []) {
	const brandTags = tags.filter(t => t.type === "Brand");
	return brandTags.map(t => t.name.toLowerCase().replace(/\s/g, ""));
}