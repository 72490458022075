import React from 'react'
import debounce from 'lodash/debounce'
import { browserHistory } from 'browserHistory'

import alt from '../../../core/services/alt'
import withDnDContext from '../../../core/services/withDnDContext'
import { hasRole } from '../../../core/services/auth'
import { displayAlert } from '../../../core/services/alert'
import { getReturnTo, isModalRoute } from '../../../core'

import App from '../../../components/app'
import { UICenter } from '../../../components/ui/alignment'
import { Filter, Left, Right } from '../../../components/filter'
import LightApproval from '../../../components/ui/editorFields/lightApproval'
import AssetDialog from '../../../components/assets/asseteditor/dialog'
import CustomDragLayer from '../../../components/list/dragLayer'
import Button from '../../../components/ui/controls/button'

import ContainerActions from './actions'
import ContainerStore from './store'
import PresentationSection from './presentation'
import Library from './library'

import appConfig from 'config'
import './container.css'
import EditorNavigationController from '../../../core/ui/editorNavigationController'
import { KEYBOARD_SHORTCUTS } from '../../../core/constants'

class StarContainerNew extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.state = {
			isEditingTitle: false,
			assetOpen: false,
			assetId: null,
			assetCategoryId: null,
			assetReadOnly: false,
			...ContainerStore.getState(),
		};
	}

	/* ***** INIT AND STATE HANDLING ***** */
	componentDidMount() {
		ContainerStore.listen(this.onChange);

		if (!getStarRole()) {
			return;
		}
		
		if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
			displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
		}

		this.containerType = this.props.location?.state?.typeName;

		// If no types are loaded, fetch them
		if (this.state.types.length <= 0) {
			ContainerActions.fetchTypes();
		}

		// If there is no container data, get container by id or reference id
		const { id, reference, provider } = this.props.params;
		if (!this.state.container.id) {
			if (reference) {
				ContainerActions.fetchContainer(reference, provider, this.containerType);
			} else if (id) {
				ContainerActions.fetchContainer(null, null, null, id);
			}
		}
	}

	componentWillUnmount() {
		// StarStore.unlisten(this.onChange);
		ContainerStore.unlisten(this.onChange);
		alt.recycle(ContainerStore);
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.id !== prevProps.params.id) {
			ContainerActions.fetchContainer(null, null, null, this.props.params.id);
		}
	}

	onChange = (state) => {
		this.setState(state);
	}

	/* ***** HEADER HANDLING ***** */
	handleTitleChange = (newValue) => {
		ContainerActions.updateTitle(this.state.container.id, newValue, this.state.container);
	}

	// handleTitleEditClick = (e) => {
	// 	e.preventDefault();

	// 	this.setState({ isEditingTitle: true });
	// }

	handleApprove = (isApproved) => {
		const targetStore = this.props.location && this.props.location.state && this.props.location.state.targetStore; // Also return the store to update in the module that opened the STAR editor
		ContainerActions.approveContainer(this.state.container, isApproved, targetStore);
	}

	onFilter = (type, e, searchText) => {
		if (type.includes("search")) {
			e.persist();
			this.debouncedFilter(type, e, searchText);
		}
		else {
			this.handleFilter(type, e);
		}
	}

	handleFilter = (type, event, searchText) => {
		const { name, value } = event.target;
		ContainerActions[type === "filter" ? "filter" : "search"](value || searchText, name);
		if (type === "search" && this.state.searchText) {
			ContainerActions.fetchContainers(this.state.searchText);
		} else if (type === "searchVideo" && this.state.videoSearchText) {
			ContainerActions.fetchVideos(this.state.videoSearchText);
		}

		this.setState({
			searchText: type !== "search" ? "" : value,
			videoSearchText: type !== "searchVideo" ? "" : value,
			unselectedFilter: type !== "search" ? false : value.length > 0,
		});

		// Force lazyload to load visible images
		window.dispatchEvent(window.customLazyEvent);
	}

	openAssetDialog = (assetId, categoryId, assetReadOnly) => {
		this.setState({
			assetOpen: true,
			assetId,
			assetCategoryId: categoryId,
			assetReadOnly,
		});
	}

	closeAssetDialog = () => {
		this.setState({
			assetOpen: false,
			assetId: null,
			assetCategoryId: null,
			assetReadOnly: false,
		});
	}

	render() {
		const {
			typesIsLoading,
			containerIsLoading,
			// isEditingTitle,
			container,
			types,

			searchLoading,
		} = this.state;

		const isModal = isModalRoute(this.props.routes);
		const isLoading = typesIsLoading || containerIsLoading || searchLoading;
		const backTitle = isModal ? '' : container.displayName;
		const starRole = getStarRole();

		if (!starRole) {
			return (
				<App name="c6-star-container-no-access" isLoading={isLoading} layout="grid">
					<UICenter>
						No read or editor access.
						Please contact support@junefirst.tv if you believe you should have access to this editor.
					</UICenter>
				</App>
			);
		}

		return (
			<App
				name="c6-star-container-metadata"
				isLoading={isLoading}
				layout="grid"
			>
				<Filter>
					<Left>
						<Button
							title="Close"
							type="cancel"
							noBackground={true}
							onClick={e => handleCancel(e, this.props.location, this.props.routes, this.props.params)}
						/>
						{appConfig.features.enableEditorNavigation && (
							<>
								<Button
									type="prev"
									hoverTitle={`Navigate to the previous item in the list (${KEYBOARD_SHORTCUTS.prev})`}
									noBackground={true}
									onClick={() => EditorNavigationController.goLeft()}
									disabled={!EditorNavigationController.canGoLeft()}
								/>
								<Button
									type="next"
									hoverTitle={`Navigate to the next item in the list (${KEYBOARD_SHORTCUTS.next})`}
									noBackground={true}
									onClick={() => EditorNavigationController.goRight()}
									disabled={!EditorNavigationController.canGoRight()}
								/>
							</>
						)}
					</Left>
					<Right>
						{container && container.id && (
							<LightApproval
								isApproved={!!container.approved}
								onChange={this.handleApprove}
								disabled={starRole !== "editor"}
							/>
						)}
					</Right>
				</Filter>

				<PresentationSection
					isLoading={containerIsLoading}
					types={types}
					container={container}
					isEditor={starRole === "editor"}
					openAssetDialog={this.openAssetDialog}
					closeAssetDialog={this.closeAssetDialog}
					handleTitleChange={this.handleTitleChange}
					viewContainer={this.viewContainer}
					backTitle={backTitle}
					starRole={starRole}
				/>
				
				<Library
					container={container}
					openAssetDialog={this.openAssetDialog}
					closeAssetDialog={this.closeAssetDialog}
				/>

				<AssetDialog
					open={this.state.assetOpen}
					onClose={this.closeAssetDialog}
					assetId={this.state.assetId}
					assetCategoryId={this.state.assetCategoryId}
					disabled={!!container.approved || starRole !== "editor" || !!this.state.assetReadOnly}
					hideTabs={!appConfig?.features?.assetEditorWithTabs}
				/>

				<CustomDragLayer />
			</App>
		);
	}
}

export default withDnDContext(StarContainerNew);


function getStarRole() {
	if (hasRole("star.AdminUser") || hasRole("star.RegularUser")) {
		return "editor";
	}
	if (hasRole("star.BrowserUser")) {
		return "reader";
	}

	return null;
}

function handleCancel(e, location, routes, params) {
	e.preventDefault();
	if (location.state && location.state.modal) {
		browserHistory.goBack();
	} else {
		const returnTo = getReturnTo(location, routes, params);
		browserHistory.replace(returnTo);
	}
}