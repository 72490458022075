import React from 'react'
import { Link } from 'react-router'

import InformationBox from '../widgets/informationBox'

import Translations from '../shared/translations'

import './footer.css'

const contentIfWidgetLoadFailed = (
	<React.Fragment>
		<span>
			<Link to={`/${Translations.getTranslation("url_faq")}`}>{Translations.getTranslation("faq")}</Link>
			<a href="https://www.mtv.fi/ohjelmat/tv-opas">{Translations.getTranslation("channels")}</a>
			<a href="https://varumarke.tv4.se/d/0EMuYcAL2hlO">{Translations.getTranslation("graphicProfile")}</a>
		</span>
		<span>
			<a href="https://www.mtv.fi/">{Translations.getTranslation("about")}</a>
			<a href="https://www.mtv.fi/ota-yhteytta">{Translations.getTranslation("customerService")}</a>
			<a href="https://www.mtvuutiset.fi">{Translations.getTranslation("news")}</a>
			<a href="https://www.mtv.fi/sarja/suomiareena-33001006">{Translations.getTranslation("arena")}</a>
			<a href="https://www.mtvuutiset.fi/makuja">{Translations.getTranslation("tastes")}</a>
			<a href="https://www.mtvuutiset.fi/palaute">{Translations.getTranslation("feedback")}</a>
			<a href="https://www.mtvspotti.fi">{Translations.getTranslation("advertisers")}</a>
			<a href="http://www.mtvyritys.fi">{Translations.getTranslation("company")}</a>
			<a href="https://www.cmore.fi">{Translations.getTranslation("cmore")}</a>
		</span>
	</React.Fragment>
);

const Footer = () => {
	return (
		<footer className="c6-press-footer">
			<div className="footer-container">
				<InformationBox
					name="footer"
					contentIfWidgetLoadFailed={contentIfWidgetLoadFailed}
				/>
			</div>
		</footer>
	);
};

export default Footer;