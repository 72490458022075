import React from 'react'

import { generateKey } from '../../../components/ui/editorFields'

import './checkbox.css'

const Checkbox = ({ label, radio, description, checked, onChange }) => {

	const handleChange = e => {
		onChange(e.target.checked);
	};

	const id = typeof(label) === "string" ? label.replace(/\s/, "") : generateKey();
	const inputId = `c6-press-checkbox-${id}-${checked}`;
	return (
		<div className={`c6-press-checkbox ${radio ? "radio" : ""}`}>
			<input id={inputId} type="checkbox" onChange={handleChange} checked={checked} />
			<label htmlFor={inputId}>
				<div className="label">{label}</div>
				<div className="description">{description}</div>
			</label>
		</div>
	);
};

export default Checkbox;