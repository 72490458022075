import Widget from './widget'
import PressButton from '../shared/button'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import { fetchAssetDownloadLink } from '../../../apis/star'

import './downloads.css'


const Downloads = ({ widgetData = {}, heading }) => {
	const { items: downloads = [] } = widgetData;
	return (
		<div className={`c6-press-downloads ${!heading || !heading.length ? "no-heading" : ""}`}>
			{downloads.map(dl => <Download key={dl.id} {...dl} />)}
		</div>
	);
}

export default Widget(Downloads);

const Download = ({ heading, assets, link }) => {
	const documentAsset = assets.find(a => a.type === "document");
	const onClick = async () => {
		const href = await fetchAssetDownloadLink(documentAsset.id);
		const a = document.createElement("a");
		a.target = "_blank";
		a.href = href;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	};

	const imageAsset = assets.find(a => a.type === "image");
	if (imageAsset && documentAsset) {
		return (
			<div
				className="download-item"
				onClick={onClick}
			>
				<div className="image">
					<ResponsiveImage
						id={imageAsset.id}
						sizes="300px"
					/>
				</div>
				<div className="title">
					{heading}
				</div>
			</div>
		)
	}

	if (documentAsset) {
		return (
			<PressButton
				label={heading}
				onClick={onClick}
			/>
		);
	}

	if (link?.url?.length) {
		return (
			<a className="c6-link icon-open_in_new" href={link.url} target="_blank">{heading}</a>
		);
	}
	
	return (
		<h3>{heading}</h3>
	);
}