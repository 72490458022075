import * as ContentAPI from '../../../apis/content'
import { useQuery } from '@tanstack/react-query'
// @ts-ignore
import appConfig from 'config'

interface args {
    filter?: object;
    enabled?: boolean;
}

const useContentVodPackages = ({ filter = {}, enabled = true }: args = {}) => {
    const filters = {
        ...filter,
        apiKey: appConfig.api.contentKey,
    };
    const isTv4Play = window.location.search.includes("site=tv4play");
    const apiMethod = isTv4Play ? ContentAPI.fetchPackagesTv4 : ContentAPI.fetchPackages;
    return useQuery(
        ["content", "vod-packages", filters],
        () => apiMethod(filters),
        {
            enabled,
            onError: () => {}, // Do nothing on error
        }
    );
};

export default useContentVodPackages;