import React from 'react'
import PropTypes from 'prop-types'
import Flatpickr from 'react-flatpickr'
import moment from 'moment'
import { Swedish } from 'flatpickr/dist/l10n/sv.js'
import { Finnish } from 'flatpickr/dist/l10n/fi.js'
import 'flatpickr/dist/flatpickr.min.css'
import 'flatpickr/dist/plugins/confirmDate/confirmDate.css'
import '../../../components/ui/controls/pickers/picker.css'
import keydown from 'react-keydown'

import {Filter, Left, Right} from '../../../components/filter'
import Button from '../shared/button'
import Switch from '../../../components/ui/controls/switch'

import Translations from '../shared/translations'

import Actions from '../actions'
import Const from '../../../core/constants'

import '../../internal-schedule/schedule/header.css'
import './schedulesHeader.css'

import appConfig from 'config'

const flatpickrLocales = {
	"fi": Finnish,
	"se": Swedish,
};

export default class Header extends React.Component {

	static propTypes = {
		date: PropTypes.string,
	}

	constructor(props) {
		super(props);
		this.listId = this.props.params?.id;
	}

	@keydown("left")
	handleNavPrev = () => {
		const { selectedChannels, date } = this.props;
		Actions.navPrev(date, selectedChannels);
	}

	@keydown("right")
	handleNavNext = () => {
		const { selectedChannels, date } = this.props;
		Actions.navNext(date, selectedChannels);
	}

	@keydown("ctrl+t")
	handleNavToday = () => {
		const { selectedChannels } = this.props;
		Actions.navToday(selectedChannels);
	}

	handleNavDate = (date) => {
		const { selectedChannels } = this.props;
		Actions.navDate(date[0], selectedChannels);
	}

	render() {
		const { date } = this.props;

		return (
			<Filter>
				<Left>
					<Button
						label={Translations.getTranslation("today")}
						title={Translations.getTranslation("todayTitle")}
						onClick={this.handleNavToday}
					/>
					<Button
						label={<span className="icon-chevron_left"></span>}
						title={Translations.getTranslation("previousDayTitle")}
						onClick={this.handleNavPrev}
					/>
					<Button
						label={<span className="icon-chevron_right"></span>}
						title={Translations.getTranslation("nextDayTitle")}
						onClick={this.handleNavNext}
					/>
					<Flatpickr
						options={{
							altInput: true,
							// altInputClass: "icon-schedule",
							altFormat: "D j M",
							// locale:	Swedish,
							locale: flatpickrLocales[appConfig.features.pressLocale],
							minDate: moment().subtract(50, "days").format(Const.DATE_FORMAT),
							maxDate: moment().add(50, "days").format(Const.DATE_FORMAT),
						}}
						value={date}
						onChange={this.handleNavDate}
					/>
				</Left>
				<Right>
					<Switch
						name="_channelGroup"
						title={Translations.getTranslation("channels")}
						onChange={this.props.handleFilter.bind(this, "filter")}
						states={getGroup(this.props.channelGroups, this.props.useChannelGroupsFallback)}
						currentState={this.props.channelGroup}
					/>
				</Right>
			</Filter>
		);
  	}
}


function getGroup(channelGroups = [], useChannelGroupsFallback) {
	if (!useChannelGroupsFallback) {
		return [
			// {
			// 	key: "",
			// 	text: Translations.getTranslation("all"),
			// 	description: "",
			// },
			...channelGroups
		];
	}

	return [
		{
			key: "",
			text: Translations.getTranslation("all"),
			description: "",
		},
		{
			key: "mtv",
			text: "MTV3, MTV Sub, MTV Ava",
			description: Translations.getTranslation("mtvDescription"),
		},
		{
			key: "cmore",
			text: "C More",
			description: Translations.getTranslation("cmoreDescription"),
		},
	];
}