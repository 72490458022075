import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Tab from './tab'

import './tabs.css'

export default class Tabs extends Component {
	static propTypes = {
		children: PropTypes.array.isRequired,
		defaultActiveTab: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
	}

	constructor(props) {
		super(props);

		this.state = {
			activeTab: props.defaultActiveTab || props.children[0].props.id,
		};
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.defaultActiveTab !== prevProps.defaultActiveTab) {
			this.setState({
				activeTab: this.props.defaultActiveTab || this.props.children[0].props.id
			});
		}
	}

	onTabClick = tabId => this.setState({ activeTab: tabId })

	renderTabs = () => {
		return this.props.children.map(({ props: tabProps }) => (
			<Tab
				key={tabProps.id}
				{...tabProps}
				id={tabProps.id}
				label={tabProps.label}
				isActive={this.state.activeTab === tabProps.id}
				onClick={e => {
					this.onTabClick(tabProps.id);
					if (tabProps.onClick) {
						tabProps.onClick(e, tabProps.id);
					}
				}}
			/>
		));
	}

	renderActiveTabContent = () => {
		const activeTab = this.props.children.find(child => child.props.id === this.state.activeTab);
		return activeTab && activeTab.props.children;
	}

	render() {
		return (
			<div className="c6-tabs">
				<div className="tab-list">
					{this.renderTabs()}
				</div>
				<div className="tab-content">
					{this.renderActiveTabContent()}
				</div>
			</div>
		);
	}
}