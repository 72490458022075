import React from 'react'
import cloneDeep from 'lodash/cloneDeep'

import Editor, { parseUi } from '../../../components/editor/'
import Approval from '../../../components/ui/editorFields/approval'
import ProgramPreview from '../shared/preview'

import { hasAccessToPath } from '../../../core/services/auth'
import * as API from '../../../apis/'
import { displayAlert } from '../../../core/services/alert'
import MetadataModule from '../module'

import schema from './schema'
import uiSchema from './uiSchema'

import appConfig from 'config'

const TagTypeIds = {
	keyword: 0,
	genre: 1,
	mood: 2,
	location: 3,
	ad: 4,
	category: 5,
	anno: 6,
	aman: 7,
	brand: 9,
	publisher: 10,
};

export default props => (
	<Editor
		layout="grid"
		api="tags"
		entity="item"
		getCommands={getCommands}
		getSchema={getSchema}
		getUiSchema={getUiSchema}
		customFields={{ approval: Approval }}
		loadPayloadTransform={loadPayloadTransform}
		savePayloadTransform={savePayloadTransform}
		hasEditAccess={hasAccessToPath(props.routes, "editor")}
		{...props}
	>
		<ProgramPreview />
	</Editor>
);

let metadataProgram = {};
const getCommands = ({ entity, location, route, params }) => ({
	fetchItem: ({id}) => {
		return new Promise((resolve, reject) => {
			API.metadata.fetchProgram({id})
				.then(metadataResponse => {
					metadataProgram = metadataResponse;
					const payload = {
						name: metadataProgram.name || "",
						itemTypeId: 0,
					};

					if (appConfig.features.tagsC80) {
						payload.provider = metadataProgram.provider?.name;
						payload.reference = metadataProgram.guid;
						payload.inputSource = "manual";
					} else if (appConfig.features.tagsC70) {
						payload.provider = metadataProgram.provider?.name;
						payload.reference = metadataProgram.reference;
						payload.inputSource = "manual";
					} else {
						payload.referenceId = metadataProgram.id;
					}

					return API.tags.fetchOrCreateItem(payload);
				}, reject)
				.then(tagsResponse => {
					resolve({
						...metadataProgram,
						...tagsResponse,
						id: metadataProgram.id,
						tagsProgramId: tagsResponse.id,
					});
				}, reject);
		});
	},
	updateItem: ({id}, payload) => {
		return new Promise((resolve, reject) => {
			if (appConfig.features.tagsC70 || appConfig.features.tagsC80) {
				payload.inputSource = "manual";
			}
			API.tags.updateItem({id}, payload)
				.then(response => {
					resolve({
						...metadataProgram,
						...response,
						id: metadataProgram.id,
						tagsProgramId: response.id,
					});
				}, reject);
		});
	}
});

const getSchema = (model, isNew, location, route, params) => {
	// Start with a fresh clone of the schema every time because RJSF will otherwise not update "required" for publisherTag for some reason
	const s = cloneDeep(schema);
	delete s.properties.annoTags;
	delete s.properties.amanTag;
	if (!appConfig.features.tagsShowBrandTags) {
		delete s.properties.brandTags;
	}

	let publisherTagRequired = false;
	if (appConfig.features.metadataPublisherTag && model?.type?.toLowerCase() !== "family") {
		const catalogue = MetadataModule.catalogueMap[params.catalogue];
		if (catalogue?.uiData?.publisherTagRequired !== undefined) {
			publisherTagRequired = catalogue.uiData.publisherTagRequired;
		} else {
			publisherTagRequired = ["britbox", "hayu", "tv4"].includes(params.catalogue);
		}
	}
	s.properties.publisherTag.required = publisherTagRequired ? ["id"] : undefined;

	return s;
};
const getUiSchema = (model, isNew, location, route, params) => {
	const ui = cloneDeep(uiSchema);

	ui.publisherTag.id["ui:widget"] = appConfig.features.metadataPublisherTag ? "select" : "hidden";

	return {
		"ui:readonly": model.approval?.status?.toLowerCase() === "approved",
		...parseUi(ui, API),
	};
};

function loadPayloadTransform({ model, entity, location, route, params }) {
	const { tags, ...payload } = model;

	if (appConfig.features.metadataEditorWarningMessageOnOpen?.length) {
		displayAlert("warning", null, null, null, null, appConfig.features.metadataEditorWarningMessageOnOpen);
	}

	// Content Discovery tags = Keywords, Genres, Moods, Locations, maybe more to come?
	const contentDiscoveryTagTypeIds = [TagTypeIds.keyword, TagTypeIds.genre, TagTypeIds.mood, TagTypeIds.location];

	const adTag = tags.find(tag => tag.tagType.id === TagTypeIds.ad) || {};
	const categoryTags = tags.filter(tag => tag.tagType.id === TagTypeIds.category);
	const brandTags = tags.filter(tag => tag.tagType.id === TagTypeIds.brand);
	const publisherTag = tags.find(tag => tag.tagType.id === TagTypeIds.publisher) || {};
	const contentDiscoveryTags = tags.filter(tag => contentDiscoveryTagTypeIds.includes(tag.tagType.id));

	return {
		publisherTag,
		adTag,
		categoryTags,
		brandTags,
		contentDiscoveryTags,
		...payload
	};
}

function savePayloadTransform({ formData, entity, location, ...rest }) {
	const { tagsProgramId, approval, adTag, publisherTag, categoryTags, brandTags, contentDiscoveryTags } = formData;

	// Make a tags-array
	// Add the adTag & publisherTag if they are set
	const tags = [...categoryTags, ...brandTags, ...contentDiscoveryTags];
	if (adTag && adTag.id !== null && adTag.id !== 0 && JSON.stringify(adTag) !== JSON.stringify({})) {
		tags.unshift(adTag);
	}
	if (publisherTag && publisherTag.id !== 0 && JSON.stringify(publisherTag) !== JSON.stringify({})) {
		tags.unshift(publisherTag);
	}
	
	// Delete tag.position since this overrides array index in API
	tags.forEach(tag => delete tag.position);

	return {
		...rest,
		formData: {
			id: tagsProgramId,
			tags,
			approval,
		},
		entity,
		location
	};
}