import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import './suggestions.css'

const ACTIVE_SUGGESTION_CLASS = "active-suggestion";
const DISABLED_SUGGESTION_CLASS = "disabled-suggestion";

export default class Suggestions extends React.Component {

	static propTypes = {
		query: PropTypes.string.isRequired,
		usedIds: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.string
			]),
		),
		suggestions: PropTypes.array.isRequired,
		selectedIndex: PropTypes.number.isRequired,
		onSuggestionClick: PropTypes.func.isRequired,
		onSuggestionHover: PropTypes.func,
		minQueryLength: PropTypes.number
	};

	constructor(props) {
		super(props);

		this.dataSourceConfig = props.dataSourceConfig ?? { value: "id", text: "displayName" };
	}

	shouldComponentUpdate(nextProps) {
		return (
			nextProps.selectedIndex !== this.props.selectedIndex
			|| !isEqual(nextProps.suggestions, this.props.suggestions)
			|| shouldRenderSuggestions(nextProps.query, this.props.minQueryLength)
			|| shouldRenderSuggestions(nextProps.query, this.props.minQueryLength) != shouldRenderSuggestions(this.props.query, this.props.minQueryLength)
		);
	}

	componentDidUpdate(prevProps) {
		const suggestionsContainer = this.refs.suggestionsContainer;
		const { selectedIndex } = this.props;

		// Scroll active suggestion into view
		if (suggestionsContainer && prevProps.selectedIndex !== selectedIndex) {
			const activeSuggestion = suggestionsContainer.querySelector(`.${ACTIVE_SUGGESTION_CLASS}`);

			if (activeSuggestion) {
				scrollSuggestionIntoView(activeSuggestion, suggestionsContainer);
			}
		}
	}

	render() {
		const {
			suggestions,
			onSuggestionClick,
			onSuggestionHover,
			selectedIndex,
			query,
			usedKeys
		} = this.props;

		// Render nothing if arrow keys are used with empty query
		if (query.length === 0 && (selectedIndex === -1 || suggestions.length === 0))
			return null;

		let items;
		if (query.length !== 0 && suggestions.length === 0) {
			items = <li className="no-results">No results found</li>;
		} else {
			items = suggestions.map((suggestion, i) => {
				const key = suggestion[this.dataSourceConfig.value]?.id ?? suggestion[this.dataSourceConfig.value];
				const selected = i == selectedIndex;
				const selectedClass = selected ? ACTIVE_SUGGESTION_CLASS : "";
				const disabled = usedKeys?.includes(key);
				const disabledClass = disabled ? DISABLED_SUGGESTION_CLASS : "";
				const className = `${selectedClass} ${disabledClass}`;
				return (
					<li
						key={`${key}-${i}`}
						onMouseDown={() => onSuggestionClick(i, disabled)}
						onMouseOver={() => onSuggestionHover(i, disabled)}
						className={className}
					>
						{suggestion[this.dataSourceConfig.text] || suggestion.name || `(Missing ${this.dataSourceConfig.text})`}
					</li>
				);
			});
		}

		return (
			<div className="c6-suggestions">
				<ul ref="suggestionsContainer">
					{items}
				</ul>
			</div>
		);
	}
}


const scrollSuggestionIntoView = (suggestionsEl, suggestionsContainer) => {
	const suggestionsContainerHeight = suggestionsContainer.offsetHeight;
	const suggestionHeight = suggestionsEl.offsetHeight;
	const relativeElTop = suggestionsEl.offsetTop - suggestionsContainer.scrollTop;

	if (relativeElTop + suggestionHeight >= suggestionsContainerHeight) {
		suggestionsContainer.scrollTop += relativeElTop - suggestionsContainerHeight + suggestionHeight;
	} else if (relativeElTop < 0) {
		suggestionsContainer.scrollTop += relativeElTop;
	}
};

const shouldRenderSuggestions = (query, minQueryLength) => query.length >= minQueryLength;