import React, { Component } from 'react'

import PressPage from './page'

import Actions from '../actions'

import './program.css'

export default class SingleProgram extends Component {
	programId = null

	UNSAFE_componentWillMount() {
		this.programId = this.props.params.programId;
		this.fetchProgram();
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.programId !== this.programId) {
			this.programId = this.props.params.programId;
			this.fetchProgram();
		}
	}

	fetchProgram = () => {
		Actions.fetchItem(
			"single",
			{ id: this.programId },
			{ targetStore: "program", callback: Actions.fetchProgramReviews },
		);
		Actions.fetchItem(
			"programSchedules",
			{ id: this.programId },
			{ targetStore: "programSchedules" },
		);
	}

	render() {
		return <PressPage pageName="single-program" />;
	}
}