import React from 'react'
import { withRouter } from 'react-router'

import Checkbox from '../shared/checkbox'
import Button from '../shared/button'
import Translations from '../shared/translations'

import * as PressAPI from '../../../apis/press'
import * as Alert from '../../../core/services/alert'

import appConfig from 'config'

import './subscriptionController.css'

@withRouter
export default class SubscriptionController extends React.Component {

	state = {
		subscriptions: [],
		isLoading: false,
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		PressAPI.fetchSubscription({ guid: this.props.params.guid, providerId: appConfig.features.pressSubscriptionsProviderId })
			.then(response => {
				this.setState({ isLoading: false, subscriptions: response });
			}, error => {
				Alert.displayAlert("error", Translations.getTranslation("loadSubscriptionsError"));
				this.setState({ isLoading: false });
			});
	}

	onSubscriptionChange = (id, value) => {
		const subscriptions = [...this.state.subscriptions];
		const index = subscriptions.findIndex(s => s.id === id);
		if (index >= 0) {
			subscriptions[index].subscribed = value;
		}
		this.setState({ subscriptions });
	}

	onSubmit = e => {
		e.preventDefault();
		this.setState({ isLoading: "submit" });
		this.sendUpdate();
	}

	onCancel = e => {
		e.preventDefault();
		const subscriptions = [...this.state.subscriptions];
		subscriptions.forEach(s => s.subscribed = false);
		this.setState({ subscriptions, isLoading: "cancel" });
		this.sendUpdate();
	}

	sendUpdate = () => {
		PressAPI.updateSubscription({ guid: this.props.params.guid, providerId: appConfig.features.pressSubscriptionsProviderId }, this.state.subscriptions)
			.then(response => {
				const atLeastOneSubscription = this.state.subscriptions.some(s => s.subscribed)
				const message = atLeastOneSubscription
					? Translations.getTranslation("subscriptionsUpdated")
					: Translations.getTranslation("subscriptionsCancelled");
				Alert.displayAlert("success", message);
				this.setState({ isLoading: false });
			}, error => {
				Alert.displayAlert("error", Translations.getTranslation("subscriptionsUpdateError"));
				this.setState({ isLoading: false });
			});
	}

	render() {
		const { isLoading, subscriptions } = this.state;
		return (
			<div className="c6-press-subscription">
				<form onSubmit={this.onSubmit}>
					<p>
						Lähetämme ilmoituksen mikäli ohjelmistoomme on tullut muutoksia. Muutosilmoitukset lähtevät yleensä kerran päivässä, mutta jos muutos koskee saman tai seuraavan päivän ohjelmia, lähetämme niistä ilmoituksen heti kuin mahdollista.
						<br/><br/>
						Voit valita mistä ohjelmistoista haluat muutosilmoitukset.
					</p>
					{subscriptions.map(({ id, title, description, subscribed }) => (
						<Checkbox
							key={id}
							label={title}
							description={description}
							checked={subscribed}
							onChange={this.onSubscriptionChange.bind(this, id)}
						/>
					))}
					<Button
						label={Translations.getTranslation("save")}
						type="submit"
						disabled={!!isLoading}
						isLoading={isLoading === "submit"}
					/>
					<Button
						label={Translations.getTranslation("cancelSubscriptions")}
						disabled={!!isLoading}
						isLoading={isLoading === "cancel"}
						onClick={this.onCancel}
					/>
				</form>
			</div>
		);
	}
}