import React, { Component } from 'react'

import * as PressAPI from '../../../apis/press'

import './processAccessRequest.css'

export default class ProcessAccessRequest extends Component {
	state = {
		status: null,
		errorMessage: null,
		accessRequest: {},
	}

	componentDidMount() {
		const newStatus = this.props.route.newStatus;
		const role = this.props.params.role;
		const guid = this.props.params.accessRequestGuid;
		if (!newStatus || !guid) {
			this.setState({ status: "error-url" });
		} else {
			this.setState({ status: "loading" });
			const command = role
				? PressAPI.updateAccessRequestWithRole({ guid, newStatus, role })
				: PressAPI.updateAccessRequest({ guid, newStatus });
			
			command.then(response => {
				this.setState({ status: "success", accessRequest: response });
			}, (error) => {
				// 190703: Hmm, can not read the error.message here, is it because errors from C70 APIs are not handled correctly in c6.js?
				this.setState({
					status: "error-api",
					errorMessage: error.status === 403 ? "This request has already been processed." : null,
				});
				// this.setState({ status: "error-api", errorMessage: error.message });
			});
		}
	}

	render() {
		let message;
		const newStatus = this.props.route.newStatus;
		const role = this.props.params.role;
		const { name } = this.state.accessRequest;
		switch (this.state.status) {
			case "loading":
				message = <h1>{newStatus === "approved" ? "Approving" : "Denying"} access request...</h1>;
				break;
			case "success":
				message = (
					<div>
						<h1>Access request for <b>{name}</b> was successfully <b style={{ color: newStatus === "approved" ? "var(--ok-color)" : "var(--attention-color)" }}>{newStatus}</b>.</h1>
						{role?.length > 0 && (
							<p>User was given the role: <b>{role}</b></p>
						)}
					</div>
				);
				break;
			case "error-url":
				message = <p>There seems to be something wrong with this link :(</p>;
				break;
			case "error-api":
				message = <p>{this.state.errorMessage || "Something went wrong. Please try again."}</p>;
				break;
			default:
				message = null;
				break;
		}

		return (
			<div className="c6-access-request">{message}</div>
		);
	}
}