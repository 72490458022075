import React, { Component } from 'react'

import Button from '../shared/button'
import TextField from '../shared/textField'
import Checkbox from '../shared/checkbox'

import Translations from '../shared/translations'

import * as PressAPI from '../../../apis/press'
import * as Alert from '../../../core/services/alert'

import appConfig from 'config'

import './loginForm.css'

const recaptchaElementId = "press-recaptcha";

export default class PressRegisterForm extends Component {
	state = {
		form: {
			name: "",
			email: "",
			phoneNumber: "",
			organisation: "",
			requestDescription: "",
		},
		userRegistrationStatus: null,
		recaptchaSuccess: false,
		conditionsAgreement: false,
		privacyPolicyAgreement: false,
	}

	componentDidMount() {
		addRecaptchaScript({
			onSuccess: () => this.setState({ recaptchaSuccess: true }),
			onExpire: () => this.setState({ recaptchaSuccess: false }),
		});
	}

	onSubmit = e => {
		e.preventDefault();

		const recaptchaResponse = getRecaptchaResponse();
		const { name, email, phoneNumber } = this.state.form;
		const requiredFieldsFilled = !!name.length && !!email.length && !!phoneNumber.length;
		if (recaptchaResponse && requiredFieldsFilled) {
			const payload = {
				recaptcha: recaptchaResponse,
				...this.state.form,
			};
			this.setState({ userRegistrationStatus: "loading" });
			PressAPI.createAccessRequest(payload)
				.then(() => {
					this.setState({ userRegistrationStatus: "success" });
				}, error => {
					resetRecaptcha();
					this.setState({
						userRegistrationStatus: null,
						recaptchaSuccess: false,
					});
					Alert.displayAlert("error", translateErrorMessage(error.exceptionMessage, email));
				});
		}
	}

	onFormChange = (key, value) => {
		this.setState({
			form: {
				...this.state.form,
				[key]: value,
			},
		});
	}

	render() {
		const { userRegistrationStatus, recaptchaSuccess, form, conditionsAgreement, privacyPolicyAgreement } = this.state;
		const isLoading = userRegistrationStatus === "loading";
		const submitDisabled = isLoading
			|| !recaptchaSuccess
			|| !form.name.length
			|| !form.email.length
			|| !form.phoneNumber.length
			|| !form.organisation.length
			|| !form.requestDescription.length
			|| !conditionsAgreement
			|| !privacyPolicyAgreement;

		return (
			<div className="c6-press-login">
				{(!userRegistrationStatus || isLoading) && (
					<form onSubmit={this.onSubmit}>
						<p>* = {Translations.getTranslation("requiredFields")}</p>
						<TextField label={Translations.getTranslation("name")} value={form.name} required={true} onChange={value => this.onFormChange("name", value)} disabled={isLoading} maxLength={200} />
						<TextField label={Translations.getTranslation("email")} value={form.email} type="email" required={true} onChange={value => this.onFormChange("email", value)} disabled={isLoading} maxLength={200} />
						<TextField label={Translations.getTranslation("phoneNumber")} value={form.phoneNumber} type="tel" required={true} onChange={value => this.onFormChange("phoneNumber", value)} disabled={isLoading} maxLength={30} />
						<TextField label={Translations.getTranslation("media")} value={form.organisation} required={true} onChange={value => this.onFormChange("organisation", value)} disabled={isLoading} maxLength={200} />
						<TextField label={Translations.getTranslation("purposeForRegistering")} value={form.requestDescription} required={true} multiline={true} onChange={value => this.onFormChange("requestDescription", value)} disabled={isLoading} maxLength={250} />
						<Checkbox
							label={(
								<span>
									{Translations.getTranslation("registrationConditionsAgreement")}
									&nbsp;<a className="c6-link" href="/faq" target="_blank">{Translations.getTranslation("registrationConditionsAgreementFAQLinkText")}</a>.
								</span>
							)}
							checked={conditionsAgreement}
							onChange={(value) => this.setState({ conditionsAgreement: value })}
						/>
						<Checkbox
							label={(
								<span>
									{Translations.getTranslation("registrationPrivacyPolicyAgreement")}
									&nbsp;<a className="c6-link" href={appConfig.features.registrationPrivacyPolicyAgreementLink} target="_blank">{Translations.getTranslation("registrationPrivacyPolicyAgreementLinkText")}</a>.
								</span>
							)}
							checked={privacyPolicyAgreement}
							onChange={(value) => this.setState({ privacyPolicyAgreement: value })}
						/>
						<div id={recaptchaElementId}></div>
						<p dangerouslySetInnerHTML={{ __html: Translations.getTranslation("registrationCaptchaPolicyText") }}></p>
						<Button
							label={Translations.getTranslation("submit")}
							type="submit"
							disabled={submitDisabled}
							isLoading={isLoading}
						/>
					</form>
				)}
				{userRegistrationStatus === "success" && (
					<div>
						<h1 className="success icon-check_circle">{Translations.getTranslation("requestSubmitted")}</h1>
						<h2>{Translations.getTranslation("requestSubmittedInfo")}</h2>
					</div>
				)}
			</div>
		);
	}
}

function translateErrorMessage(errorMessage, email) {
	if (errorMessage.includes("has not been handled")) {
		const template = Translations.getTranslation("requestNotHandled");
		return template.replace("{email}", email);
	}
	if (errorMessage.includes("already exists")) {
		const template = Translations.getTranslation("accountAlreadyExists");
		return template.replace("{email}", email);
	}
	return errorMessage;
}

const addRecaptchaScript = ({ onSuccess, onExpire }) => {
	const onloadFunctionName = "onRecaptchaScriptLoad";
	window[onloadFunctionName] = () => {
		window.grecaptcha.render(recaptchaElementId, {
			sitekey: appConfig.features.pressRecaptchaSiteKey,
			theme: "light",
			callback: onSuccess,
			"expired-callback": onExpire,
		});
	};

	const script = document.createElement("script");
	script.onerror = e => console.error("recaptcha script onerror", e);
	script.async = true;
	script.src = `https://www.google.com/recaptcha/api.js?onload=${onloadFunctionName}`;
	document.head.appendChild(script);
}

const getRecaptchaResponse = () => window.grecaptcha && window.grecaptcha.getResponse() || null;
const resetRecaptcha = () => window.grecaptcha && window.grecaptcha.reset();