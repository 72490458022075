import React from 'react'
import { Route, IndexRoute } from 'react-router'

import RequireAuthentication from '../core/authentication'

// MODULES
import PressCore from '../apps/press/core'
import Press from '../apps/press/module'

export default (
	<Route component={PressCore} path="/">
		{Press.routes()}

		<Route component={RequireAuthentication}>
			{Press.protectedRoutes()}
		</Route>
	</Route>
);