import Widget from './widget'
import ResponsiveImage from '../../../components/assets/responsiveImage'

import './heroItem.css'


const HeroItem = (props) => {
	const { widgetData = {} } = props;
	const { assets, body } = widgetData;
	const imageAssetId = assets?.find(a => a.type === "image")?.id;

	let heading = widgetData.heading;
	// HACK/TODO: backend currently sets "heading" using internal title (list.name) as fallback which is wrong
	if (heading === "mtv image") {
		heading = null;
	}
	
	return (
		<div className="c6-press-hero-item">
			<div className="image">
				<ResponsiveImage id={imageAssetId} format="fit" />
			</div>
			<div className="content">
				{heading && <h2>{heading}</h2>}
				{body && <div dangerouslySetInnerHTML={{ __html: body }} />}
			</div>
		</div>
	);
}

export default Widget(HeroItem);