import { PublicClientApplication } from '@azure/msal-browser'
import SSOInterface from './SSOInterface'

// @ts-ignore
import appConfig from 'config'

const redirectUriBase = appConfig.app.basePath?.length
    ? `${window.location.origin}/${appConfig.app.basePath}`
    : `${window.location.origin}`;
const MSAL_CONFIG = {
    auth: {
        clientId: appConfig.features.msalClientId,
        authority: appConfig.features.msalAuthority,
        redirectUri: `${redirectUriBase}/sso-callback`,
    },
};

export default class MSAL implements SSOInterface {
    private msalInstance: PublicClientApplication;

    constructor() {
        this.msalInstance = new PublicClientApplication(MSAL_CONFIG);
    }

    async initialize() {
        await this.msalInstance.initialize();
        this.msalInstance.handleRedirectPromise(); // Is this needed? Much confuse
    }

    async login() {
        const authResult = await this.msalInstance.loginPopup({
            scopes: ["openid", "email"],
        });
        this.msalInstance.setActiveAccount(authResult.account);
        return authResult;
    }

    async logout() {
        await this.msalInstance.logoutRedirect({
            account: this.msalInstance.getActiveAccount(),
            onRedirectNavigate: () => {
                // Return false if you would like to stop navigation after local logout
                return false;
            }
        });
    }
}