import React from 'react'

import { useEditor, EditorContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
// import ImageExtension from '@tiptap/extension-image'
import UnderlineExtension from '@tiptap/extension-underline'
import TextAlignExtension from '@tiptap/extension-text-align'

import './wysiwyg.css'

const WYSIWYG = ({
	disabled,
    content,
    onChange,
}) => {

    const prevContent = React.useRef(content);

    const editor = useEditor({
        extensions: [
            StarterKit,
            // ImageExtension.configure({
            //     inline: true,
            //     allowBase64: true
            // }),
            UnderlineExtension,
            TextAlignExtension.configure({
                types: ["heading", "paragraph"],
            }),
        ],
        editorProps: {
            // handleDrop: (view, event, slice, moved) => {
            //     // debugger;
            //     if (!moved && event.dataTransfer && event.dataTransfer.files) { // if dropping external files
            //         // the addImage function checks the files are an image upload, and returns the url
            //         addImage(event.dataTransfer.files[0], function(url) {
            //             // this inserts the image with src url into the editor at the position of the drop
            //             const { schema } = view.state;
            //             const coordinates = view.posAtCoords({ left: event.clientX, top: event.clientY });
            //             const node = schema.nodes.image.create({ src: url });
            //             const transaction = view.state.tr.insert(coordinates.pos, node);
            //             return view.dispatch(transaction);
            //         });
            //         return true; // drop is handled don't do anything else
            //     }
            //     return false; // not handled as wasn't dragging a file so use default behaviour
            // }
        },
        content,
        onUpdate: ({ editor: updatedEditor }) => {
            const newContent = updatedEditor.getHTML();

            const didChange = newContent !== prevContent.current // Only call onChange if value changed
                && !(newContent === "<p></p>" && !prevContent.current); // and this prevents onChange call on first render
            if (didChange) {
                onChange(newContent);
            }
            prevContent.current = newContent;
        },
        editable: !disabled,
    });

    React.useEffect(
        () => {
            if (editor) {
                editor.setEditable(!disabled);
            }
        },
        [disabled, editor]
    );

	return (
        <div className="c6-wysiwyg">
            <Toolbar editor={editor} disabled={disabled} />
            <div className="c6-wysiwyg-content">
                <EditorContent editor={editor} />
            </div>
        </div>
    );
};

export default WYSIWYG;



const Toolbar = ({ editor, disabled }) => {
    if (!editor || disabled) {
        return null;
    }

    return (
        <div className="c6-wysiwyg-toolbar">
            <button
                type="button"
                title="Heading 1"
                onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
                className={editor.isActive("heading", { level: 1 }) ? "is-active" : ""}
            >
                H1
            </button>
            <button
                type="button"
                title="Heading 2"
                onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
                className={editor.isActive("heading", { level: 2 }) ? "is-active" : ""}
            >
                H2
            </button>
            <button
                type="button"
                title="Paragraph"
                onClick={() => editor.chain().focus().setParagraph().run()}
                className={editor.isActive("paragraph") ? "is-active" : ""}
            >
                P
            </button>
            <div className="divider"></div>
            <button
                type="button"
                title="Bold"
                onClick={() => editor.chain().focus().toggleBold().run()}
                disabled={!editor.can().chain().focus().toggleBold().run()}
                className={editor.isActive("bold") ? "is-active" : ""}
            >
                <strong>B</strong>
            </button>
            <button
                type="button"
                title="Italics"
                onClick={() => editor.chain().focus().toggleItalic().run()}
                disabled={!editor.can().chain().focus().toggleItalic().run()}
                className={editor.isActive("italic") ? "is-active" : ""}
            >
                <i>I</i>
            </button>
            <button
                type="button"
                title="Strike"
                onClick={() => editor.chain().focus().toggleStrike().run()}
                disabled={!editor.can().chain().focus().toggleStrike().run()}
                className={editor.isActive("strike") ? "is-active" : ""}
            >
                <span style={{ textDecoration: "line-through" }}>S</span>
            </button>
            <button
                type="button"
                title="Underline"
                onClick={() => editor.chain().focus().toggleUnderline().run()}
                disabled={!editor.can().chain().focus().toggleUnderline().run()}
                className={editor.isActive("underline") ? "is-active" : ""}
            >
                <span style={{ textDecoration: "underline" }}>U</span>
            </button>
            <div className="divider"></div>
            <button
                type="button"
                title="Text align: left"
                onClick={() => editor.chain().focus().setTextAlign("left").run()}
                className={editor.isActive({ textAlign: "left" }) ? "is-active" : ""}
            >
                <span className="icon-format_align_left"></span>
            </button>
            <button
                type="button"
                title="Text align: center"
                onClick={() => editor.chain().focus().setTextAlign("center").run()}
                className={editor.isActive({ textAlign: "center" }) ? "is-active" : ""}
            >
                <span className="icon-format_align_center"></span>
            </button>
            <button
                type="button"
                title="Text align: right"
                onClick={() => editor.chain().focus().setTextAlign("right").run()}
                className={editor.isActive({ textAlign: "right" }) ? "is-active" : ""}
            >
                <span className="icon-format_align_right"></span>
            </button>
            <button
                type="button"
                title="Text align: justify"
                onClick={() => editor.chain().focus().setTextAlign("justify").run()}
                className={editor.isActive({ textAlign: "justify" }) ? "is-active" : ""}
            >
                <span className="icon-format_align_justify"></span>
            </button>
            {/* <div className="divider"></div>
            <button
                type="button"
                title="Insert an image"
                onClick={() => {
                    const src = window.prompt(
                        "Enter URL for your image. In the future, images can be dropped and uploaded to Comet automatically.",
                        "https://image-demo.junecomet.com/program/6ac96198-bdd4-48e8-8c1f-7cc2fcd125fd/main/Comet_16x9_200x112.jpg"
                    );
                    editor.chain().focus().setImage({ src }).run()
                }}
            >
                <span className="icon-insert_photo"></span>
            </button> */}
        </div>
    );
};