import React 				 			from 'react'
import { Route, IndexRoute, Redirect } 	from 'react-router'
import moment 				 			from 'moment'

import PressWrapperApp 		from './app'
import PressPage 			from './pages/page'
import SingleProgram		from './pages/single'
import SeriesProgram		from './pages/season'
import ProcessAccessRequest	from './pages/processAccessRequest'
import Search 				from './pages/search'
// import LogosTest 			from './pages/logosTest'

import Translations			from './shared/translations'

export default class Press {
	static routes() {
		return (
			<Route>
				<Route path="accessrequests/:accessRequestGuid/approved" component={ProcessAccessRequest} newStatus="approved" />
				<Route path="accessrequests/:accessRequestGuid/denied" component={ProcessAccessRequest} newStatus="denied" />
				<Route component={PressWrapperApp}>
					<IndexRoute component={PressPage} />

					{/* Hidden page for testing logo sizes */}
					{/*<Route path="logos-test" component={LogosTest} />*/}

					{/* Program pages */}
					<Route path={`${Translations.getTranslation("url_program")}/:programId`} component={SingleProgram} />
					<Route path={`${Translations.getTranslation("url_series")}/:seriesId`} component={SeriesProgram} />
					<Route path={`${Translations.getTranslation("url_series")}/:seriesId/${Translations.getTranslation("url_season")}/:seasonId`} component={SeriesProgram} />
					<Route path={`${Translations.getTranslation("url_series")}/:seriesId/${Translations.getTranslation("url_season")}/:seasonId/${Translations.getTranslation("url_episode")}/:id`} component={SeriesProgram} />
					<Route path={`${Translations.getTranslation("url_season")}/:seasonId`} component={SeriesProgram} />
					<Route path={`${Translations.getTranslation("url_season")}/:seasonId/${Translations.getTranslation("url_episode")}/:id`} component={SeriesProgram} />

					{/* Press release */}
					<Route path={`${Translations.getTranslation("url_pressrelease")}/:id`} component={props => <PressPage pageName={Translations.getTranslation("url_pressrelease")} {...props} />} />
					<Route path={`${Translations.getTranslation("url_bulletin")}/:id`} component={props => <PressPage pageName={Translations.getTranslation("url_bulletin")} {...props} />} />

					{/* Schedules */}
					<Route path={Translations.getTranslation("url_schedules")}>
						<IndexRoute onEnter={addScheduleDateIfMissing} />
						<Route path=":date" component={props => <PressPage pageName={Translations.getTranslation("url_schedules")} {...props} />} />
					</Route>
					<Route path={`${Translations.getTranslation("url_updateschedulessubscription")}/:guid`} component={props => <PressPage pageName={Translations.getTranslation("url_updateschedulessubscription")} {...props} />} />

					{/* Search */}
					<Route path={Translations.getTranslation("url_search")} component={Search} />

					{/* Account/Password */}
					<Route path={`${Translations.getTranslation("url_resetpassword")}/:guid`} component={props => <PressPage pageName={Translations.getTranslation("url_resetpassword")} {...props} />} />

					{/* The user tokens should last a long time in Press. If they expire and the user is sent to /logout, we redirect from /logout to start page */}
					<Redirect from="logout" to="/" />

					{/* For all pages that don't need to fetch something based on parameter in the URL */}
					<Route path=":pageName(/:param)" component={PressPage} onEnter={translatePageName} />
				</Route>
			</Route>
		);
	}

	static protectedRoutes() {
		return (
			<Route path="protected">
				<Route path="accessrequests/:accessRequestGuid/approved/:role" component={ProcessAccessRequest} newStatus="approved" />
				<Route path="accessrequests/:accessRequestGuid/denied/:role" component={ProcessAccessRequest} newStatus="denied" />
			</Route>
		);
	}
}

// HELPERS
function addScheduleDateIfMissing({ params }, replace, callback) {
	if (!params || !params.date) {
		replace(`/${Translations.getTranslation("url_schedules")}/${moment().format("YYYY-MM-DD")}`);
	}
	callback();
}

function translatePageName({ location, params, routes }, replace, callback) {
	if (params?.pageName && Translations.PressTranslations.hasOwnProperty(`url_${params.pageName.replace(/-/g, "")}`)) {
		const newPathname = location.pathname.replace(params.pageName, Translations.getTranslation(`url_${params.pageName.replace(/-/g, "")}`));
		if (newPathname !== `/${params.pageName}`) {
			replace({
				...location,
				pathname: newPathname,
			});
		}
	}
	callback();
}