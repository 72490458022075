import React, { Component } from 'react'
import moment from 'moment-timezone'
import { withRouter } from 'react-router'

import { MTV_DATE_FORMAT } from '../shared/utils'
import Translations from '../shared/translations'

import Actions from '../actions'

import appConfig from 'config'

import './pressRelease.css'

@withRouter
export default class PressRelease extends Component {
	componentDidMount() {
		Actions.fetchItem.defer("pressRelease", { id: this.props.params.id });
	}

	render() {
		const { id, header, date, brand, description, body, images = [] } = this.props.pressRelease;

		if (!id) {
			return null;
		}

		const dateFormat = appConfig.features.pressDateFormatPressReleases ?? MTV_DATE_FORMAT;

		return (
			<div className="c6-press-release">
				<h1 dangerouslySetInnerHTML={{ __html: header }}></h1>
				<h4>
					{brand && <span className={`channel-logo channel-${brand.toLowerCase().replace(/\s/g, "")}`}></span>}
					<span className="icon-insert_invitation"><time className="date" title={date} dateTime={moment(date).format()}>{moment(date).tz(appConfig.features.pressTimezone).format(dateFormat)}</time></span>
				</h4>
				<div className="description" dangerouslySetInnerHTML={{ __html: description }}></div>
				<div className="body" dangerouslySetInnerHTML={{ __html: body }}></div>

				{images && images.length ? <h2 className="section-header">{Translations.getTranslation("images")}</h2> : null}
				<div className="images">
					{images.map(image => (
						<a
							key={image.original}
							className="image"
							href={image.original}
							download={image.description}
						>
							<div>
								<img
									src={image.original}
									alt={image.name}
									decoding="async"
								/>
							</div>
						</a>
					))}
				</div>
			</div>
		);
	}
}