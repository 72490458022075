import { browserHistory } from 'browserHistory'
import Moment from 'moment'

import alt from '../../core/services/alt'

import * as MetadataData from '../../apis/metadata'
// import * as ContentData from '../../apis/content'

import { fetchTag, removeItemFromTag } from '../../apis/tags'

import { handleRequestFailed } from '../../core/services/errorhandling'
import EditorNavigationController from '../../core/ui/editorNavigationController'

import { addChannel, removeChannel } from '../internal-schedule/shared/utils'

import Const from '../../core/constants'

const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

import appConfig from 'config'

class Actions {

	/* **************** Actions **************** */
	edit({id, pathname, search, returnTo, wideModal, typeId, typeName, query, modal = true, targetStore = null}) {
		const route = {
			pathname,
			search,
			query,
			state: {
				modal,
				wideModal,
				typeId,
				typeName,
				returnTo,
				targetStore,
			}
		};

		browserHistory.push(route);
		
		EditorNavigationController.setCurrentItem({ id });

		return route;
	}

	create({ pathname, search, returnTo }) {

		const route = {
			pathname,
			search,
			state: {
				modal: true,
				returnTo,
			}
		};

		browserHistory.push(route);

		return route;
	}

	search(searchText, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				searchText,
				targetStore,
			});
		}
	}

	filter(filterValue, filterName, targetStore) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
				targetStore,
			});
		};
	}

	/* **************** Content templates  **************** */
	setAsDefaultTemplate({ id, parentId }) {
		return (dispatch) => {
			dispatch(id);
			MetadataData.setAsDefaultTemplate(id)
				.then(model => {
					this.templateUpdated({ model, parentId });
				}, handleRequestFailed);
		}
	}

	releaseDefaultTemplate({ id, parentId }) {
		return (dispatch) => {
			dispatch(id);
			MetadataData.releaseDefaultTemplate(id)
				.then(model => {
					this.templateUpdated({ model, parentId });
				}, handleRequestFailed);
		}
	}

	useEpisodeTemplate(id, masterId) {
		return (dispatch) => {
			dispatch({ id, masterId });
			MetadataData.useTemplate(id, masterId)
				.then(model => {
					this.episodeTemplateStatusUpdated({ model });
				}, handleRequestFailed);
		}
	}

	ignoreEpisodeTemplate(id) {
		return (dispatch) => {
			dispatch(id);
			MetadataData.ignoreTemplate(id)
				.then(model => {
					this.episodeTemplateStatusUpdated({ model });
				}, handleRequestFailed);
		}
	}

	templateUpdated(payload) {
		return payload;
	}

	episodeTemplateStatusUpdated(payload) {
		return payload;
	}

	/* **************** Items **************** */
	fetchItems(entity, payload, targetStore = null, noDisplayAlert = false) {
		return (dispatch) => {
			const requestTime = Date.now();
			const multiple = true;
			const command = getCommand("fetch", entity, multiple);
			const store = targetStore || getStore(entity, multiple);

			dispatch({ payload, store, requestTime });

			// HACK?: Return nothing when searchtext is less than 2 chars
			const { searchText } = payload;
			if(searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST) {
				this.itemsUpdated(store, [], false, 0, null, requestTime);
			}
			else {
				MetadataData[command](payload)
					.then(response => {
						const { pageIndex, numberOfItems, items, links } = response;
						const appendToExistingItems = pageIndex > 0;

						const nextPageUrl = getNextPageUrl(links);
						this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime);

					}, (error) => {
						if (noDisplayAlert) {
							error.noDisplayAlert = true;
						}
						this.requestFailed(error);
					});
			}
		};
	}
	pageItems(entity, url, targetStore = null) {
		return (dispatch) => {
			const multiple = true;
			const store = targetStore || getStore(entity, multiple);

			dispatch({ entity, url, store });

			MetadataData.fetchUrl(url)
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);

				}, this.requestFailed);
		};
	}
	itemsUpdated(datastore, items, appendToExistingItems, numberOfItems, nextPageUrl, requestTime) {
		return {
			datastore,
			items,
			appendToExistingItems,
			numberOfItems,
			nextPageUrl,
			requestTime,
		};
	}

	/* **************** Schedule **************** */
	fetchScheduleDay(store, payload, hideLoading, rollbackDate) {
		return (dispatch) => {
			const requestTime = Date.now();
			dispatch({payload, requestTime, hideLoading});

			MetadataData.fetchSchedule(payload)
				.then(response => {
					const { items, numberOfItems, links } = response;
					this.scheduleDayUpdated(store, items, requestTime);
				}, error => {
					if(rollbackDate) {
						this.rollbackScheduleDay(rollbackDate);
					}
					this.requestFailed(error);
				});
		};
	}
	scheduleDayUpdated(datastore, items, numberOfItems, requestTime) {
		return {
			datastore,
			items,
			numberOfItems,
			requestTime,
		};
	}
	rollbackScheduleDay(rollbackDate) {
		browserHistory.replace(`/metadata/linear/${rollbackDate}/`);

		return true;
	}

	navNext(date, selectedChannels) {
		return (dispatch) => {
			const newDate = Moment(date).add(1, "day").format(Const.DATE_FORMAT);
			dispatch(newDate);

			if(!appConfig.features.metadataScheduleSkipChannels) {
				browserHistory.push(`/metadata/linear/${newDate}/${selectedChannels}`);
			}
			else {
				browserHistory.push(`/metadata/linear/${newDate}/`);
			}
		};
	}

	navPrev(date, selectedChannels) {
		return (dispatch) => {
			const newDate = Moment(date).subtract(1, "day").format(Const.DATE_FORMAT);
			dispatch(newDate);
			if(!appConfig.features.metadataScheduleSkipChannels) {
				browserHistory.push(`/metadata/linear/${newDate}/${selectedChannels}`);
			}
			else {
				browserHistory.push(`/metadata/linear/${newDate}/`);
			}
		};
	}

	navToday(selectedChannels) {
		return (dispatch) => {
			dispatch();
			if(!appConfig.features.metadataScheduleSkipChannels) {
				browserHistory.push(`/metadata/linear/today/${selectedChannels}`);
			}
			else {
				browserHistory.push(`/metadata/linear/today/`);
			}
		};
	}

	navDate(date, selectedChannels) {
		return (dispatch) => {
			const navDate = Moment(date).format(Const.DATE_FORMAT);
			dispatch(navDate);

			if(!appConfig.features.metadataScheduleSkipChannels) {
				browserHistory.push(`/metadata/linear/${navDate}/${selectedChannels}`);
			}
			else {
				browserHistory.push(`/metadata/linear/${navDate}/`);
			}
		};
	}

	selectChannel(channels, selectedChannels, date) {
		return (dispatch) => {
			dispatch(channels);
			browserHistory.push(`/metadata/linear/${date}/${addChannel(channels, selectedChannels)}`);
		}
	}

	unselectChannel(channels, selectedChannels, date) {
		return (dispatch) => {
			dispatch(channels);
			browserHistory.push(`/metadata/linear/${date}/${removeChannel(channels, selectedChannels)}`);
		}
	}

	scheduleFilter(filterValue, filterName) {
		return (dispatch) => {
			dispatch({
				[filterName]: filterValue,
			});
		};
	}

	/* **************** Item **************** */
	fetchItem(entity, payload) {
		return (dispatch) => {
			dispatch();

			const command = getCommand("fetch", entity);
			MetadataData[command](payload)
				.then(model => {
					this.itemLoaded({entity, model});
				}, this.requestFailed);
		};
	}
	itemLoaded(payload) { return payload; }

	updateItem(entity, data, payload, type = "update", targetStore) {
		return (dispatch) => {
			dispatch();

			const command = getCommand(type, entity);
			MetadataData[command](data, payload)
				.then(model => {
					if (payload.programStatus === "hidden") {
						this.itemRemoved({ entity: targetStore, id: data.id });
					} else {
						this.itemUpdated({ entity, model, originalId: data.id, targetStore });
					}
				}, this.requestFailed);
		}
	}

	enableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			MetadataData[command](data, { enabled: true })
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	disableItem(entity, data) {
		return (dispatch) => {
			dispatch(data.id);

			const command = getCommand("update", entity);
			MetadataData[command](data, { enabled: false })
				.then(model => {
					this.itemUpdated({ entity, model, originalId: data.id });
				}, this.requestFailed);
		};
	}
	itemUpdated(payload) { return payload; }

	removeItem(entity, data) {
		return (dispatch) => {
			dispatch({ entity, id: data.id});

			const command = getCommand("delete", entity);
			MetadataData[command](data)
				.then(() => {
					this.itemRemoved({ entity, id: data.id});
				}, (error, request) => {
					// TODO!: Keep item index when rollbacking
					this.rollbackRemoveItem({ entity, id: data.id });
					this.requestFailed({ error, request });
				});
		};
	}
	itemRemoved(payload) { return payload; }
	rollbackRemoveItem(payload) { return payload};

	/* **************** Item entities **************** */
	addItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("add", entities);
			const store = getStore(entities);

			MetadataData[command](payload)
				.then(model => {
					this.itemLoaded({entity: store, model });
				}, this.requestFailed);
		};
	}

	removeItemEntity(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("remove", entities);
			const store = getStore(entities);

			MetadataData[command](payload)
				.then(model => {
					this.itemLoaded({entity: store, model });
				}, this.requestFailed);
		};
	}

	setItemEntityProperty(entities, payload) {
		return (dispatch) => {
			dispatch({entities, payload});

			const command = getCommand("update", entities);
			MetadataData[command](payload)
				.then(model => {
					this.itemLoaded({ entity: entities.parentEntity, model });
				}, this.requestFailed);
		};
	}

	/* **************** Misc **************** */
	// viewChange(isTranslationList) {
	// 	return (dispatch) => {
	// 		dispatch({isTranslationList});
	// 	}
	// }
	unmount() { return true; }

	requestFailed(error) {
		handleRequestFailed(error);
		return true;
	}

	/* **************** Custom **************** */
	filterSeriesSeasons(filterValue) {
		return filterValue;
	}
	fetchSeriesSeasons(id, searchFilters, type = "season", episodeId, templateRendered) {
		return (dispatch) => {

			switch(type) {
				case "season":
					dispatch(4);
					this.setSelectedSeason(id);
					// Season
					MetadataData.fetchProgram({ id })
						.then(seasonModel => {
							this.seriesSeasonDataLoaded(seasonModel);
							const { parentId, parentGuid, guid } = seasonModel;

							if(parentId) {
								// Series
								MetadataData.fetchProgram({ id: parentId })
									.then(this.seriesSeasonDataLoaded, this.requestFailed);

								// Seasons
								MetadataData.fetchPrograms({ parentProgramGuid: parentGuid, ...searchFilters })
									.then(this.seriesSeasonDataLoaded, this.requestFailed);
							}
							else {
								// No parentId on season, we need to let the user pick or create a series
								this.seriesSeasonDataLoaded(getEmptySeries(id));
							}

							// Fetch single episode if an episodeId is provided, else fetch all (top 250) episodes
							if(episodeId && episodeId > 0) {
								MetadataData.fetchPrograms({ programId: episodeId, ...searchFilters })
									.then(this.seriesSeasonDataLoaded, this.requestFailed)
									.then(response => {
										this.fetchMasterTemplate(response.items, templateRendered);
									});

							}
							else {
								MetadataData.fetchPrograms({ parentProgramGuid: guid, ...searchFilters })
									.then(this.seriesSeasonDataLoaded, this.requestFailed)
									.then(response => {
										this.fetchMasterTemplate(response.items, templateRendered);
									});
							}

						}, this.requestFailed);

					break;
				case "series":
					dispatch(3);

					// Series
					MetadataData.fetchProgram({ id })
						.then(seriesModel => {
							this.seriesSeasonDataLoaded(seriesModel);

							// Seasons
							MetadataData.fetchPrograms({ parentProgramGuid: seriesModel.guid,  ...searchFilters })
								.then(seasons =>{
									this.seriesSeasonDataLoaded(seasons);

									if(seasons && seasons.items && seasons.items.length) {
										// Select latest season
										const lastSeasonModel = seasons.items[seasons.items.length - 1];
										this.setSelectedSeason(lastSeasonModel.id);

										// Fetch single episode if an episodeId is provided, else fetch all (top 250) episodes
										if(episodeId && episodeId > 0) {
											MetadataData.fetchPrograms({ programId: episodeId, ...searchFilters })
												.then(this.seriesSeasonDataLoaded, this.requestFailed)
												.then(response => {
													this.fetchMasterTemplate(response.items, templateRendered);
												});
										}
										else {
											MetadataData.fetchPrograms({ parentProgramGuid: lastSeasonModel.guid, ...searchFilters })
												.then(this.seriesSeasonDataLoaded, this.requestFailed)
												.then(response => {
													this.fetchMasterTemplate(response.items, templateRendered);
												});
										}
									}
									else {
										// Clear seasons and episodes since we have a series without seasons
										this.noSeasonsAvailable();
									}


								}, this.requestFailed);

					}, this.requestFailed);



					break;
			}


		};
	}
	fetchMasterTemplate(items, templateRendered) {
		return (dispatch) => {
			if(items) {
				const { masterId: id = null  } = items.find(i => i.masterId) || {};
				const masterTemplateAvailable = items.find(i => i.isMasterTemplate);

				// Only fetch and render the template group if the episode list doesn't contain the master template
				if(id && !templateRendered && !masterTemplateAvailable) {
					dispatch(1);
					MetadataData.fetchProgram({ id }).then(this.masterTemplateDataLoaded, this.requestFailed);
				}
			}
			else {
				dispatch(0);
			}
		}
	}
	masterTemplateDataLoaded(payload) { return payload; }

	seriesSeasonDataLoaded(payload) { return payload; }
	setSelectedSeason(id) { return id; }
	noSeasonsAvailable() { return true; }

	fetchSeasonEpisodes(seasonId, searchFilters, templateRendered) {
		return (dispatch) => {
			dispatch(1);

			MetadataData.fetchProgram({ id: seasonId })
				.then(seasonModel => {
					const { guid } = seasonModel;

					// Episodes
					MetadataData.fetchPrograms({ parentProgramGuid: guid, ...searchFilters })
						.then(this.seriesSeasonDataLoaded, this.requestFailed)
						.then(response => {
							this.fetchMasterTemplate(response.items, templateRendered);
						});

				}, this.requestFailed);
		}
	}

	connectSeasonToSeries(seasonId, seriesData) {
		return (dispatch) => {
			dispatch({seasonId, seriesData});
			MetadataData.patchProgram({id: seasonId }, { parentId: seriesData.id })
				.then(response => {
					this.seriesConnectedToSeason();

					// Also fetch all seasons for the newly connected series
					MetadataData.fetchPrograms({ parentProgramGuid: seriesData.guid, orderBy: "title" })
						.then(seasons => {
							this.seriesSeasonDataLoaded(seasons);
						}, handleRequestFailed);

				}, handleRequestFailed);
		}
	}

	disconnectSeasonFromSeries(seasonId, catalogue) {
		return (dispatch) => {
			dispatch(seasonId);
			MetadataData.patchProgram({id: seasonId }, { parentId: null })
				.then(response => {
					this.seriesSeasonDataLoaded(getEmptySeries(seasonId));
					const cataloguePath = catalogue ? `${catalogue}/` : "";

					browserHistory.replace(`/metadata/programs/${cataloguePath}season/${seasonId}`)

				}, handleRequestFailed);
		}
	}

	seriesConnectedToSeason() {
		return true;
	}

	fetchTagPrograms({ id }) {
		return (dispatch) => {
			dispatch(true);

			const multiple = true;
			const entity = "tagPrograms";
			const store = getStore(entity, multiple);

			fetchTag({ id })
				.then(response => {
					this.itemLoaded({ entity: "tag", model: response });
					dispatch(false);
					return MetadataData.fetchPrograms({ tagReference: id, pageSize: 50 });
				}, this.requestFailed)
				.then(response => {
					const { pageIndex, numberOfItems, items, links, pageSize } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links, numberOfItems, pageIndex, pageSize);
					this.itemsUpdated(store, items, appendToExistingItems, numberOfItems, nextPageUrl);
				}, this.requestFailed);
		};
	}

	removeProgramFromTag(tagItemId, tagId, programId) {
		return dispatch => {
			dispatch();

			removeItemFromTag(tagId, tagItemId)
				.then(response => {
					this.removeTagProgram(programId);
				}, this.requestFailed);
		};
	}

	removeTagProgram(id) { return id }

	selectItem(dataStore, item) { return { dataStore, item }; }
	clearSelected(dataStore) { return dataStore; }


	fetchChannelGroups() {
		return dispatch => {
			dispatch();

			if (!appConfig.features?.metadataC70 && !appConfig.features?.metadataC80) {
				this.useChannelGroupsFallback();
				return;
			}

			MetadataData.fetchChannelGroups()
				.then(response => {
					const { pageIndex, numberOfItems, items, links } = response;
					const appendToExistingItems = pageIndex > 0;

					const nextPageUrl = getNextPageUrl(links);
					this.itemsUpdated("channelGroups", items, appendToExistingItems, numberOfItems, nextPageUrl);
				}, error => {
					// error.status 404 means the endpoint is not available, so we use the fallback list
					if (error.status === 404) {
						this.useChannelGroupsFallback();
					} else {
						this.requestFailed(error);
					}
				});
		}
	}

	useChannelGroupsFallback() { return true }
}

export default alt.createActions(Actions);

// Helpers
function getCommand(command, entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";

		if(entity.parentEntity) {
			return command + entity.parentEntity.substr(0, 1).toUpperCase() + entity.parentEntity.substr(1) + entity.entity.substr(0, 1).toUpperCase() + entity.entity.substr(1) + extra;
		}
		entity = `${entity.entity}${extra}`;
	}
	return command + entity.substr(0, 1).toUpperCase() + entity.substr(1);
}

function getStore(entity, multiple = false) {
	if(typeof(entity) === "object") {
		const extra = multiple ? "s" : "";
		return `${entity.entity}${extra}`;
	}
	return entity;
}

function getNextPageUrl(links = []) {
	const nextLink = links.find(l => l.rel === "next" || l.Rel === "next");

	return nextLink
		? nextLink.href || nextLink.Href
		: null;
}

function getEmptySeries(seasonId) {
	return {
		id: -100,
		type: "Series",
		seasonId,
	}
}