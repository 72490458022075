import React, { Component } from 'react'
import moment from 'moment'
import { browserHistory } from 'browserHistory'

import PressPage from './page'
import Translations from '../shared/translations'

import Actions from '../actions'
import * as PressAPI from '../../../apis/press'

import './program.css'

export default class SeasonProgram extends Component {
	seasonId = null
	seriesId = null

	UNSAFE_componentWillMount() {
		this.seasonId = this.props.params.seasonId;
		this.seriesId = this.props.params.seriesId;
		if (this.seriesId && !this.seasonId) {
			this.fetchSeriesAndThenSeason();
		} else if (this.seasonId) {
			this.fetchSeason();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.params.seasonId !== this.seasonId || this.props.params.seriesId !== this.seriesId) {
			this.seasonId = this.props.params.seasonId;
			this.seriesId = this.props.params.seriesId;
			if (this.seriesId && !this.seasonId) {
				this.fetchSeriesAndThenSeason();
			} else if (this.seasonId) {
				this.fetchSeason();
			}
		}
	}

	componentWillUnmount() {
		// HACK?: Clear season model when leaving page
		Actions.itemLoaded({ entity: "season", model: {} });
	}

	fetchSeason = () => {
		// Clear series model since item.series is only used when no season was found 
		Actions.itemLoaded({ entity: "series", model: {} });
		Actions.fetchItem(
			"season",
			{ id: this.seasonId },
			{ targetStore: "season", callback: Actions.fetchProgramReviews },
		);
		Actions.fetchItem(
			"programSchedules",
			{ id: this.seasonId },
			{ targetStore: "programSchedules" },
		);
	}

	fetchSeriesAndThenSeason = () => {
		PressAPI.fetchSeries({ id: this.seriesId })
			.then(response => {
				const seasons = response.series?.seasons ?? [];

				// Get the most recent active season exposure and use it to set season.activeFrom & season.activeUntil
				const seasonsWithDates = seasons.map(s => {
					const mostRecentActiveExposure = (s.exposures || []).sort((a, b) => moment(a.from).unix() - moment(b.from).unix()).pop();
					return {
						...s,
						activeFrom: mostRecentActiveExposure?.from,
						activeUntil: mostRecentActiveExposure?.until,
					};
				});

				// Take the most recent active season, if there are any active seasons. Otherwise, take the last season in the list
				const now = moment();
				const lookahead = moment().add(50, "days");
				const activeSeasons = seasonsWithDates
					.filter(s => moment(s.activeFrom).isBefore(lookahead) && moment(s.activeUntil).isAfter(now))
					.sort((a, b) => b.seasonNumber - a.seasonNumber) // First sort = lowest priority, this only matters if seasons have the same activeFron
					.sort((a, b) => moment(a.activeFrom).unix() - moment(b.activeFrom).unix()); // Last sort = highest priority
				const season = activeSeasons[activeSeasons.length - 1] ?? seasons[seasons.length - 1];

				// Navigate to seasonId
				if (season) {
					const url_series = Translations.getTranslation("url_series");
					const url_season = Translations.getTranslation("url_season");
					browserHistory.replace({ pathname: `/${url_series}/${this.seriesId}/${url_season}/${season.id}` });
				} else {
					Actions.itemLoaded({ entity: "season", model: {} });
					Actions.itemLoaded({ entity: "series", model: response });
				}
			});
	}

	render() {
		return <PressPage pageName="series-program" />;
	}
}