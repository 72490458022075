import React from 'react'

export function addChannel(channelsToAdd, channels) {
	const channelKeys = getChannelKeys(channelsToAdd);

	let channelFilter = channels.length ? channels.split(",") : [];
	channelFilter = channelFilter.concat(channelKeys).filter((c, i, a) => i === a.indexOf(c)); // Merge and dedupes the channel arrays
	return channelFilter.join(",");
}

export function removeChannel(channelsToRemove, channels) {
	const channelKeys = getChannelKeys(channelsToRemove);

	let channelFilter = channels.length ? channels.split(",") : [];
	channelFilter = channelFilter.filter(c => !channelKeys.includes(c));
	return channelFilter.join(",");
}

export function getBroadcastSeasonEpisodeInfo(seasonNumber, episodeNumber, classification, showSeasonEpisodeNumbersForSports = true) {
	return (seasonNumber || episodeNumber) && (classification !== "Sport" || classification === "Sport" && showSeasonEpisodeNumbersForSports)
		? `${seasonNumber ? ` S${seasonNumber}` : ""}${episodeNumber ? `\u00A0E${episodeNumber}` : ""}`
		: null;
}

// HELPERS
const getChannelKeys = channels => typeof(channels) === "object" ? channels.map(c => c.key) : [channels];