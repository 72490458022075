import React from 'react'

import Spinner from '../../../components/spinner'

import './textField.css'

const TextField = ({ type, label, value, multiline, required, onChange, isLoading, ...rest }) => {

	const handleChange = e => {
		onChange(e.target.value);
	};

	return (
		<div className={`c6-press-textfield ${isLoading ? "is-loading" : ""}`}>
			<label>{label}{required ? "*" : ""}</label>
			{multiline
				? <textarea value={value} required={required} onChange={handleChange} {...rest} onFocus={setFocus} onBlur={removeFocus} />
				: <input type={type || "text"} value={value} required={required} onChange={handleChange} {...rest} onFocus={setFocus} onBlur={removeFocus} />
			}
			<Spinner loading={isLoading} />
		</div>
	);
};

export default TextField;

function setFocus(e) {
	e.target.parentNode.classList.add("focus");
}

function removeFocus(e) {
	e.target.parentNode.classList.remove("focus");
}