import React from 'react'
import moment from 'moment'
import { withRouter } from 'react-router'
import debounce from 'lodash/debounce'
import { browserHistory } from 'browserHistory'

import Const from '../../../core/constants'
import Translations from '../shared/translations'

import Header from './schedulesHeader'
import Schedule from '../../internal-schedule/schedule/schedule'
import Broadcast from '../../internal-schedule/schedule/broadcast'

import Actions from '../actions'
import Store from '../store'
import { getConstrainedDate, getProgramLink } from '../shared/utils'

import OriginalStyles from '../../internal-schedule/schedule/app.css'
import ChannelStyles from '../../internal-schedule/shared/channels.css'
import './schedules.css'

const LS_KEY = "press-schedule-selectedchannels";
const DATASTORE = "schedules";
const ENTITY = "program";

@withRouter
export default class PressSchedule extends React.Component {

	constructor(props) {
		super(props);

		this.debouncedFilter = debounce(this.handleFilter, 500);
		this.debouncedFetch = debounce(this.onFetchSchedule, 300);
		this.scroll = null;
		this.refreshTimerId = null;
		this.channels = [];
	}

	componentDidMount() {
		this.channels = getChannels(this.props.list.channelGroups.items, fallbackChannels, this.props.useChannelGroupsFallback);
		this.setDate(this.props.params.date);
		this.onFetchSchedule();
	}

	componentDidUpdate(prevProps) {
		const newDate = this.props.params.date || moment().format(Const.DATE_FORMAT);
		const newChannels = getChannels(this.props.list.channelGroups.items, fallbackChannels, this.props.useChannelGroupsFallback);
		if (newDate !== this.date || newChannels.length !== this.channels.length) {
			this.channels = newChannels;
			this.setDate(this.props.params.date);
			this.debouncedFetch();
		}
	}

	setDate = (newDate) => {
		this.date = getConstrainedDate(newDate || moment()).format(Const.DATE_FORMAT);
		browserHistory.replace(`/${Translations.getTranslation("url_schedules")}/${this.date}`);
	}

	onFetchSchedule = () => {
		Actions.fetchSchedules.defer({
			date: this.date,
			channels: this.channels,
		});
	}

	onFilter = (type, e) => {
		if(type === "search") {
    		e.persist();
    		this.debouncedFilter(type, e);
		}
		else {
			this.handleFilter(type, e);
		}
  	}

	handleFilter = (type, event) => {
		if(this.scroll) {
			this.scroll.scrollTop = 0; // Scroll list to top to prevent automatic paging
		}

		const { name, value } = event.target;
		Actions.scheduleFilter(value, name);
	}

	shouldUpdateScroll(prevRouterProps, currentRouterProps) {
		return currentRouterProps.location.action === "POP";
	}

	render() {
		const { list, useChannelGroupsFallback } = this.props;
		const schedules = list.schedules;
		const { _channelGroup } = schedules.filters;

		const channelGroups = getChannelGroups(list.channelGroups.items, useChannelGroupsFallback);
		const channelGroup = _channelGroup//!_channelGroup.length && channelGroups?.length ? channelGroups[0].key : _channelGroup;

		const date = this.date && moment(this.date) || null;
		const filteredSchedules = getSchedules(schedules.items, this.channels, _channelGroup);
		const noBroadcastsText = `${Translations.getTranslation("noBroadcasts")}.`;

		return (
			<div className="c6-internal-schedule">
				<Header
					date={date?.format(Const.DATE_FORMAT)}
					channelGroup={channelGroup}
					channelGroups={channelGroups}
					useChannelGroupsFallback={useChannelGroupsFallback}
					handleFilter={this.handleFilter}
				/>
				<Schedule
					schedules={filteredSchedules}
					channels={this.channels}
					noBroadcastsText={noBroadcastsText}
					day={date && date.format(Const.DATE_FORMAT)}
				>
					<Broadcast
						showSeasonEpisodeNumbersForSports={false}
						programTitleMissingText={Translations.getTranslation("programTitleMissing")}
						getProgramLink={getProgramLink}
						fixedUtcOffset={moment(date).hours(4).isDST() ? 3 : 2} // DST ends on 2019-10-27 03:00 so we need to add 4 hours or we will get a false negative
						disableTooltip={true}
					/>
				</Schedule>
			</div>
		);
	}
}

// HELPERS
function getSearchPayload({ filters = {} }, day, channels) {
	const { filter, ...rest } = filters;
	const payload = {
		...rest,
		...filter,
	};

	if (day) payload.day = day;
	if (channels) payload.channel = channels;

	return payload;
}

function getSchedules(items, channels, channelGroup) {
	if (!channelGroup || channelGroup === "") {
		return items;
	}

	const channelsInGroup = channels.filter(c => c.group === channelGroup).map(c => c.key);
	return items.filter(i => i.channel && channelsInGroup.includes(i.channel.name));
}

function getChannels(channelGroups = [], fallbackChannels, useChannelGroupsFallback) {
	if (!useChannelGroupsFallback) {
		return channelGroups.reduce((channels, currentGroup) => {
			const currentGroupChannels = currentGroup.channels?.map(c => ({ group: currentGroup.name, key: c.name, text: c.displayName })) ?? [];
			return [...channels, ...currentGroupChannels];
		}, []);
	}

	return fallbackChannels;
}

function getChannelGroups(channelGroups = [], useChannelGroupsFallback) {
	if (!useChannelGroupsFallback) {
		return channelGroups.map(cg => ({ key: cg.name, text: cg.displayName }));
	}

	return null;
}

const fallbackChannels = [
	{ group: "mtv", key: "mtv3-fi", text: "MTV3" },
	{ group: "mtv", key: "sub-fi", text: "Sub" },
	{ group: "mtv", key: "ava-fi", text: "AVA" },
	{ group: "cmore", key: "cmore-first-fi", text: "C More First" },
	{ group: "cmore", key: "cmore-series-fi", text: "C More Series" },
	{ group: "cmore", key: "cmore-hits-fi", text: "C More Hits" },
	{ group: "cmore", key: "cmore-stars-fi", text: "C More Stars" },
	{ group: "cmore", key: "sf-kanalen-fi", text: "SF-kanalen" },
	{ group: "cmore", key: "cmore-juniori-fi", text: "C More Juniori" },
	{ group: "cmore", key: "cmore-max-fi", text: "C More Max" },
	{ group: "cmore", key: "cmore-sport1-fi", text: "C More Sport 1" },
	{ group: "cmore", key: "cmore-sport2-fi", text: "C More Sport 2" },
];