import React from 'react'
import TextField from '@mui/material/TextField'

import { getCharacterCountState, getCharacterCountStyles, sanitizeTextInput } from './utils'

const MuiTextArea = (props) => {

	const {
		value,
		required,
		placeholder = '',
		label,
		schema,
		options = {},
		readonly = false,
		onChange,
		formContext = {},
		id,
	} = props;

	const {
		maxLength: length,
		recommendedLength,
		deviation,
		floatingLabelFixed,
		liveUpdate = false,
	} = options;

	const [charactersState, setCharactersState] = React.useState(value?.length ?? 0);
	const [focus, setFocus] = React.useState(false);
	const ref = React.useRef(null);

	React.useEffect(
		() => {
			if (ref.current) {
				ref.current.value = value ?? "";
				setCharactersState(ref.current.value?.length ?? 0);
			}
		},
		[value]
	);

	// Update the editor model on blur event
	const commitChange = React.useCallback(
		(e) => {
			if (e.which == 13 || e.keyCode == 13) {
				e.stopPropagation();
			}

			else if (e.type === "blur") {
				e.preventDefault();
				e.target.blur();

				// Only update model if value actually has changed
				const newValue = sanitizeTextInput(String(e.target.value ?? ""));
				const oldValue = sanitizeTextInput(String(value ?? ""));
				if (newValue !== oldValue) {
					onChange(newValue, e);
				}
			}
		},
		[onChange, value]
	);

	const handleTextChange = React.useCallback(
		(e) => {
			const text = sanitizeTextInput(e.target.value);
			setCharactersState(text.length);
			if (liveUpdate) {
				onChange(text, e);
			} else if (formContext.editorActions?.setDirty) {
				formContext.editorActions.setDirty();
			}
		},
		[onChange]
	);

	const maxLength = length || schema && schema.maxLength || null; // maxLength can either come from the ui schema or the json-schema
	const characters = liveUpdate ? (value ?? "").length : charactersState;
	const { recommended, error } = getCharacterCountState(characters, recommendedLength, deviation, maxLength);

	const labelText = `${label}${required ? " *" : ""}`

	const style = {
		position: "relative",
		width: "calc(100% - 40px)",
		clear: "both",
		overflow: "hidden",
		paddingTop: "15px"
	};

	const { ccStyle } = getCharacterCountStyles(recommended, error, readonly);

	const valueProps = liveUpdate
		? { value: value ?? "" }
		: { defaultValue: value ?? "" };

	let color = "primary";
	if (error) {
		color = "error";
	} else if (recommended) {
		color = "success";
	}

	return (
		<div style={style}>
			<TextField
				id={id}
				inputRef={ref}
				variant="standard"
				className="c6-mui"
				name="muiTextArea"
				color={color}
				multiline
				label={labelText}
				placeholder={placeholder}
				focused={error ? true : undefined}
				onChange={handleTextChange}
				onFocus={() => setFocus(true)}
				onBlur={(e) => {
					setFocus(false);
					commitChange(e);
				}}
				onKeyDown={commitChange}
				style={{ width: '100%' }}
				inputProps={{
					readOnly: Boolean(readonly),
					"data-1p-ignore": true, // tell 1Password it should ignore the field
					"data-lpignore": true, // tell LastPass it should ignore the field
				}}
				InputLabelProps={floatingLabelFixed || value?.length ? { shrink: true } : {}}
				{...valueProps}
			/>
			{(maxLength) && (
				<span style={{ ...ccStyle,	visibility: error || focus ? "visible" : "hidden" }}>
					{characters} / {maxLength}
				</span>
			)}
		</div>
	);
};

export default MuiTextArea;