import React, { Component } from 'react'
import { withRouter } from 'react-router'
import moment from 'moment'

import Button from '../shared/button'
import TextField from '../shared/textField'
import Link from '../shared/link'
import Translations from '../shared/translations'

import AuthActions from '../../../core/authentication/actions'
import UserStore from '../../../core/authentication/store'
import * as PressAPI from '../../../apis/press'

import './loginForm.css'

const MAX_TIMER = 3600 * 48; // 48h is the maximum for new users (so don't add a new user on a friday)

@withRouter
export default class PressResetPasswordForm extends Component {
	constructor(props) {
		super(props);

		this.resetKey = props.params.guid;
		this.timerID = null;

		this.state = {
			timer: MAX_TIMER,
			password: "",
			confirmPassword: "",
			loginErrorMessage: "",
			passwordErrorMessage: "",
			...UserStore.getState(),
		}
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
		AuthActions.display.defer();
		AuthActions.fetchResetState.defer(this.resetKey, PressAPI);
		this.startCountdown();
	}

	componentWillUnmount() {
		// AuthActions.unmount();
		clearInterval(this.timerID);
		UserStore.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { password, confirmPassword, isLoading } = this.state;

		if (isLoading) {
			return false;
		}

		if (password && confirmPassword && password === confirmPassword) {
			AuthActions.resetPassword({
				resetKey: this.resetKey,
				password,
				confirmPassword,
				api: PressAPI,
				successMessage: Translations.getTranslation("passwordResetSuccess"),
			});
		} else {
			if (password && confirmPassword && password !== confirmPassword) {
				AuthActions.loginFailed({ exceptionMessage: Translations.getTranslation("passwordsDontMatch") });
			} else {
				AuthActions.loginFailed({ exceptionMessage: Translations.getTranslation("typePasswordTwice") });
			}


			if (!password) {
				const password1ErrorMessage = Translations.getTranslation("typeNewPassword");
				this.setState({ password1ErrorMessage });
			}
			if (!confirmPassword) {
				const password2ErrorMessage = Translations.getTranslation("retypePassword");
				this.setState({ password2ErrorMessage });
			}
		}
	}

	handleNewLink = (e) => {
		e.preventDefault();
		const { email } = this.state.resetState;
		AuthActions.forgotPassword(email, PressAPI);
	}

	handlePass1Change = (e, value) => {
		this.setState({
			password: value,
			password1ErrorMessage: "",
		});
	}

	handlePass2Change = (e, value) => {
		this.setState({
			confirmPassword: value,
			password2ErrorMessage: "",
		});
	}

	startCountdown = () => {
		this.timerID = setInterval(() => {
			const { resetState } = this.state;
			if (resetState.expires) {
				const timer = moment(resetState.expires).diff(moment(), "seconds");
				this.setState({ timer });
			}
		}, 1000);
	}

	handleInputKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	}

	render() {
		const {
			timer,
			password,
			confirmPassword,
			// loginErrorMessage,
			password1ErrorMessage,
			password2ErrorMessage,
			error,
			errorMessage,
			resetState,
			isLoading,
		} = this.state;

		if (timer === MAX_TIMER) {
			return null;
		}

		if (timer > 0) {
			return (
				<div className="c6-press-login">
					<form onSubmit={this.handleSubmit}>
						<h1>{ error ? errorMessage : null }</h1>
						<p>{Translations.getTranslation("linkExpires")} {moment().add(timer, "seconds").fromNow()}</p>
						<TextField
							label={Translations.getTranslation("newPassword")}
							type="password"
							value={password}
							onChange={value => this.setState({ password: value, password1ErrorMessage: "" })}
							disabled={isLoading}
							// errorText={loginErrorMessage}
							onKeyPress={this.handleInputKeyPress}
							maxLength={300}
						/>
						<TextField
							label={Translations.getTranslation("confirmNewPassword")}
							type="password"
							value={confirmPassword}
							onChange={value => this.setState({ confirmPassword: value, password2ErrorMessage: "" })}
							disabled={isLoading}
							// errorText={loginErrorMessage}
							onKeyPress={this.handleInputKeyPress}
							maxLength={300}
						/>
						<Button
							label={Translations.getTranslation("save")}
							type="submit"
							disabled={isLoading}
							isLoading={isLoading}
						/>
					</form>
				</div>
			);
		}

		return (
			<div className="c6-press-login">
				<form onSubmit={this.handleNewLink}>
					<h1>{Translations.getTranslation("linkExpired")}</h1>
					<p>{Translations.getTranslation("passwordResetLinkInfo")}</p>
					<p>{Translations.getTranslation("itWillBeSentTo")} <strong>{resetState.email}</strong>.</p>
					<Button label={Translations.getTranslation("requestNewLink")} onClick={this.handleNewLink} />
				</form>
			</div>
		);
	}
}