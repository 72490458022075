import React from 'react'

import Widget from './widget'

import './faq.css'

class FAQ extends React.Component {
	state = {
		openedItems: []
	}

	toggleOpen = id => {
		const newList = [...this.state.openedItems];
		const index = newList.indexOf(id);
		if (index >= 0)
			newList.splice(index, 1);
		else
			newList.push(id);

		this.setState({ openedItems: newList });
	}

	render() {
		const { widgetData = {} } = this.props;
		const { body, items = [] } = widgetData;
		return (
			<div className="c6-press-faq">
				<div className="body" dangerouslySetInnerHTML={{ __html: body }}></div>
				{items.map(({ id, heading, description }) => {
					const isOpen = this.state.openedItems.includes(id);
					return (
						<div key={id} className={`c6-press-faq-item ${isOpen ? "open" : "closed"}`}>
							<h1 onClick={this.toggleOpen.bind(this, id)} className={isOpen ? "icon-expand_less" : "icon-expand_more"}>{heading}</h1>
							<div dangerouslySetInnerHTML={{ __html: description }}></div>
						</div>
					);
				})}
			</div>
		);
	}
}

export default Widget(FAQ);