import * as Alert from '../core/services/alert'

export const formatBytes = (bytes, decimals) => {
	if (bytes === 0) return "0 Byte";
	const k = 1024; // 1024 for binary
	const dm = decimals + 1 || 3;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export const isEmpty = obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;

export const copyTextToClipboard = (textToCopy, alertText, e) => {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}

	navigator.clipboard.writeText(textToCopy).then(() => {
		if (alertText) {
			Alert.displayAlert("info", alertText);
		}
	});
};

export const copyHtmlToClipboard = (htmlToCopy, alertText, e) => {
	if (e) {
		e.preventDefault();
		e.stopPropagation();
	}

	const data = [new ClipboardItem({ "text/html": new Blob([htmlToCopy], { type: "text/html" }) })];
	navigator.clipboard.write(data).then(() => {
		if (alertText) {
			Alert.displayAlert("info", alertText);
		}
	});
};

export const classNames = (...input) => {
	let classes = "";
	input.forEach((item) => {
		if (typeof item === "string") {
			classes += ` ${item}`;
		} else if (Array.isArray(item)) {
			classes += ` ${classNames(...item)}`;
		} else if (typeof item === "object") {
			Object.keys(item).forEach((key) => {
				if (item[key]) {
					classes += ` ${key}`;
				}
			});
		}
	});
	return classes.trim();
}