import React from 'react'
import { Filter, Left, Right } from '../../../components/filter'
import Switch from '../../../components/ui/controls/switch'
import Dropdown from '../../../components/ui/controls/dropdown'
import Search from '../../../components/ui/controls/search'
import {
	getApproval,
	getRight,
	getClass,
	getPremiere,
	getTypes,
	getTranslationStatuses,
	getVersions,
	getVersionApprovals,
	getCatalogues,
	getRightState
} from '../shared/utils'
import { useLocalStorage } from '../../../core/hooks/useStorage'

import appConfig from 'config'

export default ({
	title,
	searchText,
	filters,
	unselectedFilters,
	handleFilter,
	isTranslationList,
	translationLanguage,
	catalogue,
	rightVersion,
}) => {

	const storageKey = isTranslationList ? "filters-collapse-metadata-library-translation" : "filters-collapse-metadata-library";
	const [collapseFilters, setCollapseFilters] = useLocalStorage(storageKey, true);
	const isVodubProgramListFilter = appConfig.features.metadataVodHubProgramListFilter; // We want VodHub to have different filters for now
	return (
		<Filter>
			<Left>
				<h1>{title}</h1>
				<Search
					onChange={handleFilter.bind(this, "search")}
					placeholder="Search"
					searchText={searchText}
				/>
			</Left>
			<Right>
				{!isTranslationList && catalogue === "cmore" && !rightVersion && (
					<Dropdown
						name="rightVersion"
						title="Region"
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getVersions()}
						currentState={filters.filter.rightVersion}
					/>
				)}
				{!(isTranslationList && catalogue === "mtv") && (
					<Switch
						name="_type"
						title="Type"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getTypes()}
						currentState={filters.filter._type}
					/>
				)}
				{!collapseFilters && (
					<Dropdown
						name="premiere"
						title="Premiere"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getPremiere()}
						currentState={filters.filter.premiere}
					/>
				)}
				{!collapseFilters && !isVodubProgramListFilter && (
					<Switch
						name="rightClass"
						title="Class"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getClass()}
						currentState={filters.filter.rightClass}
					/>
				)}
				{!collapseFilters && (isTranslationList && !collapseFilters) || catalogue === "all" && (
					<Dropdown
						name="catalogue"
						title="Catalogue"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getCatalogues(isTranslationList ? translationLanguage : undefined)}
						currentState={filters.filter.catalogue}
					/>
				)}
				{!collapseFilters && !isVodubProgramListFilter && (
					<Switch
						name="right"
						title="Right"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getRight()}
						currentState={filters.filter.right}
					/>
				)}
				{!collapseFilters && appConfig.features.metadataC80 && (
					<Switch
						name="planningState"
						title="Right State"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getRightState()}
						currentState={filters.filter.planningState}
					/>
				)}
				{!isTranslationList && renderApprovalSwitch(unselectedFilters, handleFilter, filters, catalogue)}
				{isTranslationList && (
					<Switch
						name="_translationStatus"
						title="Status"
						unselected={unselectedFilters}
						disabled={unselectedFilters}
						onChange={handleFilter.bind(this, "filter")}
						states={getTranslationStatuses()}
						currentState={filters.filter._translationStatus}
					/>
				)}
				{collapseFilters && !(appConfig.features.metadataTranslationNewFilters && isTranslationList) && <a className="c6-link icon-chevron_right hide-filters" onClick={() => setCollapseFilters(false)}>More</a>}
				{!collapseFilters && <a className="c6-link icon-chevron_left hide-filters" onClick={() => setCollapseFilters(true)}>Less</a>}
			</Right>
		</Filter>
	);
}

function renderApprovalSwitch(unselectedFilters, handleFilter, filters, catalogue) {
	if (appConfig.features.metadataVodHubProgramListFilter) {
		return (
			<Switch
				name="approval"
				title="Approval"
				unselected={unselectedFilters}
				disabled={unselectedFilters}
				onChange={handleFilter.bind(this, "filter")}
				states={getApproval(undefined, catalogue)}
				currentState={filters.filter.approval}
			>
				<Switch
					whenParentState="version"
					name="versionApproval"
					unselected={unselectedFilters}
					disabled={unselectedFilters}
					onChange={handleFilter.bind(this, "filter")}
					states={getVersionApprovals()}
					currentState={filters.filter.versionApproval} 
				/>
			</Switch>
		);
	}

	return (
		<Switch
			name="approval"
			title="Approval"
			unselected={unselectedFilters}
			disabled={unselectedFilters}
			onChange={handleFilter.bind(this, "filter")}
			states={getApproval(undefined, catalogue)}
			currentState={filters.filter.approval}
		/>
	);
}