import * as PressAPI from '../../../apis/press'
import { useQuery } from '@tanstack/react-query'

const usePressProgram = (programId: string | number) => {
    return useQuery(
        ["press", "programs", { id: programId }],
        () => PressAPI.fetchSingle({ id: programId }),
        { enabled: programId !== null && programId !== undefined }
    );
};

export default usePressProgram;