import React from 'react'
import PropTypes from 'prop-types'
import { withRouter, Link } from 'react-router'

import ResponsiveImage from '../assets/responsiveImage'

import { trackAdobeAnalyticsImageDownload } from '../../core/services/tracking'
import { hasRole } from '../../core/services/auth'

import './pressImages.css'

import appConfig from 'config'

const DOWNLOAD_FORMAT = "Original";

@withRouter
export default class PressImages extends React.PureComponent {

	static propTypes = {
		main: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired, // From @withRouter
		episode: PropTypes.object,
		series: PropTypes.object,
		onClick: PropTypes.func,
		texts: PropTypes.object,
	}

	render() {
		const { episode, main, series, location, onClick, allowDownload, allowPreview, texts = {}, provider } = this.props;

		return (
			<div className="section press-image-area" data-nopressimages={texts["noPressImages"] || "Inga pressbilder tillgängliga."}>
				{getPressImages(episode, location, onClick, allowDownload, allowPreview, texts, provider)}
				{getPressImages(main, location, onClick, allowDownload, allowPreview, texts, provider)}
				{getPressImages(series, location, onClick, allowDownload, allowPreview, texts, provider)}
			</div>
		)
	}
}

// HELPERS

function getPressImages(program, location, onClick, allowDownload, allowPreview, texts, provider) {
	const assets = program?.assets?.filter(userHasAccessToAsset) ?? [];
	const pressImages = assets.filter(a => a.category === "press" && a.type === "image");
	const pressDocuments = assets.filter(a => a.category === "press" && a.type === "document").map(a => ({ ...a, name: getDocumentName(a) }));
	const pressClips = assets.filter(a => a.category === "press" && a.type === "clip");

	if (pressImages.length || pressDocuments.length) {
		const heading = getPressImageHeading(program.type, texts);
		const images = [...pressClips, ...pressImages, ...pressDocuments].map((a, i) => (
			<Link
				key={a.id}
				to={(a.type === "clip" || a.type === "image") && allowPreview ? generateLinkAction(a.id, location) : null}
				onClick={(a.type === "clip" || a.type === "image") && onClick ? e => onClick(e, a) : null}
				className={getAssetClassName(a, allowPreview, allowDownload)}
				title={!allowPreview ? texts["previewNotAllowed"] || "You need to log in to preview & download images." : ""}
			>
				<ResponsiveImage
					id={a.id}
					format="freeheight"
					sizes="(min-width: 1901px) 33.33vw, (min-width: 1200px) 50vw, (min-width: 786px) 25vw, (min-width: 666px) 33.33vw, 50vw"
					extension={["png", "tif"].includes(a.ext?.toLowerCase()) ? "png" : "jpg"}
				/>
				{a.type !== "clip" && allowDownload && <button onClick={handleClick.bind(this, a, program, i)} className="icon-cloud_download"><span>Download</span></button>}
				{a.type !== "clip" && !allowDownload && texts["downloadNotAllowed"] ? <div className="download-not-allowed">{texts["downloadNotAllowed"]}</div> : null}
				{(a.type === "clip" || a.type === "document") && <div className="asset-name">{a.name ?? a.id}</div>}
				{provider === "Hayu" && <span className="channel-logo channel-hayu" />}
			</Link>
		));

		return (
			<div>
				{heading}
				<section className="press-images">
					{images}
				</section>
			</div>
		);
	}

	return null;
}

function getPressImageHeading(type, texts) {
	switch (type) {
		case "season":
			return <h2>{texts["seasonImages"] || "Säsongsbilder"}</h2>;
		case "episode":
			return <h2>{texts["episodeImages"] || "Episodbilder"}</h2>;
		case "series":
			return <h2>{texts["seriesImages"] || "Seriebilder"}</h2>;
		case "single":
		default:
			return texts["downloadableMaterials"] ? <h2>{texts["downloadableMaterials"]}</h2> : null;
	}
}

function generateFileName(program, index, extension) {
	const sanitizedTitle = (program.originalTitle || program.title || program.name || program.id)
		.replace(/ /g, "_")
		.replace(/&/g, "");
	return `${sanitizedTitle}-${index + 1}.${extension}`;
}

function generateLinkAction(id, location) {
	return {
		pathname: `/image/${id}`,
		state: {
			modal: true,
			wideModal: true,
			returnTo: `${location.pathname}${location.search}`,
		}
	};
}

function handleClick(asset, program, index, e) {
	e.preventDefault();
	e.stopPropagation();

	if (asset.type === "document") {
		trackAdobeAnalyticsImageDownload(asset.name);
		document.location.href = `${appConfig.api.pressBlobStorage}${asset.path}`;
		return;
	}

	const extension = asset.ext ?? "jpg";
	const filename = generateFileName(program, index, extension);
	trackAdobeAnalyticsImageDownload(filename);
	document.location.href = `${appConfig.media.star}${asset.id}/${DOWNLOAD_FORMAT}.${extension}?download=true&fileName=${filename}`;
}

function getDocumentName(asset) {
	return asset.path.split("/").pop();
}

function getAssetClassName(asset, allowPreview, allowDownload) {
	let className = `${asset.type}`;

	if (asset.type === "document")
		className += " icon-description";
	if (asset.type === "clip")
		className += " icon-play_circle_outline";
	
	if (allowPreview)
		className += " preview-allowed";
	if (allowPreview && asset.type === "image")
		className += " icon-search";
	
	if (allowDownload && asset.type !== "clip")
		className += " download-allowed";

	return className;
}

function userHasAccessToAsset(asset) {
	if (!asset?.role?.length) return true;

	console.log("asset.role", asset.role);
	return hasRole(`star.${asset.role}`);
}