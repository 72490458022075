import { MENU } from '../../../components/actions'

import Actions from '../actions'

export default {
	targetComponent: "Content",
	actions: [
		{
			title: "Hide",
			icon: "visibility_off",
			method: (data, sourceProps, location) => {
				Actions.updateItem("program", { id: data.id }, { programStatus: "hidden" }, "patch");
			},
			hidden: (data, sourceProps) => {
				return !sourceProps.isMetadataAdmin; 
			},
			type: MENU,
			confirm: true,
			dangerous: true,
		},
	]
};