import React, { Component } from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import Button from '../shared/button'
import TextField from '../shared/textField'
import Link from '../shared/link'

import Translations from '../shared/translations'

import AuthActions from '../../../core/authentication/actions'
import UserStore from '../../../core/authentication/store'
import * as PressAPI from '../../../apis/press'

import './loginForm.css'

@withRouter
export default class PressForgotPasswordForm extends Component {
	state = {
		email: "",
		...UserStore.getState(),
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
		AuthActions.display.defer();
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);
	}

	onChange = (state) => {
		this.setState(state);
	}

	handleSubmit = (e) => {
		e.preventDefault();
		const { router, location, modal = false } = this.props;
		const { email, isLoading } = this.state;

		if (isLoading) {
			return false;
		}

		if (email) {
			const successMessage = Translations.getTranslation("forgotPasswordSuccess");
			AuthActions.forgotPassword(email, PressAPI, successMessage);
		} else {
			AuthActions.actionFailed({ exceptionMessage: "Please type your email address!" });
		}
	}

	handleLoginNav = (e) => {
		// e.preventDefault();
		AuthActions.display();
	}

	handleInputKeyPress = (e) => {
		if (e.key === "Enter") {
			this.handleSubmit(e);
		}
	}

	render() {
		const { email, error, errorMessage, loggedIn, isLoading } = this.state;

		const translatedErrorMessage = translateErrorMessage(errorMessage, email);

		return (
			<div className="c6-press-login">
				<form onSubmit={this.handleSubmit}>
					<h1>{ error ? translatedErrorMessage : null }</h1>
					<TextField
						type="email"
						label={Translations.getTranslation("email")}
						value={email}
						onChange={value => this.setState({ email: value, error: false, errorMessage: "" })}
						onKeyPress={this.handleInputKeyPress}
						maxLength={300}
					/>
					<Button label={Translations.getTranslation("submit")} onClick={this.handleSubmit} isLoading={isLoading} disabled={isLoading} />
					<div className="links">
						<Link to={`/${Translations.getTranslation("url_login")}`} onClick={this.handleLoginNav}>{Translations.getTranslation("logIn")}</Link>
					</div>
				</form>
			</div>
		);
	}
}

function translateErrorMessage(errorMessage, email) {
	if (errorMessage.toLowerCase().includes("could not find login")) {
		const template = Translations.getTranslation("couldNotFindLogin");
		return template.replace("{email}", email);
	}
	return errorMessage;
}