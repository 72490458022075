import React from 'react'
import { useQuery } from '@tanstack/react-query'

import { fetchWidget } from '../../../apis/press'

const Widget = (WrappedComponent) => {
	const WidgetComponent = (props) => {
		const { widgetData, widgetLoaded, widgetLoadFailed } = useWidget(props.name);

		return (
			<WrappedComponent
				widgetData={widgetData}
				widgetLoaded={widgetLoaded}
				widgetLoadFailed={widgetLoadFailed}
				{...props}
			/>
		);
	};

	return WidgetComponent;
};

export default Widget;

function useWidget(name) {
	const { data, isError, isLoading } = useQuery(
		["c6-press-widget", name],
		() => fetchWidget({ widgetName: name }),
	);

	return {
		widgetData: data,
		widgetLoaded: !isLoading,
		widgetLoadFailed: isError,
	};
}