import React from 'react'
import PropTypes from 'prop-types'
import isEqual from 'lodash/isEqual'

import Cropper from '../../../components/crop/index'
import Image from '../../../components/image'

import Actions from './actions'

import appConfig from 'config'

export default class AssetCropper extends React.Component {

    static propTypes = {
        asset: PropTypes.object,
        activeCrop: PropTypes.object,
        orgFile: PropTypes.object,
        forceRefreshImage: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    constructor(props) {
		super(props);

        this.timestamp = Date.now();
        this.cropImageWrapperRef = null;
    }

    shouldComponentUpdate(nextProps) {
		const { asset, forceRefreshImage, activeCrop, transformations } = this.props;
        return (
			!isEqual(nextProps.asset, asset)
			|| nextProps.forceRefreshImage !== forceRefreshImage
			|| nextProps.activeCrop !== activeCrop
			|| !isEqual(nextProps.transformations, transformations)
		);
    }

    UNSAFE_componentWillUpdate(nextProps) {
        if (nextProps.forceRefreshImage !== this.props.forceRefreshImage) {
            this.timestamp = Date.now();
        }

        if (nextProps.activeCrop?.id !== this.props.activeCrop?.id) {
            this.maximizeCropperSize();
        }
    }

    maximizeCropperSize = () => {
        if (!appConfig.features.starMaximizeCropperSize) {
            return;
        }

        const imgEl = this.cropImageWrapperRef?.querySelector(".ReactCrop, .c6-img > img");
        const cropRect = imgEl?.getBoundingClientRect();
        const imageWrapperRect = this.cropImageWrapperRef?.getBoundingClientRect();
        const widthPercentage = cropRect.width / imageWrapperRect.width;
        const heightPercentage = cropRect.height / imageWrapperRect.height;
        const minPercentage = 0.7;
        const maxScale = 4;
        if (widthPercentage > heightPercentage && widthPercentage < minPercentage) {
            const newScale = Math.min((imageWrapperRect.width - 200) / cropRect.width, maxScale);
            setElScale(imgEl, newScale);
        } else if (heightPercentage > widthPercentage && heightPercentage < minPercentage) {
            const newScale = Math.min((imageWrapperRect.height - 200) / cropRect.height, maxScale);
            setElScale(imgEl, newScale);
        }
    }

    onCompleteCrop = cropData => {
        const { activeCrop } = this.props;

        if (activeCrop.cropData) {

			const newModifiedCrop = {
				...activeCrop,
				cropData: {
					...activeCrop.cropData,
					...cropData,
					offsetX: cropData.x,
					offsetY: cropData.y,
					width: cropData.width,
					height: cropData.height,
				},
			};

			Actions.setCrops(newModifiedCrop);
		}
    }

    render() {
        const { asset, activeCrop, orgFile, forceRefreshImage, disabled, transformations } = this.props;
		const extension = asset.assetData && asset.assetData.type.toLowerCase() === "png" ? "png" : "jpg";

        const refreshImageParam = forceRefreshImage ? `&forceRefresh=true` : "";
        const format = "Star_None_x600_notransform";
        const imageLinkHref = asset.links.find(link => link.rel === 'image').href;
        const imageSource = `${imageLinkHref}/${format}.${extension}?timestamp=${this.timestamp}${refreshImageParam}`;

        let content;
        if (activeCrop.id) {
            const swapWidthHeight = asset.rotation % 180 !== 0;
            const width = swapWidthHeight ? orgFile.height : orgFile.width;
            const height = swapWidthHeight ? orgFile.width : orgFile.height;
            content = (
                <Cropper
                    src={imageSource}
                    crop={getCrop(activeCrop)}
                    originalWidth={width}
                    originalHeight={height}
                    minWidth={activeCrop.minWidth}
                    minHeight={activeCrop.minHeight}
                    onComplete={this.onCompleteCrop}
                    disabled={disabled}
                    cropId={activeCrop.id}
                    aspect={activeCrop.xRatio / activeCrop.yRatio}
                    onImageLoad={() => this.maximizeCropperSize()}
                />
            );
        }
        else {
            content = (
                <Image
                    src={imageSource}
                    alt={asset.name}
					onLoad={() => {
                        this.maximizeCropperSize();
                        if (forceRefreshImage) {
                            Actions.imageLoaded.defer();
                        }
                    }}
					assetTransformations={transformations}
                    hideImageWhileSpinnerIsShown={true}
                />
            );
        }

        return (
            <div
                className="image-wrapper"
                ref={ref => this.cropImageWrapperRef = ref}
            >
                {content}
            </div>
        );
    }
}

function getCrop({ cropData }) {
	return {
		x: cropData.offsetX,
		y: cropData.offsetY,
		width: cropData.width,
		height: cropData.height,
	}
}

function setElScale(el, newScale) {
    const oldTransformWithoutScale = (el.style.transform || "").replace(/scale\([^\)]+\)/, "");
    el.style.transform = `${oldTransformWithoutScale} scale(${newScale})`;
}
