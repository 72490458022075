import React from 'react'

import Widget from './widget'

import './informationBox.css'

const InformationBox = (props) => {
	const { widgetData = {}, widgetLoaded, widgetLoadFailed, contentIfWidgetLoadFailed } = props;
	const body = widgetData.body;
	return (
		<div className="c6-press-information-box">
			{widgetLoadFailed && <div>{contentIfWidgetLoadFailed}</div>}
			{widgetLoaded && (
				<React.Fragment>
					<div dangerouslySetInnerHTML={{ __html: body }}></div>
					{/*!body && <em>&lt;No info provided&gt;</em>*/}
				</React.Fragment>
			)}
		</div>
	);
}

export default Widget(InformationBox);