import appConfig from 'config'

import DefaultTranslations from './translations_default'
import FinnishTranslations from './translations_fi'
import SwedishTranslations from './translations_se'

const translations = {
	default: DefaultTranslations,
	fi: FinnishTranslations,
	se: SwedishTranslations,
};

const lang = appConfig.features.pressLocale;
let PressTranslations = translations[lang];
if (!PressTranslations) {
	console.error(`There is no translation file for lang "${lang}"!`);
	PressTranslations = DefaultTranslations;
}

function getTranslation(key) {
	const translation = PressTranslations[key];
	if (translation === undefined) {
		console.error(`PressTranslations["${key}"] does not exist.`);
		return "";
	}
	if (lang && translation === undefined) {
		console.warn(`PressTranslations["${key}"] does not have a translation for language "${lang}", using default value: "${DefaultTranslations[key]}".`);
		return DefaultTranslations[key];
	}

	return translation || DefaultTranslations[key];
}

// Turns PressTranslations into a dictionary
function getAllTranslations() {
	return Object.keys(PressTranslations).reduce((acc, curr) => ({
		...acc,
		[curr]: getTranslation(curr),
	}), {});
}

export default {
	getTranslation,
	getAllTranslations,
	PressTranslations,
}