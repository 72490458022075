import React from 'react'

import Spinner from '../../../components/spinner'

import './button.css'

const PressButton = ({ label, isLoading, ...rest }) => {
	return (
		<button className={`c6-press-button ${isLoading ? "is-loading" : ""}`} {...rest}>
			<div>{label}<Spinner loading={isLoading} /></div>
		</button>
	);
};

export default PressButton;