import React, { Component } from 'react'
import { withRouter } from 'react-router'

import ResponsiveImage from '../../../components/assets/responsiveImage'

import Actions from '../actions'

import './pressRelease.css'

@withRouter
export default class BulletinBoardItem extends Component {
	componentDidMount() {
        // Since we don't have JSON files for each item, we fetch the whole list and filter it in the component
		Actions.fetchItem.defer("bulletinBoardItem");
	}

	render() {
        const items = this.props.bulletinBoardItem?.items ?? [];
        const item = items.find(i => i.id === this.props.params.id);
        if (!item) {
            return null;
        }

        const { heading, subHeading, description, assets = [] } = item;
        const image = assets.find(a => a.category === "main");

		return (
			<div className="c6-press-release c6-bulletin-board-item">
				<h1>{heading}</h1>
                <h2>{subHeading}</h2>

                {image && (
                    <ResponsiveImage
                        id={image.id}
                        format="fit"
                        sizes="(min-width: 1901px) 60vw, (min-width: 1200px) 80vw, 100vw"
                    />
                )}

				<div className="body" dangerouslySetInnerHTML={{ __html: description }}></div>
			</div>
		);
	}
}