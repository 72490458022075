import React from 'react'
import { withRouter } from 'react-router'
import { browserHistory } from 'browserHistory'

import { Tabs, Tab } from '../../../components/tabs'

import './subSections.css'

const SubSections = ({ subSections = [], renderWidget, location, renderAll = false }) => {
	if (renderAll) {
		return (
			<div className="c6-press-subsections">
				{subSections.filter(section => !section.hidden).map((section) => {
					const { id, title, areas = [] } = section;
					const main = areas.find(area => area.name === "main");
					return (
						<div key={id}>
							{title?.length > 0 && (<h2>{title}</h2>)}
							{main && main.widgets.map(w => renderWidget(w))}
						</div>
					);
				})}
			</div>
		);
	}
	
	const list = location.query && location.query.list;
	const activeTab = subSections.find(section => {
		const main = (section.areas || []).find(area => area.name === "main");
		return (main && main.widgets || []).find(widget => widget.name === list);
	});

	if (!list) {
		setDefaultList(subSections);
	}

	return (
		<div className="c6-press-subsections">
			<Tabs defaultActiveTab={activeTab ? activeTab.id : null}>
				{subSections.filter(section => !section.hidden).map((section) => {
					const { id, title, areas = [] } = section;
					const main = areas.find(area => area.name === "main");
					const firstWidget = (main && main.widgets || [])[0];
					return (
						<Tab key={id} id={id} label={title} onClick={(e, id) => { if (firstWidget) setList(firstWidget.name) }}>
							<TabContent {...section} renderWidget={renderWidget} />
						</Tab>
					);
				})}
			</Tabs>
		</div>
	);
};

export default withRouter(SubSections);

function setList(listName) {
	const pathname = window.location.pathname;
	setTimeout(() => {
		browserHistory.replace({
			pathname,
			query: {
				list: listName,
			},
		});
	}, 0);
}

function setDefaultList(sections = []) {
	if (sections[0]) {
		const main = (sections[0].areas || []).find(area => area.name === "main");
		if (main && main.widgets && main.widgets.length) {
			const firstNonEmptyWidget = main.widgets.find(w => w.items && w.item.length > 0);
			const name = /*firstNonEmptyWidget && firstNonEmptyWidget.name ||*/ main.widgets[0] && main.widgets[0].name;
			if (name) {
				setList(name);
			}
		}
	}
}

const TabContent = withRouter(({ areas = [], renderWidget, location }) => {
	const list = location.query && location.query.list;
	const main = areas.find(a => a.name === "main");
	const activeWidget = main && main.widgets.find(w => w.name === list) || {};
	return (
		<div>
			{main && main.widgets && main.widgets.length > 1 && (
				<div className="widget-selector">
					{main.widgets.map(({ heading, name, items }) => (
						<a
							key={name}
							onClick={/*items && items.length*/true ? () => setList(name) : null}
							className={`${activeWidget.name === name ? "active" : ""} ${/*!items || !items.length*/false ? "disabled" : ""}`}
						>
							{heading}
						</a>
					))}
				</div>
			)}
			{main && main.widgets.map(w => (
				<div key={w.name} style={{ display: activeWidget.name === w.name ? "block" : "none" }}>
					{renderWidget(w)}
				</div>
			))}
		</div>
	);
});