import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import './metadata.css'

export default class Metadata extends React.PureComponent {

	static propTypes = {
		program: PropTypes.object.isRequired,
		showNumberOfEpisodes: PropTypes.bool,
	}

	render() {
		const { program, texts = {}, showNumberOfEpisodes } = this.props;

		// Represents what the program is a ORIGINAL of, example 'CMORE' or 'MTV' for season/single programs
		const shouldPresentContentOriginal = program.type === "season" || program.type === "single";
		const contentOriginal = mapContentOriginal(program?.contentOriginal); // Could use a logo instead of a text representation
		
		return (
			<div className="section metadata">
				{getMetadata(program, texts, showNumberOfEpisodes)}
				{shouldPresentContentOriginal && contentOriginal && <p>{contentOriginal}</p>}
				{program.credits && program.credits.length ? <hr /> : null}
				{getCredits(program, "actor", texts)}
				{getCredits(program, "director", texts)}
				{getCredits(program, "producer", texts)}
				{getCredits(program, "scriptWriter", texts)}
			</div>
		)
	}
}

// HELPERS
function mapContentOriginal(contentOriginal) {
	switch (contentOriginal?.toLowerCase()) {
		case "cmore":
			if (moment().isSameOrAfter("2023-10-10")) {
				return <span className="channel-logo channel-mtv-original"></span>;
			}
			return <span className="channel-logo channel-cmore-originals"></span>;
		case "hayu":
		case "britbox":
		case "mtv":
			if (moment().isSameOrAfter("2023-10-10")) {
				return <span className="channel-logo channel-mtv-original"></span>;
			}
			return `${contentOriginal} original`;
		default:
			return null;
	}
}

function getMetadata(program, texts, showNumberOfEpisodes) {
	const metadata = program[`${program.type}Info`] || program;
	const numberOfEpisodes = showNumberOfEpisodes && metadata.episodes && metadata.episodes.length > 0 ? <span>{metadata.episodes.length} {texts["episodes"] || "episoder"}.</span> : null;
	return <p>{getFacts(metadata, texts)}{numberOfEpisodes}</p>;
}

export function getFacts({ genre, genres, minutes, productionCountries, productionYear }, texts = {}) {
	let facts = `${genre || getList(genres)}${getList(productionCountries)}${productionYear ? " " + productionYear : ""}`.trim();
	facts = facts ? `${facts}. `: "";
	facts += minutes ? `${minutes} ${texts["minutes"] || "minuter"}.`: "";
	return facts;
}

function getList(items) {
	return items ? " " + items.join(", ") : "";
}

function getCredits(program, type, texts) {
	const { credits = [] } = program;
	const persons = credits.filter(c => c.type === type);

	return persons.length
		? (
			<div className="program-credits">{getTypeHeading(type, texts)}{persons.map(p => {
				const character = p.character ? <span>{p.character}</span> : null;
				return <div key={p.type + p.name}><span>{p.name}</span>{character}</div>
			})}
			</div>
		)
		: null;
}

function getTypeHeading(type, texts = {}) {
	switch (type) {
		case "actor":
			return <h2>{texts["actor"] || "Skådespelare"}</h2>;
		case "director":
			return <h2>{texts["director"] || "Regi"}</h2>;
		case "producer":
			return <h2>{texts["production"] || "Produktion"}</h2>;
		case "scriptWriter":
			return <h2>{texts["script"] || "Manus"}</h2>;
		default:
			return null;
	}
}