import React from 'react'
import { Link } from 'react-router'

import './link.css'

const PressLink = ({ children, ...rest }) => (
	<Link
		className="c6-press-link"
		{...rest}
	>
		{children}
	</Link>
);

export default PressLink;