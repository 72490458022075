import React from 'react'
import { Link } from 'react-router'
import moment from 'moment'

import Widget from './widget'
import Translations from '../shared/translations'
import { getStringWithoutHTML } from '../shared/utils'

import appConfig from 'config'

import './pressReleases.css'

const BulletinBoard = ({ compact, widgetData = {}, heading }) => {
	const { items = [] } = widgetData;
	console.log("items", items);

	return (
		<div className={`c6-press-pressreleases c6-press-bulletinboard ${compact ? "compact" : ""} ${!heading || !heading.length ? "no-heading" : ""}`}>
			{items.map(b => (
                <BulletinBoardItem
                    key={b.id}
                    compact={compact}
                    {...b}
                />
            ))}
		</div>
	);
}

const BulletinBoardItem = ({ heading, subHeading, description, start: date, id, compact, link, itemCategory }) => {
	const dateFormat = "DD.MM.YYYY";
	const clickable = getStringWithoutHTML(description)?.length > 0;
	const content = (
		<>
			<h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
			<p className="metadata">
				<span className="icon-insert_invitation">{moment(date).tz(appConfig.features.pressTimezone).format(dateFormat)}</span>
				{renderItemCategory(itemCategory)}
			</p>
			<div className="info">
				<p>
					{subHeading}
				</p>
			</div>
		</>
	);

	if (link?.url) {
		return (
			<a
				className={`c6-press-pressrelease-item ${compact ? "compact" : ""}`}
				href={link.url}
				target="_blank"
			>
				{content}
			</a>
		);
	}

	if (clickable) {
		return (
			<Link
				className={`c6-press-pressrelease-item ${compact ? "compact" : ""}`}
				to={`/${Translations.getTranslation("url_bulletin")}/${id}`}
			>
				{content}
			</Link>
		);
	}

	return (
		<div className={`c6-press-pressrelease-item ${compact ? "compact" : ""}`}>
			{content}
		</div>
	);
}

export default Widget(BulletinBoard);

export function renderItemCategory(category) {
	const _category = category?.toLowerCase().replace("-", "");
	if (!_category || _category === "othernews") {
		return null;
	}

	const text = Translations.getTranslation(`bulletinboardItemCategory_${_category}`);
	return (
		<span className={`c6-press-bulletinboard-item-category-${_category}`}>{text}</span>
	);
}