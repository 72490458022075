import * as PressAPI from '../../../apis/press'
import { useQuery } from '@tanstack/react-query'

const usePressProgramReviews = (imdbId: string | number) => {
    return useQuery(
        ["press", "programreviews", { id: imdbId }],
        () => PressAPI.fetchProgramReviews({ id: imdbId }),
        {
            enabled: imdbId !== null && imdbId !== undefined,
            onError: () => {}, // Do nothing on error
        }
    );
};

export default usePressProgramReviews;