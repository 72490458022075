import React, { Component } from 'react'
import { withRouter } from 'react-router'

import Button from '../shared/button'
import TextField from '../shared/textField'
import Link from '../shared/link'

import Translations from '../shared/translations'

import AuthActions from '../../../core/authentication/actions'
import UserStore from '../../../core/authentication/store'
import * as PressAPI from '../../../apis/press'

import appConfig from 'config'

import './loginForm.css'

@withRouter
export default class PressLoginForm extends Component {
	state = {
		...UserStore.getState(),
		username: "",
		password: "",
		loginErrorMessage: "",
		passwordErrorMessage: "",
	}

	componentDidMount() {
		UserStore.listen(this.onChange);
		AuthActions.display.defer();
	}

	componentWillUnmount() {
		UserStore.unlisten(this.onChange);
	}

	onChange = state => {
		this.setState(state);
	}

	onSubmit = e => {
		e.preventDefault();

		const { router, location, modal = false } = this.props;
		const { username, password, isLoading } = this.state;

		if (isLoading) {
			return false;
		}

		if (username && password) {
			AuthActions.loginWithUserPass({ username, password, location, router, modal, api: PressAPI });
		} else {
			AuthActions.loginFailed({ exceptionMessage: Translations.getTranslation("fillBothFields") });

			if (!username) {
				const loginErrorMessage = Translations.getTranslation("typeEmail");
				this.setState({ loginErrorMessage });
			}
			if (!password) {
				const passwordErrorMessage = Translations.getTranslation("typePassword");
				this.setState({ passwordErrorMessage });
			}
		}
	}

	handleForgotPasswordNav = e => {
		// e.preventDefault();
		// const { modal = false } = this.props;
		AuthActions.display();
	}

	handleInputKeyPress = e => {
		if (e.key === "Enter") {
			this.onSubmit(e);
		}
	}

	render() {
		const { username, password, loginErrorMessage, passwordErrorMessage, error, errorMessage, loggedIn, isLoading } = this.state;
		
		const translatedErrorMessage = translateErrorMessage(errorMessage);

		return (
			<div className="c6-press-login">
				<form onSubmit={this.onSubmit}>
					<h1>
						{error ? translatedErrorMessage : null}
					</h1>
					<TextField
						label={Translations.getTranslation("email")}
						type={appConfig.features.pressLoginInputType ?? "email"}
						value={username}
						noValidate={true}
						onChange={value => this.setState({ username: value, loginErrorMessage: "" })}
						disabled={isLoading}
						// errorText={loginErrorMessage}
						onKeyPress={this.handleInputKeyPress}
						maxLength={300}
					/>
					<TextField
						label={Translations.getTranslation("password")}
						type="password"
						value={password}
						onChange={value => this.setState({ password: value, passwordErrorMessage: "" })}
						disabled={isLoading}
						// errorText={loginErrorMessage}
						onKeyPress={this.handleInputKeyPress}
						maxLength={300}
					/>
					<Button
						label={Translations.getTranslation("logIn")}
						type="submit"
						disabled={isLoading}
						isLoading={isLoading}
					/>
					{!appConfig.features.pressHidePasswordAndRegisterLinks && (
						<div className="links">
							<Link to={`/${Translations.getTranslation("url_forgotpassword")}`} onClick={this.handleForgotPasswordNav}>{Translations.getTranslation("forgotPassword")}</Link>
							<Link to={`/${Translations.getTranslation("url_register")}`}>{Translations.getTranslation("register")}</Link>
						</div>
					)}
				</form>
			</div>
		);
	}
}

function translateErrorMessage(errorMessage) {
	if (errorMessage.includes("incorrect"))
		return Translations.getTranslation("incorrectCredentials");
	return errorMessage;
}