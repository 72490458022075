import React from 'react'

export function getPathWithNamesInsteadOfIDs({ id, program, season, series, texts }) {
	// Season/Series
	if (season?.id) {
		const url_series = texts["url_series"] || "series";
		const url_season = texts["url_season"] || "season";
		const url_episode = texts["url_episode"] || "episode";
		const episode = id && season.episodes?.find(e => e.id === id) || {};
		return `
			${series?.id ? `/${url_series}/${series.id}-${series.title?.replace(/-/g, "")}` : ""}
			/${url_season}/${season.id}-${season.title?.replace(/-/g, "")}
			${episode.id ? `/${url_episode}/${episode.id}-${episode.title?.replace(/-/g, "")}` : ""}
		`.replace(/[\n\t]/g, "").replace(/\s/g, "_");
	}
	// Single
	else if (program?.id) {
		const url_program = texts["url_program"] || "program";
		return `/${url_program}/${program.id}-${program.title?.replace(/-/g, "")}`;
	}

	return window.location.pathname;
}


// const REQUIRED_SCORE_FOR_AVERAGE = 0;
// const REQUIRED_NR_OF_REVIEWS_FOR_AVERAGE = 1;
// const AVERAGE_EXPLANATION_TEXT = "Snittbetyg baserat på alla våra recensioner.";
const REQUIRED_REVIEW_SCORE = 0;
const NR_OF_QUOTES = 3;
const NR_OF_SCORES = 5;
export function renderProgramReviews({ seasonNumber, data = {} }) {
	let review = data;
	// For series, there will be separate objects for each season, identified by the season number
	if (seasonNumber !== undefined && Array.isArray(data)) {
		review = data.find(r => r.seasonNumber === seasonNumber) ?? {};
	}
	const { /*normalizedScore,*/ reviews = [] } = review;
	if (!reviews?.length) {
		return null;
	}

	// const showNormalizedScore = parseFloat(normalizedScore) >= REQUIRED_SCORE_FOR_AVERAGE && reviews.length >= REQUIRED_NR_OF_REVIEWS_FOR_AVERAGE;
	const reviewsWithQuotes = reviews.filter(r => parseFloat(r.normalizedScore) >= REQUIRED_REVIEW_SCORE && r.quotes?.length).slice(0, NR_OF_QUOTES);
	const otherReviews = reviews.filter(r => parseFloat(r.normalizedScore) >= REQUIRED_REVIEW_SCORE && !reviewsWithQuotes.includes(r)).slice(0, NR_OF_SCORES);
	
	return (
		<div className="c6-program-reviews">
			{/* {showNormalizedScore && (
				<div className="normalized-score">
					<span>{roundToHalf(normalizedScore)}</span>
					<span>{AVERAGE_EXPLANATION_TEXT}</span>
				</div>
			)} */}
			{reviewsWithQuotes.map(r => renderReview(r, true))}
			<br/>
			{otherReviews.map(r => renderReview(r, false))}
		</div>
	);
}

function renderReview(review, includeQuote) {
	return (
		<div className={`review ${includeQuote ? "include-quote" : ""}`} key={review.source.name}>
			{includeQuote && <span className="quote">{review.quotes[0].text}</span>}
			<div className="score-source">
				{renderReviewScore(review)}
				{renderSource(review)}
			</div>
		</div>
	);
}

function renderReviewScore(review) {
	const image = review.assets?.find(a => a.format === "h30px");
	return image ? <img className="score" src={image.url} /> : <span className="score">{review.displayScore}</span>;
}

function renderSource(review) {
	const sourceUrl = review.source.assets?.find(a => a.format === "h100px")?.url;
	const sourceImage = sourceUrl ? <img className="source-image" src={sourceUrl} /> : null;
	return (
		<a className="source" target="_blank" href={review.link}>{sourceImage ?? review.source.name}</a>
	);
}

// function roundToHalf(num) {
// 	return Math.round(parseFloat(num) * 2) / 2;
// }