import Moment from 'moment'

import { MuiToggle } from '../editorWidgets'
import AuthStore from '../../../core/authentication/store'
import { KEYBOARD_SHORTCUTS } from '../../../core/constants'

import './approval.css'

const Approval = ({ formData = {}, onChange, formContext, uiSchema, simpleLabel, overrideApprovalLabel }) => {
	const handleChange = (isApproved) => {
		const User = AuthStore.getState().user;
		const { email, username } = User;

		const approveObject = {
			status: isApproved ? "approved" : "unapproved",
			by: email || username,
			on: Moment().toISOString(),
		};

		onChange(approveObject);
	};

	const { hasEditAccess = true, canApprove = true } = formContext;

	const { status, by, on } = formData;
	const isApproved = status && status.toLowerCase() === "approved";

	const when = Moment.duration(Moment(on).diff(new Moment())).humanize(true);
	let label = "Not approved";
	if (overrideApprovalLabel) {
		label = overrideApprovalLabel;
	} else if (isApproved) {
		const byStr = by ? `by ${by} ` : "";
		label = simpleLabel ? "Approved" : `Approved ${byStr}${when}`;
	}
	let title = "This item is not approved.";
	if (!isApproved && on) {
		title = `Unapproved ${on}`;
	} else if (isApproved && on) {
		title = `Approved ${on}`;
	} else if (isApproved) {
		title = "This item is approved";
	}

	title += `\n(${KEYBOARD_SHORTCUTS.approve})`;

	if (hasEditAccess && canApprove) {
		return (
			<MuiToggle
				title={title}
				value={isApproved}
				label={label}
				disabled={uiSchema?.overrideReadOnly}
				onChange={handleChange}
				options={{
					color: "success",
				}}
				className="c6-editor-approval"
			/>
		);
	}

	return <small className={`c6-text-${status?.toLowerCase()}`} title={title}>{label}</small>;
};

export default Approval;