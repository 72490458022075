export default {
	// Header & search
	search: "Search",
	searching: "Searching",
	noItemsFound: "No items found",
	
	// Footer
	faq: "FAQ",
	channels: "Channels",
	graphicProfile: "Graphic profile",
	about: "About MTV",

	// Login/register/forgot password
	logIn: "Log in",
	logOut: "Log out",
	forgotPassword: "Forgot password?",
	register: "Register",
	email: "E-mail",
	password: "Password",
	incorrectCredentials: "Name or password was incorrect.",
	couldNotFindLogin: "Could not find login with name or email: {email}.",
	name: "Name",
	phoneNumber: "Phone number",
	media: "Media",
	purposeForRegistering: "Purpose for registering",
	submit: "Submit",
	requestSubmitted: "Request submitted",
	requestSubmittedInfo: "We will take a look at your request as soon as we can and you will be notified by e-mail when your request is approved or denied.",
	accountAlreadyExists: "We already have an account registered with the email {email}. Try resetting your password and send us an email at mtvviestinta@mtv.fi if you have any further questions.",
	requestNotHandled: "You have requested access to our press service and provided your email address {email}. We have not handled your request just yet. For further questions you can send an email to mtvviestinta@mtv.fi.",
	requiredFields: "required fields",
	fillBothFields: "Fill both fields, please!",
	typeEmail: "Please type your login/e-mail!",
	typePassword: "Please type your password!",
	passwordsDontMatch: "The passwords don't match.",
	typePasswordTwice: "Please type the password twice!",
	typeNewPassword: "Please type a new password!",
	retypePassword: "Please retype the password!",
	linkExpires: "Link expires",
	linkExpired: "Link expired!",
	newPassword: "New password",
	confirmNewPassword: "Confirm new password",
	save: "Save",
	passwordResetLinkInfo: "The password reset links are only valid for a limited time. This one has already expired so you need to request a new one with the button below.",
	itWillBeSentTo: "It will be sent to",
	requestNewLink: "Request new link",
	passwordResetSuccess: "Your password has been updated, please log in below.",
	forgotPasswordSuccess: "An email with instructions on how to set a new password has been sent to {email}. It should be with you within 2 minutes, if not please check your spam folder.",

	// Schedules
	today: "Today",
	all: "All",
	noBroadcasts: "No broadcasts",
	programTitleMissing: "Program title missing",
	mtvDescription: "Only display MTV channels",
	cmoreDescription: "Only display C More channels",
	todayTitle: "Go to today's date [CTRL-T]",
	previousDayTitle: "Go to previous day [LEFT ARROW KEY]",
	nextDayTitle: "Go to next day [RIGHT ARROW KEY]",
	subscribeSuccess: "Thank you for subscribing! An email will be sent to",
	subscribeError: "Could not subscribe, please try again!",
	loadSubscriptionsError: "Could not load subscriptions, please try again!",
	subscriptionsUpdated: "Your subscriptions were successfully updated!",
	subscriptionsCancelled: "All subscriptions were successfully cancelled!",
	subscriptionsUpdateError: "Could not update subscription, please try again!",
	cancelSubscriptions: "Cancel my subscriptions",
	subscribeWidgetMessage: "If you would like to be notified of changes in our schedules, please submit your email address.",

	// Program
	season: "Season",
	episode: "Episode",
	episodes: "episodes",
	availableOn: "Available on",
	// premieresOn: "Premieres on",
	nextBroadcast: "Next broadcast",
	upcomingBroadcasts: "Upcoming broadcasts",
	noPlannedBroadcasts: "Currently no planned broadcasts.",
	noEpisodesAvailable: "No episodes currently available.",
	at: "at",
	tomorrow: "Tomorrow",
	noEpisodeSynopsis: "No episode synopsis",
	episodeImages: "Episode images",
	seasonImages: "Season images",
	seriesImages: "Series images",
	downloadableMaterials: "Downloadable materials",
	downloadNotAllowed: "Login to download hi-res image.",
	pressPreviewsNotAllowed: "Login to play press previews.",
	noPressImages: "No press photos available currently on this site. Please contact us in case you wish to get images of this programme so that we can check their availability.",
	// programDescriptionMissing: "Program description missing.",
	programDescriptionMissing: " ",
	showMore: "Show more",
	showFewer: "Show fewer",
	minutes: "minutes",
	actor: "Acting",
	director: "Directing",
	production: "Producing",
	script: "Writing",
	previewNotAllowed: "You need to log in to preview & download images.",
	programNotAvailable: "Program is not available",
	availability: "Availability",
	copyProgramInfo: "Copy",
	copyProgramInfoDone: "Program information copied to clipboard.",
	closePreview: "Close",
	loading: "Loading",

	// Press releases
	images: "Images",
	morePressReleases: "More press releases",
	loadMore: "Load more",
	bulletinboardItemCategory_invitation: "Invitation",
	bulletinboardItemCategory_programchange: "Change",
	bulletinboardItemCategory_othernews: "",

	// URLs
	url_pressreleases: "press-releases",
	url_pressrelease: "press-release",
	url_bulletin: "bulletin",
	url_program: "program",
	url_series: "series",
	url_season: "season",
	url_episode: "episode",
	url_schedules: "schedules",
	url_updateschedulessubscription: "update-schedules-subscription",
	url_login: "login",
	url_register: "register",
	url_forgotpassword: "forgot-password",
	url_resetpassword: "reset-password",
	url_faq: "faq",
	url_search: "search",
}