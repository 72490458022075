import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Moment from 'moment'
import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'
import InfiniteScroll from 'react-infinite-scroller'

import { ItemGroup } from '../../../components/list/listItems'
import Empty from '../../../components/list/empty'
import { decoratorUIActions } from '../../../core/decorators/uiActions'

import Item from '../shared/listItem'
import { getItemTemplateModification } from '../shared/utils'

import contentActions from './listItemActions'
import { Regions } from '../../../core/constants'
import { renderTodayOrDate } from '../../../utils/date'

import appConfig from 'config'
import moment from 'moment'


const ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST = 2;

@decoratorUIActions(contentActions)
export default class List extends Component {

	static propTypes = {
		items: PropTypes.array.isRequired,
		isLoading: PropTypes.bool.isRequired,
		handleFilter: PropTypes.func.isRequired,
		masterTemplateModifications: PropTypes.object,
		pastNeedingAttention: PropTypes.object,
		searchText: PropTypes.string,
		filters: PropTypes.object,
		textEmpty: PropTypes.string,
		isTranslationList: PropTypes.bool,
		translationLanguage: PropTypes.string,
	}

	constructor(props) {
		super(props);
	}

	render() {
		const {
			items,
			numberOfItems,
			isLoading,
			searchText = "",
			textEmpty = "Sorry, could not find any items.",
			hasMore,
			loadMore,
			pastNeedingAttention,
			pastMatchingSearch,
			filters,
			handleFilter,
		} = this.props;

		const textTooFewCharacters = searchText && searchText.length < ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST
			? `Please type ${ONLY_SEARCH_WHEN_SEARCHTEXT_IS_AT_LEAST} characters or more to search.`
			: "" ;

		const noResult = !(items && items.length);
		return noResult
			? (
				<Empty key="empty" v2={true} isLoading={isLoading}>
					{textTooFewCharacters || textEmpty}&nbsp;
					{!textTooFewCharacters && !searchText?.length && getPastNeedingAttention(pastNeedingAttention, filters, handleFilter)}
					{!textTooFewCharacters && !!searchText?.length && getPastMatchingSearch(pastMatchingSearch, numberOfItems, filters, handleFilter)}
				</Empty>
			)
			: (
				<InfiniteScroll
					loadMore={loadMore}
					hasMore={hasMore}
					loader={<div className="infinite-loader" key="infinite-loader">Loading ...</div>}
					useWindow={false}
					threshold={700}
					initialLoad={false}
				>
					{this.renderGroups(this.props)}
				</InfiniteScroll>
			);
	}

	renderGroups({
		items,
		pastNeedingAttention,
		pastMatchingSearch,
		numberOfItems,
		searchText,
		filters,
		handleFilter,
		onFamilyActionClick,
		isTranslationList,
		translationLanguage,
		masterTemplateModifications,
		onVideoClick,
		onSubtitleClick,
		catalogue,
	}) {
		if (searchText) {
			const title = (
				<span>
					Items matching &quot;{searchText}&quot;&nbsp;
					{getPastMatchingSearch(pastMatchingSearch, numberOfItems, filters, handleFilter)}
				</span>
			);
			return (
				<ItemGroup title={title}>
					{this.renderItems({ items, onFamilyActionClick, catalogue, isTranslationList, masterTemplateModifications, translationLanguage, onVideoClick, onSubtitleClick })}
				</ItemGroup>
			);
		}

		const lan = isTranslationList ? translationLanguage : null;
		const groupedItems = groupItems(items, filters, lan);

		return Object.keys(groupedItems).map((groupKey, index) => (
			<ItemGroup key={groupKey} title={getGroupTitle(groupedItems, groupKey, index, pastNeedingAttention, handleFilter, filters, lan)}>
				{this.renderItems({ items: groupedItems[groupKey], onFamilyActionClick, catalogue, isTranslationList, masterTemplateModifications, translationLanguage, onVideoClick, onSubtitleClick })}
			</ItemGroup>
		));
	}

	renderItems({ items, onFamilyActionClick, catalogue, isTranslationList, masterTemplateModifications, translationLanguage, onVideoClick, onSubtitleClick }) {
		return items.map(item => (
			<Item
				{...item}
				key={item.id}
				id={item.id}
				actionData={item}
				itemTemplateModification={getItemTemplateModification(item, masterTemplateModifications)}
				onFamilyActionClick={e => onFamilyActionClick(e, item)}
				catalogue={catalogue==="all"?this.props.filters.filter.catalogue:catalogue}
				isTranslationList={isTranslationList}
				translationLanguage={translationLanguage}
				onVideoClick={onVideoClick}
				onSubtitleClick={onSubtitleClick}
			/>
		));
	}
}

// HELPERS
function groupItems(items, filters, language) {
	const groupedItems = groupBy(items, item => {
		const firstPremiere = getFirstPremiere(item, filters, language);
		return Moment(firstPremiere).format("YYYYMMDD"); // DATE
	});

	if (filters.filter.filter === "HasActiveRights") {
		return sortBy(groupedItems, group => items.indexOf(group[0]));
	}

	return groupedItems;
}

function getFirstPremiere(item, filters, language) {
	if (!item.rights || !item.rights.length) {
		return null;
	}

	let rights = item.rights;
	if (appConfig.features.metadataUseCatalogues) {
		rights = item.rights.filter(r => r.futurePremiere).sort((a, b) => moment(a.regionStart ?? a.start).diff(moment(b.regionStart ?? b.start)));
	}

	// If we're listing a specific language we need to group by the corresponding country rights
	if (language) {
		const region = Regions.filter(r => r.languageDisplayName === language);
		const versionId = region && region.length && region[0].id;
		const rightVersions = rights.filter(rv => rv.versionId === versionId);

		if (rightVersions.length) {
			return new Date(rightVersions[0].regionStart ?? rightVersions[0].start);
		}
	}

	return new Date(rights[0].regionStart ?? rights[0].start);
}

function getGroupTitle(groupedItems, group, index, pastNeedingAttention, handleFilter, filters, language) {
	const firstItem = groupedItems[group][0];
	const firstPremiere = getFirstPremiere(firstItem, filters, language);
	const title = renderTodayOrDate(firstPremiere);

	return index === 0
		? (
			<span>
				{title} {getPastNeedingAttention(pastNeedingAttention, filters, handleFilter)}
			</span>
		)
		: title;
}

function getPastNeedingAttention(pastNeedingAttention, filters, handleFilter) {
	if (filters?.filter.filter === "HasActiveRights") {
		return <span>(Displaying older but still active programs but you can also <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "premiere", value: "upcoming" } })}>show upcoming programs</span> if you&#39;d like)</span>;
	}

	if (pastNeedingAttention?.numberOfItems > 0) {
		return <span>({pastNeedingAttention.numberOfItems} older but still active {pastNeedingAttention.numberOfItems > 1 ? "programs" : "program"} <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "filter", value: "HasActiveRights" } })}>also need your attention</span>)</span>;
	}

	return null;
}

function getPastMatchingSearch(pastMatchingSearch, numberOfItems, filters, handleFilter) {
	if (!appConfig.features.metadataNewDefaultSearchBehaviour) {
		return;
	}

	if (filters?.filter._searchPastPrograms) {
		return <span>(Displaying all programs matching your search but you can <span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "_searchPastPrograms", value: false } })}>show only active and upcoming programs</span> if you&#39;d like)</span>;
	}

	if (pastMatchingSearch?.numberOfItems > 0 && pastMatchingSearch?.numberOfItems !== numberOfItems) {
		const diff = pastMatchingSearch.numberOfItems - numberOfItems;
		return <span>(<span className="c6-link" onClick={handleFilter.bind(this, "filter", { target: { name: "_searchPastPrograms", value: true } })}>{diff} more past {diff > 1 ? "programs" : "program"} also match your search</span>)</span>;
	}

	return null;
}