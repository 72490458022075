export default {
	// Header & search
	search: "Sök",
	searching: "Söker",
	noItemsFound: "Inga träffar",
	
	// Footer
	faq: "FAQ",
	channels: "Kanaler",
	graphicProfile: "Grafisk profil",
	about: "Om SVT",
	customerService: "Kundtjänst",
	news: "Nyheter",
	arena: "SVT Arenan",
	tastes: "Smaker",
	feedback: "Feedback",
	advertisers: "Reklam",
	company: "Företaget",
	cmore: "SVT Play",

	// Login/register/forgot password
	logIn: "Logga in",
	logOut: "Logga ut",
	forgotPassword: "Glömt lösenord?",
	register: "Registrera",
	email: "E-post / Användarnamn",
	password: "Lösenord",
	incorrectCredentials: "Fel namn eller lösenord.",
	couldNotFindLogin: "Hittade inget konto med namn eller e-post: {email}.",
	name: "Namn",
	phoneNumber: "Telefonnummer",
	media: "Företag/media",
	purposeForRegistering: "Syfte för registrering",
	submit: "Skicka",
	requestSubmitted: "Förfrågan skickad",
	requestSubmittedInfo: "Vi kommer att ta en titt på din förfrågan så snart vi kan och du kommer att bli notifierad via e-post när din förfrågan är hanterad.",
	accountAlreadyExists: "Det finns redan ett konto med e-postadressen {email}. Försök nollställa ditt lösenord eller skicka ett meddelande till support@junefirst.tv om du har frågor.",
	requestNotHandled: "Du har redan skickat en förfrågan med e-postadressen {email}. Vi har ännu inte hanterat din förfråga. För andra frågor kan du höra av dig till support@junefirst.tv.",
	requiredFields: "obligatoriska fält",
	fillBothFields: "Var god fyll i båda fälten!",
	typeEmail: "Var god ange din e-postadress!",
	typePassword: "Var god ange lösenord!",
	passwordsDontMatch: "Lösenorden stämmer inte överens.",
	typePasswordTwice: "Ange lösenordet två gånger!",
	typeNewPassword: "Var god ange ett nytt lösenord!",
	retypePassword: "Var god skriv in lösenordet en gång till!",
	linkExpires: "Länken utgår",
	linkExpired: "Länken har utgått!",
	newPassword: "Nytt lösenord",
	confirmNewPassword: "Bekräfta nytt lösenord",
	save: "Spara",
	passwordResetLinkInfo: "Länkar för att nollställa lösenord är giltiga under en begränsad tid. Den här länken har redan utgått, du behöver be om en ny länk med hjälp av knappen nedan.",
	itWillBeSentTo: "Den kommer att skickas till",
	requestNewLink: "Be om en ny länk",
	passwordResetSuccess: "Ditt lösenord har uppdaterats, logga in nedan.",
	forgotPasswordSuccess: "Ett e-postmeddelande med instruktioner om hur du väljer ett nytt lösenord har skickats till {email}. Det borde anlända inom 2 minuter, annars ber vi dig kolla din skräppost.",

	// Schedules
	today: "Idag",
	all: "Alla",
	noBroadcasts: "Inga sändningar",
	programTitleMissing: "Programtitel saknas",
	mtvDescription: "Visa endast MTV-kanaler",
	cmoreDescription: "Visa endast C More-kanaler",
	todayTitle: "Hoppa till dagens datum [CTRL-T]",
	previousDayTitle: "Hoppa till igår [LEFT ARROW KEY]",
	nextDayTitle: "Hoppa till imorgon [RIGHT ARROW KEY]",
	subscribeSuccess: "Tack för att du prenumererar! Ett e-postmeddelande kommer att skickas till",
	subscribeError: "Lyckades inte prenumerera, vänligen försök igen!",
	loadSubscriptionsError: "Lyckades inte hämta prenumerationer, vänligen försök igen!",
	subscriptionsUpdated: "Dina prenumerationer uppdaterades!",
	subscriptionsCancelled: "Alla dina prenumerationer avslutades!",
	subscriptionsUpdateError: "Lyckades inte ändra dina prenumerationer, vänligen försök igen!",
	cancelSubscriptions: "Avsluta mina prenumerationer",
	subscribeWidgetMessage: "Om du vill bli informerad om ändringar i våra tablåer, vänligen ange din e-postadress.",

	// Program
	season: "Säsong",
	episode: "Avsnitt",
	episodes: "avsnitt",
	availableOn: "Tillgänglig på",
	// premieresOn: "Premieres on",
	nextBroadcast: "Nästa visning",
	upcomingBroadcasts: "Kommande visningar",
	noPlannedBroadcasts: "Inga planerade visningar.",
	noEpisodesAvailable: "Inga tillgängliga avsnitt.",
	at: "kl",
	tomorrow: "Imorgon",
	noEpisodeSynopsis: "Ingen avsnittssynopsis",
	episodeImages: "Avsnittsbilder",
	seasonImages: "Säsongsbilder",
	seriesImages: "Seriebilder",
	downloadableMaterials: "Nedladdningsbart material",
	downloadNotAllowed: "Logga in för att ladda ner högupplöst bild.",
	pressPreviewsNotAllowed: "Logga in för att spela upp video.",
	noPressImages: "Inga pressbilder tillgängliga. Vänligen kontakta oss om du vill ha bilder från det här programmet så kan vi kolla deras tillgänglighet.",
	// programDescriptionMissing: "Program description missing.",
	programDescriptionMissing: " ",
	showMore: "Visa fler",
	showFewer: "Visa färre",
	minutes: "minuter",
	actor: "Skådespelare",
	director: "Regi",
	production: "Produktion",
	script: "Manus",
	previewNotAllowed: "Du måste logga in för att förhandsgranska och ladda ner bilder.",
	programNotAvailable: "Programmet är inte tillgängligt",
	availability: "Tillgänglighet",
	copyProgramInfo: "Kopiera",
	copyProgramInfoDone: "Programinformation kopierat till urklipp.",
	closePreview: "Stäng",
	loading: "Laddar",

	// Press releases
	images: "Bilder",
	morePressReleases: "Fler pressmeddelanden",
	loadMore: "Ladda in fler",
	bulletinboardItemCategory_invitation: "Inbjudan",
	bulletinboardItemCategory_programchange: "Ändring",
	bulletinboardItemCategory_othernews: "",

	// URLs
	url_pressreleases: "pressmeddelanden",
	url_pressrelease: "pressmeddelande",
	url_bulletin: "bulletin",
	url_program: "program",
	url_series: "serie",
	url_season: "sasong",
	url_episode: "avsnitt",
	url_schedules: "tabla",
	url_updateschedulessubscription: "andra-tabla-prenumerationer",
	url_login: "inloggning",
	url_register: "registrering",
	url_forgotpassword: "glomt-losenord",
	url_resetpassword: "nollstall-losenord",
	url_faq: "faq",
	url_search: "sok",
}