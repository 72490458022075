import React, { Component } from 'react'

import TextField from '../shared/textField'
import Button from '../shared/button'

import Translations from '../shared/translations'

import * as PressAPI from '../../../apis/press'
import * as Alert from '../../../core/services/alert'

import './subscribe.css'

export default class Subscribe extends Component {
	state = {
		email: "",
		isLoading: false,
	}

	onSubmit = e => {
		e.preventDefault();

		if (this.state.isLoading || !this.state.email?.length) {
			return false;
		}
		
		this.setState({ isLoading: true });
		PressAPI.subscribe({ email: this.state.email })
			.then(response => {
				Alert.displayAlert("success", `${Translations.getTranslation("subscribeSuccess")} ${this.state.email}.`);
				this.setState({ email: "", isLoading: false });
			}, error => {
				Alert.displayAlert("error", Translations.getTranslation("subscribeError"));
				this.setState({ isLoading: false });
			});
	}

	handleInputKeyPress = e => {
		if (e.key === "Enter") {
			this.onSubmit(e);
		}
	}

	render() {
		return (
			<div className="c6-press-subscribe">
				<p>{Translations.getTranslation("subscribeWidgetMessage")}</p>
				<form onSubmit={this.onSubmit}>
					<TextField
						label={Translations.getTranslation("email")}
						type="email"
						value={this.state.email}
						onChange={value => this.setState({ email: value })}
						disabled={this.state.isLoading}
						onKeyPress={this.handleInputKeyPress}
						maxLength={200}
					/>
					<Button
						label={Translations.getTranslation("submit")}
						type="submit"
						disabled={this.state.isLoading}
						isLoading={this.state.isLoading}
					/>
				</form>
			</div>
		);
	}
}