import { createPatch as createRFC6902Patch } from 'rfc6902'

/**
 * Retrieves a template with values from provided data, considering visibility and nested structures.
 * @param {Array<Object>} template - The template to be processed.
 * @param {Object} values - The object containing values.
 * @returns {Array<Object>} - The processed template with values.
 */
export function getTemplateWithValues(template, values) {
	const mappedData = [...template]
		.filter(t =>
			t.componentType === "filter"
			|| t.componentType !== "property" && t.style?.visible
			|| t.componentType === "metadata"
		)
		.map(t => {
			const value = getValue(values, t.name, null, t.valueIndex);

			let childTemplates = [];
			if (Array.isArray(value)) {
				value.forEach((v, i) => {
					childTemplates.push(...mapChildren(t.children, v, i));
				});
			} else {
				childTemplates = mapChildren(t.children, value);
			}

			const childrenWithValues = getTemplateWithValues(
				childTemplates,
				value,
			);

			return {
				...t,
				children: childrenWithValues,
				value,
			};
		});

	return mappedData;
}

function mapChildren(children, value, valueIndex) {
	return (children ?? []).map(c => {
		const visibilityKey = `${c.name}_visible`;
		if (value?.[visibilityKey] === undefined || value?.[visibilityKey] === true) {
			return {
				...c,
				valueIndex,
				displayName: value?.[`${c.name}_displayName`] ?? c.displayName,
			};
		}

		return null;
	}).filter(c => c !== null);
}

/**
 * Retrieves a value from nested objects using dot notation or directly from the root level.
 * @param {Object} values - The object containing values for the entire item.
 * @param {string} valueName - The name of the value to retrieve, possibly in dot notation for nested objects.
 * @param {string} [parentName] - The parent name for nested values in dot notation.
 * @param {number} [valueIndex] - The index of the value if \@values is an array.
 * @returns {*} - The retrieved value, or undefined if not found.
 */
export function getValue(values, valueName, parentName, valueIndex) {
	let value;

	if (!valueName) {
		return;
	}

	// For nested values
	if (valueName.includes(".")) {
		const path = valueName.split(".");
		const vPath = path.slice(0, -1).join(".");
		valueName = path.at(-1);
		parentName = parentName ? `${parentName}.${vPath}` : vPath;
	}
	
	// For root level values
	if (!parentName) {
		value = valueIndex !== undefined
			? values?.[valueIndex]?.[valueName]
			: values?.[valueName];
	}

	/*
	* No value was found at the root level, and this is a nested attribute (has parentName).
	* Example:
	* parentName: "ott.encoder"
	* valueName: "start"
	* values: {
	* 	ott: {
	* 		encoder: {
	* 			start: "2020-01-01 00:00", <-- code below will get this value
	* 		}
	* 	}
	* }
	*/
	if (value === undefined && parentName) {
		const path = parentName.split(".");
		let v = values[path[0]] ?? undefined;
		for (let p of path.slice(1)) {
			v = v?.[p] ?? undefined;
		}
		v = valueIndex !== undefined
			? v?.[valueIndex]?.[valueName]
			: v?.[valueName];
		value = v;
	}

	return value;
}

export function splitPath(path = []) {
	// If path is not array we return it as is
	if (!Array.isArray(path)) {
		return path;
	}

	const result = path.flatMap(p => {
		if (typeof p === "string") {
			return p.split(".");
		}
		return p;
	});
	return result;
}

/**
 * Retrieves an entity with updated value at a specified path in the object or array.
 * @param {Object|Array} [original={}] - The original object or array.
 * @param {Array|string} [path=[]] - The path to the value to be updated. If a string is provided, it is split into an array.
 * @param {*} value - The new value to be set at the specified path.
 * @returns {Object|Array} - The updated object or array.
 */
export function getEntityWithUpdatedValue(original = {}, path = [], value) {
	if (typeof path === "string") {
		path = path.split(".");
	}
	const [current, ...restPath] = path;
	const currentValue = restPath?.length
		? getEntityWithUpdatedValue(original[current], restPath, value)
		: value;
	
	// If "current" is a number, it is the index of an array, which means that "original" needs to be an array
	if (typeof current === "number") {
		const arr = [...original];
		arr[current] = currentValue;
		return arr;
	}

	return {
		...original,
		[current]: currentValue
	} 
}

/**
 * Creates a JSON patch based on the differences between the original and updated items.
 * @param {*} originalItem - The original item.
 * @param {*} updatedItem - The updated item.
 * @param {boolean} [replaceObjectFields=false] - Indicates whether to replace object fields entirely.
 * @returns {Array<Object>} - The JSON patch representing the changes between the original and updated items.
 */
export function createPatch(originalItem, updatedItem, replaceObjectFields = false) {
	if (replaceObjectFields) {
		return createRFC6902Patch(originalItem, updatedItem);
	}
	
	return createRFC6902Patch(originalItem, updatedItem, avoidRecursingObjects);
}

// https://github.com/chbrown/rfc6902#createpatchinput-any-output-any-diff-voidablediff-operation
// This will make sure that we get one (and only one) "replace" operation when changing an object value
// instead of multiple remove/add/replace
function avoidRecursingObjects(input, output, ptr) {
	const path = ptr.toString();
	if (
		path !== "" // Skip this check for the root object
		&& input != output
		&& (["string", "number"].includes(typeof input?.id) || ["string", "number"].includes(typeof output?.id))
		&& input?.id !== output?.id
	) {
		return [{ op: "replace", path: path, value: output }];
	}

	// Return undefined to fall back to default behaviour
	return undefined;
}